import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../../../api/agent';
import MyTooltip from '../../../../Common/Tooltip/MyTooltip';
import {
  APPLICATION_STATUSES,
  INTAKES,
  STUDENTSOURCES,
  STUDENTSTATUS,
  STUDY_LEVELS,
} from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import { addMonths } from 'date-fns';
import CountdownLoader from '../../../StudentLeadComms/LeadComUtils/CountdownLoader';
import WhatsappSummaryDialog from '../../../StudentLeadComms/SendBulkMailDialog/WhatsappSummaryDialog';
import StudentComFilters from '../../StudentComsFilter/StudentComFilters';
import { HiCheckCircle } from 'react-icons/hi2';
import { CiNoWaitingSign } from 'react-icons/ci';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const StudentBulkWhatsapp = ({ appUserId }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [LeadListName, setLeadListName] = useState('');
  const [Name, setLeadFirstName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [studentsLead, setStudentsLead] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [citizenship, setCitizenshipFilter] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [BranchOfficeId, setBranchOfficeFilter] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [studentsLeadIdList, setStudentsLeadIdList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [openWhatsappConfirmDialog, setWhatsappOpenConfirmDialog] =
    useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [email, setEmail] = useState('');
  const [university, setUniversity] = useState(0);
  const [course, setCourse] = useState(0);
  const [intakeMonth, setIntakeMonth] = useState(0);
  const [intakeYear, setIntakeYear] = useState(0);
  const [studyLevelFilter, setStudyLevelFilter] = useState(0);
  const [showDataApp, setShowDataApp] = useState(false);
  const [dataSetApplications, setDataSetApplications] = useState([]);
  const [countryOfRes, setCountryOfRes] = useState(0);
  const [datasetOption, setDatasetOption] = useState(0);
  const [profileType, setProfileType] = useState(0);
  const [login, setLogin] = useState(0);
  const [appStatus, setAppStatus] = useState(0);
  const [destination, setDestination] = useState(null);
  const [studentType, setStudentType] = useState(0);
  const [appType, setAppType] = useState(0);
  const [studentStatus, setstudentStatus] = useState(0);
  const [source, setSource ] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState(
    addMonths(new Date(), -1).toISOString().split('T')[0]
  ); // Initialize with ISO 8601 format;
  const [endDateFilter, setEndDateFilter] = useState(
    new Date().toISOString().split('T')[0]
  );

  const handleAppStatus = (event) => {
    setAppStatus(event.target.value);
  };

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };
  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };
  const handleUniversityChange = (event, value) => {
    setUniversity(value ? value.id : 0);
  };
  const handleCourseChange = (event, value) => {
    setCourse(value ? value.id : 0);
  };
  const handleIntakeMonthChange = (event, value) => {
    setIntakeMonth(value ? value.id : 0);
  };
  const handleIntakeYearChange = (event, value) => {
    setIntakeYear(event.target.value);
  };
  const handleStudyLevelFilterChange = (event, value) => {
    setStudyLevelFilter(value ? value.id : 0);
  };

  const handleEmailChange = (event) => {
    const selectedValue = event.target.value;
    setEmail(selectedValue);
  };

  const handlePhoneNumber = (event) => {
    const selectedValue = event.target.value;
    setPhoneNumber(selectedValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleWhatsappCloseConfirmDialog = () => {
    setWhatsappOpenConfirmDialog(false);
  };
  const handleCountdownComplete = () => {
    setShowCountdownLoader(false);
  };
  const handleDatasetChange = (event, value) => {
    handleClearFilters();
    setDatasetOption(value ? value.id : 0);
    setDataSetApplications([]);
    setStudentsLead([]);
  };
  const handleStartDateFilter = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
      console.log('formattedDate', formattedDate);
      setStartDateFilter(formattedDate);
    } else {
      setStartDateFilter(null); // Clear the start date filter
    }
  };
  const handleEndDateFilter = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
      setEndDateFilter(formattedDate);
    } else {
      setEndDateFilter(null); // Clear the end date filter
    }
  };
  const handleApplyFiltersApp = () => {
    setShowData(false);
    setShowDataApp(true);
    setPageNumber(1);
    fetchDataApp(true);
  };
  const handleApplyFilters = () => {
    setShowDataApp(false);
    setShowData(true);
    setPageNumber(1);
    fetchData(true);
  };

  const handleSendWhatsapp = async () => {
    setShowCountdownLoader(true);
    handleWhatsappCloseConfirmDialog();
    try {
      await Agent.Communication.sendbulkWhatsappStudents(payloadWhatsapp).then(
        (response) => {
          setOpenDialog(false);
          setSuccessMessage('WhatsApp sent successfully!', response);
          setShowCountdownLoader(false);
        }
      );
    } catch (error) {
      console.error('payload error', error);
      setErrorMessage('Post data', error);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    Agent.Events.getEventList().then(setEventList);
  }, []);

  const handleAddStudentsLeadId = (selectedStudentsLeadId) => {
    setStudentsLeadIdList((prevList) => [
      ...prevList,
      { Id: selectedStudentsLeadId },
    ]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payloadWhatsapp = {
    Students: studentsLeadIdList,
    CommunicationName: dialogueCommunicationName,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
    TemplateId: selectTemplateId,
  };

  useEffect(() => {
    // fetchData();
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getWhatsappTemplates();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCountryOfResidence = (event, value) => {
    setCountryOfRes(value ? value.id : 0);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    // params.append('email', filter ? email.trim() : '');

    //params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? Name.trim() : '');
    // params.append('PhoneNumber', filter ? phoneNumber.trim() : '');

    if (filter) {
      params.append('BranchOfficeId', BranchOfficeId);
    }
    if (filter) {
      params.append('Email', email.trim());
    }
    // if (filter) {
    //   params.append('PhoneNumber', phoneNumber.trim());
    // }

    if (filter) {
      params.append('Citizenship', countryOfRes);
    }
    if (filter) {
      params.append('ProfileType', profileType);
    }
    if (filter) {
      params.append('LoginType', login);
    }
    if (filter) {
      params.append('Status', studentStatus);
    }
    if (filter) {
      params.append('Source', source);
    }
    if (filter) {
      params.append('AppType', appType);
    }
    if (filter) {
      params.append('StudentType', studentType);
    }
    if (filter) {
      if (startDateFilter) {
        params.append('StartDate', new Date(startDateFilter).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDateFilter) {
        params.append('EndDate', new Date(endDateFilter).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }
    return params;
  };

  const getParamsApp = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    if (filter) {
      params.append('UniversityId', parseInt(university));
    }
    if (filter) {
      params.append('branchOfficeId', BranchOfficeId);
    }
    if (filter && destination) {
      params.append('DestinationCountryId', destination.id);
    }
    if (filter) {
      params.append('Status', appStatus);
    }
    if (filter) {
      params.append('Source', source);
    }
    if (filter) {
      params.append('StudentType', studentType);
    }
    if (filter) {
      params.append('Intake', parseInt(intakeMonth));
    }
    if (filter) {
      params.append('Year', intakeYear);
    }
    if (filter) {
      params.append('StudyLevelId', parseInt(studyLevelFilter));
    }
    if (filter) {
      params.append('Citizenship', citizenship);
    }
    if (filter) {
      if (startDateFilter) {
        params.append('startDate', new Date(startDateFilter).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDateFilter) {
        params.append('endDate', new Date(endDateFilter).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    return params;
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  //FOR APPLICATIONS
  const fetchDataApp = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParamsApp(filter);
      console.log('params', params);
      const dataset = await Agent.Communication.getWhatsappStudentList(params);
      const { pagination, data } = dataset;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data
      setDataLoaded(true);
      setDataSetApplications(data);
      //getRows(dataset);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //FOR STUDENTS
  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      console.log('params', params);
      const dataset = await Agent.Communication.whatsapplistStudents(params);
      console.log('dataset', dataset);
      const { pagination, data } = dataset;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data
      setDataLoaded(true);
      setStudentsLead(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true); // Initial fetch with filters
  }, [pageNumber,pageSize]);

  const handleOpenConfirmDialog = () => {
    setWhatsappOpenConfirmDialog(true);
  };

  // FOR APPLICATIONS
  const handleSelectAllApp = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const selectedIds = dataSetApplications.map((studentLead) => ({
        Id: studentLead.studentId,
      }));
      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };


  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const selectedIds = studentsLead.map((studentLead) => ({
        Id: studentLead.id,
      }));
      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckboxApp = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.studentId) || selectAll}
      onChange={(event) => handleRowSelectionApp(event, params.row.studentId)}
    />
  );


  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

  let studentsLeadColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < studentsLead.length
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      width: 80,
      renderCell: (params) => <Typography>{params.row.id}</Typography>,
    },
    {
      field: 'name',
      align: 'left',
      headerAlign: 'center',
      width: 250,
      renderHeader: () => <strong>{'Name'}</strong>,
      renderCell: (params) => (
        <MyTooltip
          title={`${params.row.firstName} ${params.row.lastName}`}
          color="primary"
        >
          <Typography className="clickable">{`${params.row.firstName} ${params.row.lastName}`}</Typography>
        </MyTooltip>
      ),
    },
    
    {
      field: 'email',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Email'}</strong>,
      width: 300,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'source',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Source'}</strong>,
      valueGetter: (params) => {
        const sourceId = params.row.source;
        const source = STUDENTSOURCES.find((source) => source.id == sourceId);
        return source ? source.type : null;
      },
    },
    {
      field: 'dateOfBirth',
      hide: true,
      width: 150,
      renderHeader: () => <strong>{'Date of Birth'}</strong>,
      renderCell: (params) => (
        <Typography>
          {params.row.dateOfBirth
            ? new Date(params.row.dateOfBirth).toLocaleDateString()
            : ''}
        </Typography>
      ),
    },

    {
      field: 'countryOfResidence',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Residence'}</strong>,
      width: 150,
      valueGetter: (params) => {
        const countryId = params.row.countryOfResidence;
        const country = Countries.find((country) => country.id === countryId);
        return country ? country.name : '';
      },
    },
    {
      field: 'ReferralName',
      renderHeader: () => <strong>{'Referral'}</strong>,
      width: 200,
      valueGetter: (params) => {
        return params.row.referralName;
      },
    },
    {
      field: 'appType',
      renderHeader: () => <strong>{'App'}</strong>,
      width: 100,
      valueGetter: (params) => {
        return params.row.noOfApplications;
      },
    },
    {
      field: 'status',
      renderHeader: () => <strong>{'Student Status'}</strong>,
      width: 150,
      valueGetter: (params) => {
        const statusId = params.row.status;
        const status = STUDENTSTATUS.find((status) => status.id == statusId);
        return status ? status.type : null;
      },
    },

    {
      field: 'citizenship',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Citizenship'}</strong>,
      width: 150,
      hide: 'true',
      valueGetter: (params) => {
        const countryId = params.row.citizenship;
        const country = Countries.find((country) => country.id === countryId);
        return country ? country.name : '';
      },
    },

    {
      field: 'passportNumber',
      renderHeader: () => <strong>{'Passport'}</strong>,
      width: 100,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.passportNumber}</Typography>
      ),
    },

    {
      field: 'branchOfficeId',
      width: 200,
      align: 'left',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Branch Name'}</strong>,
      valueGetter: (params) => {
        const branchOfficeId = params.row.branchOfficeId;
        const branch = offices.find((branch) => branch.id == branchOfficeId);
        return branch ? branch.name : '';
      },
    },
    {
      field: 'gender',
      renderHeader: () => <strong>{'Gender'}</strong>,
      hide: true,
      width: 60,
      renderCell: (params) => (
        <Typography>
          {params.row.gender === 1
            ? 'Male'
            : params.row.gender === 2
            ? 'Female'
            : 'Other'}
        </Typography>
      ),
    },
    {
      field: 'profileComplete',
      headerName: 'Profile Complete',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Profile Complete'}</strong>,
      renderCell: (params) => (
        <strong>
          {params.row.profileComplete ? (
            <MyTooltip
              icon={<HiCheckCircle color="success" />}
              title="Profile Completed"
            >
              <IconButton size="large">
                <HiCheckCircle color="#03C988" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<HiCheckCircle />} title="Profile Not Completed">
              <IconButton size="large">
                <CiNoWaitingSign color="red" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },

    {
      field: 'isLoginCreated',
      headerName: 'Login Status',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Login Status'}</strong>,
      renderCell: (params) => (
        <strong>
          {params.row.isLoginCreated ? (
            <MyTooltip
              icon={<HiCheckCircle color="success" />}
              title="Already Logged In"
            >
              <IconButton size="large">
                <HiCheckCircle color="#03C988" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<HiCheckCircle />} title="Student Not Logged in">
              <IconButton size="large">
                <CiNoWaitingSign color="red" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
  ];

  let studentsLeadColumnsApp = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckboxApp,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllApp}
          indeterminate={
            selectedRows.length > 0 &&
            selectedRows.length < dataSetApplications.length
          }
        />
      ),
    },
    {
      field: 'name',
      renderHeader: () => <strong>{'Name'}</strong>,
      width: 250,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => (
        <MyTooltip
          title={`${params.row.firstName} ${params.row.lastName}`}
          color="primary"
        >
          <Typography className="clickable">{`${params.row.firstName} ${params.row.lastName}`}</Typography>
        </MyTooltip>
      ),
    },

    {
      field: 'email',
      align: 'left',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Email'}</strong>,
      width: 200,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'ReferralName',
      headerName: 'Referral',
      width: 200,
      valueGetter: (params) => {
        return params.row.referralName;
      },
    },

      {
          field: 'source',
          align: 'center',
          headerAlign: 'center',
          renderHeader: () => <strong>{'Source'}</strong>,
          valueGetter: (params) => {
            const sourceId = params.row.source;
            const source = STUDENTSOURCES.find((source) => source.id == sourceId);
            return source ? source.type : null;
          },
        },

    {
      field: 'universityName',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'University'}</strong>,
      width: 250,
      renderCell: (params) => (
        <Typography>{params.row.universityName}</Typography>
      ),
    },
    {
      field: 'citizenship',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{'Citizenship'}</strong>,
      width: 150,
      valueGetter: (params) => {
        const countryId = params.row.citizenship;
        const country = Countries.find((country) => country.id === countryId);
        return country ? country.name : '';
      },
    },
    {
      field: 'Destination',
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Destination'}</strong>,
      width: 150,
      valueGetter: (params) => {
        const countryId = params.row.countryId;
        const country = Countries.find((country) => country.id === countryId);
        return country ? country.name : '';
      },
    },
    {
      field: 'status',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{'App Status'}</strong>,
      width: 100,
      valueGetter: (params) => {
        const statusId = params.row.status;
        const status = APPLICATION_STATUSES.find(
          (appStatus) => appStatus.id == statusId
        );
        return status ? status.type : null;
      },
    },
    {
      field: 'year',
      headerName: 'Intake Year',
      align: 'center',
      renderHeader: () => <strong>{'Intake Year'}</strong>,
      renderCell: (params) => <Typography>{params.row.year}</Typography>,
    },
    {
      field: 'branchOfficeId',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'Branch'}</strong>,
      valueGetter: (params) => {
        const branchOfficeId = params.row.branchOfficeId;
        const branch = offices.find((branch) => branch.id == branchOfficeId);
        return branch ? branch.name : '';
      },
    },
    {
      field: 'intake',
      headerName: 'Intake Month',
      width: 150,
      hide: true,
      valueGetter: (params) => {
        const intake = params.row.intake;
        const intakeMonth = INTAKES.find((inta) => inta.id == intake);
        return intakeMonth ? intakeMonth.type : null;
      },
    },
    {
      field: 'year',
      headerName: 'Intake Year',
      width: 150,
    },
    {
      field: 'studyLevelId',
      headerName: 'Study Level',
      hide: true,
      width: 170,
      valueGetter: (params) => {
        const studyLevelId = params.row.studyLevelId;
        const studyLevel = STUDY_LEVELS.find((stu) => stu.id == studyLevelId);
        return studyLevel ? studyLevel.type : null;
      },
    },
  ];

  const handleCitizenshipFilterChange = (event, value) => {
    setCitizenshipFilter(value ? value.id : 0);
  };
  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    setLeadFirstName(selectedValue);
  };

  const handleClearFilters = () => {
    setAppStatus(0);
    setProfileType(0);
    setLogin(0);
    setCountryOfRes(0);
    setCitizenshipFilter(0);
    setIntakeYear(0);
    setBranchOfficeFilter(0);
    setStartDateFilter(addMonths(new Date(), -1).toISOString().split('T')[0]);
    setEndDateFilter(new Date().toISOString().split('T')[0]);
    setUniversity(0);
    setCourse(0);
    setIntakeMonth(0);
    setIntakeYear(0);
    setStudyLevelFilter(0);
    setLeadFirstName('');
    setLeadListName('');
    setEmail('');
    setPhoneNumber('');
    setSelectedRows([]);
    setStudentsLeadIdList([]);
    setShowData(false);
    setShowDataApp(false);
    setSelectAll(false);
    fetchData(false);
    setStudentType(0);
    setAppType(0);
    setstudentStatus(0);
    setSource(0);
    setDestination(null);
    fetchDataApp(false); // Refetch the data after clearing filters
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedStudensLeadtId = studentsLead.find(
        (studentLead) => studentLead.id === rowId
      ).id;
      handleAddStudentsLeadId(selectedStudensLeadtId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };


  const handleRowSelectionApp = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedDataSetAppId = dataSetApplications.find(
        (dataset) => dataset.studentId === rowId
      ).studentId;
      handleAddStudentsLeadId(selectedDataSetAppId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const WhatsappConfirmDialog = () => (
    <Dialog
      open={openWhatsappConfirmDialog}
      onClose={handleWhatsappCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Whatsapp'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the whatsapp?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleWhatsappCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendWhatsapp}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="h-96 w-full">
      <div className="bg-white px-4 mb-4 shadow-sm">
        <StudentComFilters
          appStatus={appStatus}
          handleAppStatus={handleAppStatus}
          phoneNumber={phoneNumber}
          handlePhoneNumber={handlePhoneNumber}
          countryOfRes={countryOfRes}
          handleCountryOfResidence={handleCountryOfResidence}
          profileType={profileType}
          setProfileType={setProfileType}
          login={login}
          setLogin={setLogin}
          citizenship={citizenship}
          university={university}
          course={course}
          intakeMonth={intakeMonth}
          intakeYear={intakeYear}
          studyLevelFilter={studyLevelFilter}
          Name={Name}
          email={email}
          offices={offices}
          BranchOfficeId={BranchOfficeId}
          setBranchOfficeFilter={setBranchOfficeFilter}
          handleCitizenshipFilterChange={handleCitizenshipFilterChange}
          handleUniversityChange={handleUniversityChange}
          handleCourseChange={handleCourseChange}
          handleIntakeMonthChange={handleIntakeMonthChange}
          handleIntakeYearChange={handleIntakeYearChange}
          handleStudyLevelFilterChange={handleStudyLevelFilterChange}
          handleNameChange={handleNameChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          handleEmailChange={handleEmailChange}
          handleApplyFiltersApp={handleApplyFiltersApp}
          totalSelected={totalSelected}
          handleStartDateFilter={handleStartDateFilter}
          handleEndDateFilter={handleEndDateFilter}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          handleDatasetChange={handleDatasetChange}
          datasetOption={datasetOption}
          destination={destination}
          setDestination={setDestination}
          setStudentType={setStudentType}
          studentType={studentType}
          setAppType={setAppType}
          appType={appType}
          setstudentStatus={setstudentStatus}
          studentStatus={studentStatus}
          source={source}
          setSource={setSource}
        />
        {showData && datasetOption == 1 ? (
          dataLoaded ? (
            <Grid item style={{ height: 800, width: '100%' }}>
              <DataGridPro
                rows={studentsLead}
                columns={studentsLeadColumns}
                totalPageSize={totalPages}
                loading={loading}
                pagination={true}
                paginationMode="server"
                pageSize={pageSize}
                pageSizeOptions={[800]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowCount={totalrows}
                onPageChange={(newPage) => setPageNumber(newPage + 1)}
                page={pageNumber - 1}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: GridPagination,
                }}
              />
            </Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : showDataApp && datasetOption == 2 ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? (
            <Grid item style={{ height: 800, width: '100%' }}>
              <DataGridPro
                rows={dataSetApplications}
                columns={studentsLeadColumnsApp}
                totalPageSize={totalPages}
                loading={loading}
                pagination={true}
                paginationMode="server"
                pageSize={pageSize}
                pageSizeOptions={[800]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowCount={totalrows}
                onPageChange={(newPage) => setPageNumber(newPage + 1)}
                page={pageNumber - 1}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: GridPagination,
                }}
              />
            </Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          datasetOption != 0 && (
            <div>No data to display. Please apply filters to show data.</div>
          )
        )}

        <WhatsappSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          dialogueCommunicationName={dialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          // fromphoneList={Phone_From}
          // setFromNumber={setFromNumber}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <WhatsappConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalSelected={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(StudentBulkWhatsapp);
