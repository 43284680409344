/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Agent from '../../../api/agent';
import { INTAKES, intakeYearList, STUDENTSOURCES } from '../../../Util/Constants';
import { TextField, Select, MenuItem, InputLabel, FormControl, Autocomplete, Chip, CircularProgress, Paper, Grid, Typography } from '@mui/material';
import { Countries } from '../../../Util/Util';
import { motion } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorPopup from '../RegionalReport/ErrorPopUp';

const statusTypes = [
  { id: 1, type: 'Lead Organic', key: 'organicLead' },
  { id: 2, type: 'Lead Paid', key: 'paidLead' },
  { id: 3, type: 'Total Lead', key: 'totalLead' },
  { id: 4, type: 'Potential Lead', key: 'potentialLead' },
  { id: 5, type: 'Qualified', key: 'qualified' },
  { id: 6, type: 'Applied', key: 'applied' },
  { id: 7, type: 'Applied Ratio', key: 'appliedRatio', isRatio: true },
  { id: 8, type: 'Conditional Offer', key: 'conditionalOffer' },
  { id: 9, type: 'Unconditional Offer', key: 'unconditionalOffer' },
  { id: 10, type: 'Total Offer', key: 'totalOffer' },
  { id: 11, type: 'Offer Ratio', key: 'offerRatio', isRatio: true },
  { id: 12, type: 'Rejection', key: 'rejection' },
  { id: 13, type: 'Rejection Ratio', key: 'rejectionRatio', isRatio: true },
  { id: 14, type: 'Deposit', key: 'deposite' },
  { id: 15, type: 'Num of CAS', key: 'numOfCAS' },
  { id: 16, type: 'Enrolled', key: 'enrolled' },
  { id: 17, type: 'Enrolled Ratio', key: 'enrolledRatio', isRatio: true },
];

const staticTargets = {
  organicLead: 100,
  paidLead: 100,
  totalLead: 200,
  potentialLead: 50,
  qualified: 80,
  applied: 60,
  appliedRatio: 30,
  conditionalOffer: 40,
  unconditionalOffer: 30,
  totalOffer: 70,
  offerRatio: 70,
  rejection: 10,
  rejectionRatio: 10,
  deposite: 50,
  numOfCAS: 40,
  enrolled: 30,
  enrolledRatio: 50,
};

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <motion.div
      className="w-24 h-24 flex items-center justify-center"
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      }}
    >
      <motion.span
        className="block w-12 h-12 border-t-4 border-blue-500 rounded-full"
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      />
    </motion.div>
  </div>
);

const GlobalSalesProgressReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sources, setSources] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [year, setYear] = useState('');
  const [citizenship, setCitizenship] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [type, setType] = useState(1);

  const hasAccess = permissions.CanAccessGlobalSalesReportB2C;

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await Agent.Cache.getBranchOffices();
          if (Array.isArray(response)) {
            setBranches(response.map(branch => ({ id: branch.id.toString(), label: branch.name })));
          } else {
            console.error('Unexpected response format for branch offices:', response);
          }
        } catch (err) {
          console.error('Error fetching branch offices:', err);
          setError('Failed to fetch branch offices. Please try again later.');
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [hasAccess]);

  const fetchData = useCallback(async () => {
    if (!hasAccess) return;

    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.globalSalesProgress({
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources,
        branches: selectedBranches.map(branchId => parseInt(branchId, 10)),
        year: year ? [year] : [],
        citizenship,
        type
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
      } else {
        setError('No data available for the selected criteria.');
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch data. Please try again.');
      setShowErrorPopup(true);
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [hasAccess, intakeYears, intakeMonths, startDate, endDate, sources, selectedBranches, year, citizenship, type]);

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setError(null);
  };

  const handleGenerateReport = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setSources([]);
    setSelectedBranches([]);
    setYear('');
    setCitizenship([]);
    setData([]);
    setError(null);
    setShowErrorPopup(false);
    setType(1);
  };

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === 0 || value === '') return '';
    if (isRatio) {
      const formattedValue = Number(value).toFixed(0);
      return formattedValue === '0' ? '' : `${formattedValue}%`;
    }
    return value.toString();
  };

  const calculateTotal = (statusKey) => {
    return data.reduce((sum, monthData) => {
      const value = monthData[statusKey];
      return sum + (value !== undefined && value !== null ? Number(value) : 0);
    }, 0);
  };

  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Global Sales Progress Report(B2C)!" />
      </Paper>
    );
  }

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
<div className="mb-6 text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Global Sales Progress Report ({type === 1 ? 'Overall' : 'B2C'})
        </h2>
        <div className="flex justify-between items-center">
          <Link
            to="/reportpanel"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowBackIcon className="w-5 h-5 mr-2" />
            Back to Reporting Panel
          </Link>
          <div className="flex items-center space-x-4">
            <FormControl size="small" className="min-w-[150px]">
              <InputLabel id="type-select-label">Report Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Report Type"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={1}>Overall</MenuItem>
                <MenuItem value={2}>B2C</MenuItem>
              </Select>
            </FormControl>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FilterListIcon className="w-5 h-5 mr-2" />
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </div>
      </div>
      {showFilters && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-years"
                options={intakeYearList.map(year => ({ id: year.year.toString(), label: year.year.toString() }))}
                value={intakeYears.map(id => ({ id, label: id }))}
                onChange={(_, newValue) => setIntakeYears(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Intake Years" placeholder="Select intake years" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-months"
                options={[{ id: '', label: 'All Months' }, ...INTAKES.map(month => ({ id: month.id.toString(), label: month.type }))]}
                value={intakeMonths.map(id => ({ id, label: INTAKES.find(m => m.id.toString() === id)?.type || id }))}
                onChange={(_, newValue) => setIntakeMonths(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Intake Months" placeholder="Select intake months" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="sources"
                options={STUDENTSOURCES.map(source => ({ id: source.id.toString(), label: source.type }))}
                value={sources.map(id => ({ id, label: STUDENTSOURCES.find(s => s.id.toString() === id)?.type || id }))}
                onChange={(_, newValue) => setSources(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="branches"
                options={branches}
                loading={loadingBranches}
                value={selectedBranches.map(id => branches.find(b => b.id === id) || { id, label: id })}
                onChange={(_, newValue) => setSelectedBranches(newValue.map(v => v.id))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Branches"
                    placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries.map(country => ({ id: country.id.toString(), label: country.name }))}
                value={citizenship.map(id => ({ id, label: Countries.find(c => c.id.toString() === id)?.name || id }))}
                onChange={(_, newValue) => setCitizenship(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenship" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
      
          </Grid>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset Filters
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? 'Generating...' : 'Generate Report'}
            </button>
          </div>
        </>
      )}

      {loading ? (
        <Paper elevation={3} className="mt-6 p-4">
          <Typography variant="body1" className="mb-2 text-center">Generating report...</Typography>
          <LoadingAnimation />
        </Paper>
      ) : error ? (
        <Paper elevation={3} className="mt-6 p-4">
          {showErrorPopup && <ErrorPopup error={error} onClose={handleCloseErrorPopup} />}
        </Paper>
      ) : (
        data.length > 0 && (
          <Paper elevation={3} className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg">
            <div className="overflow-x-auto">
              <table className="w-full text-xs border-collapse">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40" rowSpan={2}>Status Type</th>
                    {months.map((month) => (
                      <th key={month} className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200" colSpan={2}>{month.slice(0, 3)}</th>
                    ))}
                    <th className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-amber-200" colSpan={2}>Total</th>
                  </tr>
                  <tr>
                    {months.map((month) => (
                      <React.Fragment key={`header-${month}`}>
                        <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">Target</th>
                        <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">Achieve</th>
                      </React.Fragment>
                    ))}
                    <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">Target</th>
                    <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">Achieve</th>
                  </tr>
                </thead>
                <tbody>
                  {statusTypes.map((status, index) => (
                    <tr key={status.id} className={index % 2 === 0 ? 'bg-white hover:bg-sky-50' : 'bg-gray-50 hover:bg-sky-100'}>
                      <td className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300" style={{backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FAFB'}}>
                        {status.type}
                      </td>
                      {months.map((month, monthIndex) => {
                        const monthData = data.find(d => d.month === monthIndex + 1) || {};
                        const achievement = monthData[status.key];
                        const target = '';
                        return (
                          <React.Fragment key={`${status.id}-${month}`}>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-red-600 text-xs">{formatValue(target, status.isRatio)}</div>
                            </td>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-green-600 text-xs">
                                {formatValue(achievement, status.isRatio)}
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                      <td className="py-1 px-1 text-center border-r border-gray-300">
                        <div className="font-medium text-red-600 text-xs">{formatValue('', status.isRatio)}</div>
                      </td>
                      <td className="py-1 px-1 text-center border-r border-gray-300">
                        <div className="font-medium text-green-600 text-xs">
                          {formatValue(calculateTotal(status.key), status.isRatio)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Paper>
        )
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(GlobalSalesProgressReport);

