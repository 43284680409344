import axios from 'axios';
// import { toast } from 'react-toastify';
import { history } from '..'; // this is basically pointing to index.js
// require('dotenv').config();


const OTHER_API_URL =process.env.REACT_APP_API_OTHER_URL ||'https://test-services.tclglobal.co.uk';

axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

//  axios.defaults.baseURL = 'https://main-service-iyy5u6jjmq-el.a.run.app/';


axios.interceptors.request.use(
  (request) => {
    const user = JSON.parse(localStorage.getItem('tcl_user'));
    if (user) {
      request.headers.Authorization = `Bearer ${user.token}`;
    }
    // if (user) {
    //   request.headers.Authorization = `Bearer ${
    //     request.url.includes('services.tclglobal.co.uk')
    //       ? user.externalToken
    //       : user.token
    //   }`;
    // }
    //console.log(request);
    // you can also edit request config
    return request; // very import to return request - otherwise requests will be blocked
  },
  (error) => {
    // may be log into some file or something like that?
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      response.data = {
        data: response.data,
        pagination: JSON.parse(pagination),
      };
      return response;
    }
    // you can also edit response here
    return response; // very import to return request - otherwise requests will be blocked
  },

  (error) => {
    // may be log into some file or something like that?
    console.log(error);
    const { data, status, statusText } = error.response || error;
    debugger;
    if (data) {
      switch (status) {
        case 400:
          if (typeof data === 'string') {
            // this means, this is just a bad request, not necessairy any validation error
            // toast.error('Bad Request');
            console.log('Status Code: 400, Bad Request', data);
            return Promise.reject(statusText + ': ' + data);
          }
          if (data.errors) {
            // this means, we have validation errors coming back from the API
            const validationErrors = [];
            for (const key in data.errors) {
              if (data.errors[key]) {
                validationErrors.push(data.errors[key]);
              }
            }
            //throw validationErrors.flat();
            // toast.error('Bad Request : ', data);
            console.log('Bad Request : 400 ', data);
            return Promise.reject(validationErrors.flat());
          }
          break;
        case 401:
          // toast.error(data);
          history.push('/');
          return Promise.reject(statusText + ': ' + data);
        case 404:
          history.push('/not-found');
          // toast.error(`Failed! ${data}`);
          console.log(`Failed! ${data}`);
          break;
        case 500:
          // toast.error('Server Error: ', data);
          console.log('Server Error: 500,  ', data);
          return Promise.reject(
            statusText + ': ' + data.message + '\n' + data.details
              ? data.details
              : ''
          );
        // setServerError(data); // dispathcing this isn't really working because we dont have access to dispatch here. may need to come back here
        // history.push('/server-error');
      }
    }
  }
);

const responseBody = (response) => response?.data;

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  del: (url) => axios.delete(url).then(responseBody),
};

const Universities = {
  saveUniversity: (university) =>
    requests.post('/universities/save', university),
  saveCountries: (uniCountries) =>
    requests.post('/universities/savecountries', uniCountries),
  saveTests: (uniTests) => requests.post('/universities/savetests', uniTests),
  deleteUniversity: (universityId) => {
    return axios.delete(`/universities/delete/${universityId}`);
  },
  disableUniversity: (universityid) => {
    return axios.delete(`/universities/activeorinactive/${universityid}`);
  },
  getUniversity: (id) => requests.get(`/universities/${id}`),
  list: () => requests.get('/universities/universities'),
  listWithParams: (params) =>
    axios
      .get('/universities/universitieswithparams', { params })
      .then(responseBody),
  listApplicationMethods: (universityId) =>
    requests.get(`/universities/methods/${universityId}`),

  saveApplicationMethods: (method, file) => {
    let formData = new FormData();
    if (file) {
      formData.append('File', file);
    }
    if (method.id) {
      formData.append('id', method.id);
    }
    if (method.universityId) {
      formData.append('universityId', method.universityId);
    }
    if (method.courseLevelId) {
      formData.append('courseLevelId', method.courseLevelId);
    }
    if (method.universityId) {
      formData.append('universityId', method.universityId);
    }
    if (method.portalType) {
      formData.append('portalType', method.portalType);
    }
    if (method.method) {
      formData.append('method', method.method);
    }
    if (method.url) {
      formData.append('url', method.url);
    }
    if (method.description) {
      formData.append('description', method.description);
    }
    if (method.login) {
      formData.append('login', method.login);
    }
    if (method.password) {
      formData.append('password', method.password);
    }
    return axios
      .post('/universities/savemethod', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  deleteMethod: (id) => {
    return axios.delete(`/universities/deleteapplicationmethod/${id}`);
  },
  search: (country, name) => requests.get(`/search/getuni/${country}/${name}`),
};

const Users = {
  getUser: (id) => requests.get(`/users/user/${id}`),
  getStuIdbyAppUserId: (appuserid) =>
    requests.get(`/users/studentidbyappuserid/${appuserid}`),
  editUser: (user) => requests.post('/users/edituser', user),
  list: () => requests.get('/users/users'),
  allUserList: () => requests.get('/users/allusers'),
  listUserCountries: () => requests.get('/users/usercountries'),
  getClaims: (userId) => requests.get(`/users/users/${userId}/claims`),
  setClaims: (userId, claims) =>
    requests.post(`/users/users/${userId}/claims`, claims),
  save2f: (user) => requests.post('/users/save2f', user),
};

const AutoReportConfigs = {
  updateConfig: (reportId, data) =>
    requests.post(`${OTHER_API_URL}/store/updateconfig/${reportId}`, data),
  getConfig: (reportId) =>
    requests.get(`${OTHER_API_URL}/store/config/${reportId}`),
  sendAccountReport1: () =>
    requests.get(`${OTHER_API_URL}/data/accountreport1`),
};

const Agents = {
  saveSocialMedia: (urls) => requests.post('/agents/savesocialmedia', urls), //save social media  info for agent
  saveAgentTerritory: (uniCountries) =>
    requests.post('/agents/savecountries', uniCountries), //save agent uni  countries or territory
  list: (params) => axios.get('/agents/agents', { params }).then(responseBody),
  getAgentByAppUserId: (appuserId) =>
    requests.get(`/agents/getagentbyappuserid/${appuserId}`),
  getAgent: (agentId, userId) =>
    agentId > 0
      ? requests.get(`/agents/getagentbyagentid/${agentId}`)
      : requests.get(`/agents/getagentbyuserid/${userId}`),
  // getAgent: (agentId, userId) => {
  //   if (agentId > 0) {
  //     return requests.get(`agents/getagentbyagentid/${agentId}`);
  //   } else {
  //     return requests.get(`agents/getagentbyuserid/${userId}`);
  //   }
  // },
  updateAgent: (agent) => axios.post('/agents/editagent', agent),
  updateOthers: (editDto) => axios.post('/agents/editothers', editDto),
  updateStatus: (agent) => axios.post('/agents/updatestatus', agent),
  updateBusinessDevelopment: (agentId, businessDevAppUserDetailsId) =>
    requests.get(
      `/agents/updatebusinessdevelopment/${agentId}/${businessDevAppUserDetailsId}`
    ),
  deleteCompanyLogo: (fileName) => {
    return axios.delete(`/cloudfiles/deletecompanylogo/${fileName}`);
  },
  deleteAgentPassport: (agentId) => {
    return axios.delete(`/cloudfiles/deleteagentpassport/${agentId}`);
  },
  downloadfile: (filename) => {
    return axios.get(`/cloudfiles/downloadfile/${filename}`, {
      responseType: 'arraybuffer',
    });
  },
  saveCommission: (commission) =>
    requests.post('/agents/savecommissions', commission),
  listCommission: (agentId) =>
    requests.get(`/agents/listcommission/${agentId}`),

  disableAgent: (id) => {
    return axios.delete(`/agents/disable/${id}`);
  },
  deleteAgent: (id) => {
    return axios.delete(`/agents/delete/${id}`);
  },
  getTemplates: () => requests.get(`${OTHER_API_URL}/contract/templates/`),
  getAgreements: (agentId) =>
    requests.get(`${OTHER_API_URL}/store/partners/getagreements/${agentId}`),
  addManualAgreement: (file, agentId, sender) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('agentId', agentId);
    formData.append('sender', sender);
    return axios
      .post(`${OTHER_API_URL}/contract/upload`, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  downloadAgreement: (fileName) => {
    return axios.post(`${OTHER_API_URL}/contract/agreement/download`, {
      fileName,
      responseType: 'arraybuffer',
    });
  },
  deleteAgreement: (id, fileName, isManual) =>
    requests.post(`${OTHER_API_URL}/contract/agreement/delete`, {
      id,
      fileName,
      isManual,
    }),
  sendAgreement: (info) =>
    requests.post(`${OTHER_API_URL}/contract/send`, info),
  sendAgreementReminder: (id) =>
    requests.get(`${OTHER_API_URL}/contract/remind/${id}`),
  getStaff: (agentId) => requests.get(`/agents/users/${agentId}`),
  saveStaff: (staff) => requests.post('/agents/user/save', staff),
  updateLoginEmail: (staff) =>
    requests.post('/agents/user/account/email', staff),
  updateLoginPassword: (staff) =>
    requests.post('/agents/user/account/password', staff),
  disableToggleAgentUser: (staff) =>
    requests.post('/agents/user/account/disabletoggle', staff),
  getLogins: (userId) =>
    requests.get(`${OTHER_API_URL}/gen/users/${userId}/activities/LOGIN`),
  register: (user) => requests.post('/account/registeragent', user),
};

const Applications = {
  add: (studentId, courseAllocationId, applicationId = 0) =>
    requests.post('/applications/add', {
      studentId,
      courseAllocationId,
      applicationId,
    }),
  list: (params) =>
    axios.get('/applications/applications', { params }).then(responseBody),
  listdashboard: () => requests.get('/applications/applicationdashboard'),
  // listAllApp: () => requests.get('/applications/allapplications'),
  listdashboardAgent: () =>
    requests.get('/applications/applicationdashboardagent'),
  getApplication: (id) => requests.get(`/applications/${id}`),
  getApplicationByStuId: (id) =>
    requests.get(`/applications/applicationsbystudentid/${id}`),
  edit: (application) => requests.post('/applications/edit', application),
  applPriorityUpdate: (appid, priorityId) =>
    requests.put(`applications/applpriorityupdate/${appid}/${priorityId}`),
  disableApplication: (id) => {
    return axios.delete(`/applications/disable/${id}`);
  },
  assign: (assignDto) => requests.put('/applications/assign', assignDto),
};

const Programs = {
  list: (params) =>
    axios.get('/programs/programs', { params }).then(responseBody),
};
const Referral = {
  listdashboardReferral: () =>
    requests.get('/referrals/applicationdashboardreferral'),
  saveReferral: (referralData) =>
    requests.post('/account/registerreferral', referralData),
  ReferralList: (params) =>
    axios.get('/referrals/referrals', { params }).then(responseBody),
  List: (params) =>
    axios.get('/referrals/dropdownreferrals', { params }).then(responseBody),
  getreferralbyuserId: (userId) =>
    requests.get(`/referrals/getreferralbyuserid/${userId}`),
  getreferralbyRefId: (referralId) =>
    requests.get(`referrals/getreferralbyreferralid/${referralId}`),
  saveRefDocMarkAsComplete: (id, iscomplete) =>
    requests.put(`/referrals/completefile/${id}/${iscomplete}`),
  updateReferralUser: (modifiedReferral) =>
    axios.post('/referrals/editreferral/', modifiedReferral),
  uploadFile: (modifiedReferral) =>
    axios.post('/cloudfiles/upload/', modifiedReferral),
  sendInvitation: (invitionData) =>
    requests.post('/referrals/refstudentinvitation/', invitionData),
  getApplicationsByStudId: (studId) =>
    requests.get(`applications/applicationsbystudentid/${studId}`),
  refStudentList: (appUserId) =>
    axios.get(`/referrals/referralstudents/${appUserId}`),
  RegisterStudentbyRefId: (referralId) =>
    axios.post(`/referrals/referraltostudent/${referralId}`),
  ChangeRoleByAppuserId: (userId, roleName) =>
    axios.post(`referrals/changerole/${userId}/${roleName}`),
  CreateReferralbyStuId: (studentId) =>
    axios.post(`students/studenttoreferral/${studentId}`),
  updateLandingPage: (referralId, isreferraldashboard) =>
    requests.post(
      `referrals/editdasboardflag/${referralId}/${isreferraldashboard}`
    ),
  assign: (assignDto) => requests.put('/referrals/assign', assignDto),
  deleteReferral: (id) => {
    return axios.delete(`/referrals/delete/${id}`);
  },
  add: (msg) => requests.post('/referrals/addmessage', msg),
  getMessagebyRefId: (referralId) =>
    axios.get(`/referrals/getmessages/${referralId}`).then(responseBody),
};
const Students = {
  getStudent: (id) => requests.get(`/students/${id}`),
  getStudentSummary: (id) => requests.get(`/students/${id}/summary`),
  saveStudent: (studentDto) => requests.post('/students/save', studentDto),
  saveQuickStudent: (studentDto) =>
    requests.post('/students/quicksave', studentDto),
  registerStudent: (studentDto) =>
    requests.post('/students/registerstudentjourney', studentDto),
  registerRefStudent: (studentDto) =>
    requests.post('/students/registerreferralstudentjourney', studentDto),
  list: (params) =>
    axios.get('/students/students', { params }).then(responseBody),
  programList: (params) =>
    axios.get('/students/programstudents', { params }).then(responseBody),
  listdashboard: () => requests.get('/students/studentsdashboard'),
  create: (agentAppUserId, createdByAppUserId) =>
    requests.post('/students/register', {
      agentAppUserId,
      createdByAppUserId,
    }),
  assign: (assignDto) => requests.put('/students/assign', assignDto),
  setStatus: (id, status) =>
    requests.put(`/students/setstatus/${id}/${status}`),
  disableStudent: (id) => {
    return axios.delete(`/students/disable/${id}`);
  },
  emailExists: (email) => requests.get(`/students/exist/${email}`),
  getPreferences: (id) => requests.get(`/students/${id}/preferences`),
  updatePreferences: (studentDto) =>
    requests.post('/students/updatepreferences', studentDto),
  getStudentLeadByStuId: (id) =>
    requests.get(`/students/getstudentleadbystuid/${id}`),
  updateStudentContactInfo: (payload) =>
    requests.post('/students/updatecontracts', payload),
};

const Maintenance = {
  getStaffList: () => requests.get('/maintenance/getstaffs'),
  syncStudent: (id, staffId) =>
    requests.get(`/maintenance/syncstudent/${id}/${staffId}`),
  syncApplication: (id) => requests.get(`/maintenance/syncapplication/${id}`),
};

const Appointment = {
  save: (appointment) => requests.post('/appointments/save', appointment),
  get: () => requests.get('/appointments/get'),
};

const UserTask = {
  delete: (id) => requests.del(`/usertasks/delete/${id}`),
  list: (params) => axios.get('/usertasks/get', { params }).then(responseBody),
  save: (userTask, file) => {
    let formData = new FormData();
    if (file) {
      formData.append('File', file);
    }
    formData.append('id', userTask.id);
    if (userTask.studentId) {
      formData.append('studentId', userTask.studentId);
    }
    formData.append('admin1AppUserDetailsId', userTask.admin1AppUserDetailsId);
    if (userTask.admin2AppUserDetailsId) {
      formData.append(
        'admin2AppUserDetailsId',
        userTask.admin2AppUserDetailsId
      );
    }
    if (userTask.admin3AppUserDetailsId) {
      formData.append(
        'admin3AppUserDetailsId',
        userTask.admin3AppUserDetailsId
      );
    }
    formData.append('priority', userTask.priority);
    formData.append('group', userTask.group);
    formData.append('type', userTask.type);
    formData.append('status', userTask.status);
    formData.append('title', userTask.title);
    formData.append('description', userTask.description);
    formData.append('deadline', new Date(userTask.deadline).toUTCString());
    formData.append('fileActionType', userTask.fileActionType);

    return axios
      .post('/usertasks/save', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  get: () => requests.get('/usertasks/get'),
  getStudentTasks: (studentId) =>
    requests.get(`/usertasks/getstudenttasks/${studentId}`),
  getDetails: (id) => requests.get(`/usertasks/get/${id}`),
};

const Account = {
  changeRole: (appUserId, newRole) =>
    requests.post(`/account/changerole/${appUserId}/${newRole}`),
  enableUser: (appUserDetailsId) =>
    requests.post(`/account/enable/${appUserDetailsId}`),
  disableUser: (appUserDetailsId) =>
    requests.post(`/account/disable/${appUserDetailsId}`),

 createStudentLoginForMobileApp: (id) => requests.post(`/students/appregister/${id}`),


  createStudentLogin: (email) =>
    requests.post('/account/studentlogin', {
      email,
    }),
  login: (email, password, browserUserInfo) =>
    requests.post('/account/login', {
      email,
      password,
      browserUserInfo,
    }),
  login2f: (email, password, browserUserInfo) =>
    requests.post('/account/login2f', {
      email,
      password,
      browserUserInfo,
    }),
  registerinternal: (
    email,
    password,
    firstName,
    lastName,
    role,
    isEmailConfirmed,
    companyName,
    browserUserInfo
  ) =>
    requests.post('/account/registerinternal', {
      email,
      password,
      firstName,
      lastName,
      role,
      isEmailConfirmed,
      companyName,
      browserUserInfo,
    }),
  register: (
    email,
    password,
    firstName,
    lastName,
    role,
    companyName,
    browserUserInfo
  ) =>
    requests.post('/account/register', {
      email,
      password,
      firstName,
      lastName,
      role,
      companyName,
      browserUserInfo,
    }),
  verifyEmail: (token, email) =>
    requests.post('/account/verifyEmail', { token, email }),
  resendVerificationEmail: (email) =>
    requests.get(`/account/resendVerificationEmail?email=${email}`),
  forgotPassword: (email) =>
    requests.post('/account/forgotpassword', { email: email }),
  resetPassword: (token, email, password, confirmpassword) =>
    requests.post('/account/resetPassword', {
      token: token,
      email: email,
      password: password,
      confirmpassword: confirmpassword,
    }),
};

const Messages = {
  addContactsNote: (msg) => requests.post('/messages/addcontactnote', msg), //accounts note for contact

  addAccountsNote: (msg) => requests.post('/messages/addaccountsnote', msg), //accounts note for agent or student note
  addemailWithTemplateStudentId: (payload) =>
    requests.post('/messages/addstudentdropdownNote', payload),

  addemailWithTemplateStudentsLead: (payload) =>
    requests.post('/messages/addstudentleaddropdownnote', payload),

  add: (msg) => requests.post('/messages/addmessage', msg),
  addApplicationNote: (msg) =>
    requests.post('/messages/addapplicationnote', msg),
  addStudentNote: (msg) => requests.post('/messages/addstudentnote', msg),
  sendEmailToStudent: (msg) =>
    requests.post('/messages/referralsharelinkmsg', msg),
  addAgentNote: (msg) => requests.post('/messages/addagentnote', msg),
  addReferralNote: (msg) => axios.post('messages/addreferralnote', msg),
  addcontactNote: (msg) => requests.post('messages/addcontactnote', msg),
  addUniversityNote: (msg) => requests.post('/messages/adduniversitynote', msg),
  list: () => requests.get('/messages/getmessages'),
  get: (id) => requests.get(`/messages/getmessage/${id}`),
  getAllAgentInfoMsg: (id) => requests.get(`/messages/getagentmessage/${id}`),
  allMsgDetailsInfo: (params) =>
    axios.get('/messages/allmessagesagentinfo', { params }).then(responseBody),
  totalMsgCount: (appUserId) =>
    requests.get(`/messages/totalmessagecount/${appUserId}`),
  edit: (msg) => requests.put('/messages/edit', msg),
  addStudentLeadNote: (msg) => {
    let formData = new FormData();
    if (msg.file) {
      formData.append('File', msg.file);
    }
    if (msg.file.name) {
      formData.append('FileName', msg.file.name);
    }
    return axios
      .post('/messages/addstudentleadnote', msg, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  addStudentNoteAtt: (msg) => {
    let formData = new FormData();
    if (msg.file) {
      formData.append('File', msg.file);
    }
    if (msg.file.name) {
      formData.append('FileName', msg.file.name);
    }
    return axios
      .post('/messages/addstudentnote', msg, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  addApplicationNoteAtt: (msg) => {
    let formData = new FormData();
    if (msg.file) {
      formData.append('File', msg.file);
    }
    if (msg.file.name) {
      formData.append('FileName', msg.file.name);
    }
    return axios
      .post('/messages/addapplicationnote', msg, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
};

const Summary = {
  get: (userId) => requests.get(`/summary/getsummary/${userId}`),
  getUnread: () => requests.get('/summary/getundread'),
  getNotifications: () => requests.get('/summary/getnotifications'),
  getApplicationTeamStats: () =>
    requests.get('/summary/getapplicationteamstats'),
  getChatNotifications: (userId) =>
    requests.get(`${OTHER_API_URL}/store/getnotifications/${userId}`),
  getDocById: (collection, id) =>
    requests.get(`${OTHER_API_URL}/store/${collection}/document/${id}`),
  updateAsReadNotifi: (id) => {
    return requests.post('/summary/updatereadnotification', id);
  },
};

const UserAssignment = {
  assign: (parentId, childId) =>
    requests.post(`/userassignment/assign/${parentId}/${childId}`),
  delete: (parentId, childId) =>
    requests.post(`/userassignment/delete/${parentId}/${childId}`),
  listAssignments: (userId, predicate) =>
    requests.get(`/userassignment/${userId}?predicate=${predicate}`),
  listUserAgents: (userId) =>
    requests.get(`/userassignment/getuseragents/${userId}`),
};

const Management = {
  listPartners: () => requests.get('/management/partners'),
  listCourseLevels: () => requests.get('/management/courselevels'),
  listLanguageTests: () => requests.get('/management/languagetests'),
};

const CloudFiles = {
  upload: (
    file,
    documentType,
    agentId,
    studentId,
    description,
    applicationId,
    universityId,
    applicationMethodId,
    studentLeadId,
    referralId,
    contactManagementId
  ) => {
    let formData = new FormData();
    formData.append('File', file);
    formData.append('documentType', documentType);
    if (description) {
      formData.append('description', description);
    }
    if (agentId) {
      formData.append('agentId', agentId);
    }
    if (studentId) {
      formData.append('studentId', studentId);
    }
    if (applicationId) {
      formData.append('applicationId', applicationId);
    }
    if (universityId) {
      formData.append('universityId', universityId);
    }
    if (applicationMethodId) {
      formData.append('applicationMethodId', applicationMethodId);
    }
    if (studentLeadId) {
      formData.append('studentLeadId', studentLeadId);
    }
    if (referralId) {
      formData.append('ReferralId', referralId);
    }
    if (contactManagementId) {
      formData.append('ContactManagementId', contactManagementId);
    }
    return axios
      .post('/cloudfiles/upload', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  delete: (
    fileName,
    agentId = 0,
    studentId = 0,
    applicationId = 0,
    universityId = 0,
    applicationMethodId = 0,
    studentLeadId = 0,
    ReferralId = 0,
    ContactManagementId = 0
  ) => {
    return axios
      .post('/cloudfiles/delete', {
        fileName,
        agentId,
        studentId,
        applicationId,
        universityId,
        applicationMethodId,
        studentLeadId,
        ReferralId,
        ContactManagementId,
      })
      .then(responseBody);
  },
  download: (fileNameLocal, fileName, containerName) => {
    return axios.get(
      `/cloudfiles/download/${containerName}?filename=${encodeURIComponent(
        fileName
      )}&fileNameLocal=${encodeURIComponent(fileNameLocal)}`,
      {
        responseType: 'arraybuffer',
      }
    );
  },
};

const Common = {
  getplatform: () => requests.get('/common/getplatform'),
  deletenotification: (id) => requests.del(`/common/deletenotification/${id}`),
};

const Cosmos = {
  getUsers: () => requests.get('/users/cosmosusers'),
  getAdmins: () => requests.get('/users/cosmosadminusers'),
  getAppTeam: () => requests.get('/users/cosmosappteamusers'),
};

const Courses = {
  // add: (course) => requests.post('/courses/savecourse', course),
  list: (universityId) => requests.get(`/courses/courses/${universityId}`),
  listglobal: (showAll) =>
    requests.get(`/courses/coursesglobal/courses/${showAll}`),
  getCourseGlobal: (id) => requests.get(`/courses/coursesglobal/${id}`),
  save: (course) => requests.post('/courses/save', course),
  saveGlobal: (course) => requests.post('/courses/saveglobal', course),
  addCourseAllocations: (courses) => requests.post('/courses/add', courses),
  addCourses: (courses) => requests.post('/courses/addcourses', courses),
  listGlobalWithParams: (params) =>
    axios
      .get('/courses/coursesglobalwithparams', { params })
      .then(responseBody),
  listDepartments: () => requests.get('/courses/departments'),
  saveDept: (dept) => requests.post('/courses/savedepartment', dept),
};

const Requirements = {
  save: (requirement) => requests.post('/requirements/add', requirement),
  saveItem: (item) => requests.post('/requirements/updateitem', item),
  list: (studentId) => requests.get(`/requirements/list/${studentId}`),
  upload: (file, type, studentId, requirementItemId) => {
    let formData = new FormData();
    formData.append('File', file);
    formData.append('type', type);
    if (studentId) {
      formData.append('studentId', studentId);
    }
    if (requirementItemId) {
      formData.append('requirementItemId', requirementItemId);
    }
    return axios
      .post('/requirements/upload', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  download: (requirementItemId, type) => {
    return axios.get(`/requirements/download/${requirementItemId}/${type}`, {
      responseType: 'arraybuffer',
    });
  },
  delete: (requirementItemId, type) =>
    requests.get(`/requirements/delete/${requirementItemId}/${type}`),
  deleteItem: (id, requirementId) => {
    return axios.delete(`/requirements/deleteitem/${id}/${requirementId}`);
  },
};

const Cache = {
  getBranchOffices: () => requests.get('/cache'),
};

const Employees = {
  getEmployees: () => requests.get('/employees/employees'),
  getEmployee: (id) => requests.get(`/employees/employees/${id}`),
  save: (emp) => requests.post('/employees/save', emp),
  uploadDoc: (file, bucketName, recordType, parentId) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('recordType', recordType);
    if (bucketName) {
      formData.append('bucketName', bucketName);
    }
    if (parentId) {
      formData.append('parentId', parentId);
    }

    return axios
      .post(`${OTHER_API_URL}/gen/upload`, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  deleteDoc: (employeeId, url) =>
    requests.post(`${OTHER_API_URL}/gen/file/remove`, {
      employeeId,
      url,
    }),

  getLogins: () => requests.get(`${OTHER_API_URL}/gen/users/logins`),
  getFiles: (userId) =>
    requests.get(`${OTHER_API_URL}/gen/users/files/${userId}`),
};

const General = {
  downloadDoc: (bucketName, fileName) => {
    return axios.post(`${OTHER_API_URL}/gen/download`, {
      bucketName,
      fileName,
      responseType: 'arraybuffer',
    });
  },
};

const Report = {

  counselorPerformance: (params) => {
    const { intakeYear, intakeMonth, startDate, endDate, branchId, citizenship, userId, year, type } = params;
    const queryParams = new URLSearchParams();
    
    if (intakeYear) queryParams.append('IntakeYear', intakeYear);
    if (intakeMonth) queryParams.append('IntakeMonth', intakeMonth);
    if (startDate) queryParams.append('StartDate', startDate);
    if (endDate) queryParams.append('EndDate', endDate);
    if (branchId) queryParams.append('BranchId', branchId);
    if (citizenship) queryParams.append('Citizenship', citizenship);
    if (userId) queryParams.append('UserId', userId);
    if (year) queryParams.append('Year', year.toString());
    queryParams.append('type', type.toString());

    return requests.post(`${OTHER_API_URL}/reporting/counselorPerformance?${queryParams.toString()}`);
  },


  leadsBySource: (params) => {

    const { intakeYears, intakeMonths, startDate, endDate, branches, citizenship, year, type } = params;
    const queryParams = new URLSearchParams();
    
    if (intakeYears) queryParams.append('IntakeYear', intakeYears.join(','));
    if (intakeMonths) queryParams.append('IntakeMonth', intakeMonths.join(','));
    if (startDate) queryParams.append('StartDate', startDate);
    if (endDate) queryParams.append('EndDate', endDate);
    if (branches) queryParams.append('BranchId', branches.join(','));
    if (citizenship) queryParams.append('Citizenship', citizenship.join(','));
    if (year) queryParams.append('Year', year.join(','));
    queryParams.append('Type', type.toString());

    return requests.post(`${OTHER_API_URL}/reporting/leadsbysource?${queryParams.toString()}`);
  },

  globalSalesProgress: (params) => {
    const { intakeYears, intakeMonths, startDate, endDate, sources, branches, citizenship, year, type = 1 } = params;
    const queryParams = new URLSearchParams();

    if (intakeYears) queryParams.append('IntakeYear', intakeYears.join(','));
    if (intakeMonths) queryParams.append('IntakeMonth', intakeMonths.join(','));
    if (startDate) queryParams.append('StartDate', startDate);
    if (endDate) queryParams.append('EndDate', endDate);
    if (sources) queryParams.append('Sources', sources.join(','));
    if (branches) queryParams.append('BranchId', branches.join(','));
    if (citizenship) queryParams.append('Citizenship', citizenship.join(','));
    if (year) queryParams.append('Year', year.join(','));
    queryParams.append('Type', type.toString());

    return requests.post(`${OTHER_API_URL}/reporting/globalsale?${queryParams.toString()}`);
  },

  
  applicationSummary: (request) => {
    return axios.post(`${OTHER_API_URL}/reporting/app`, request);
  },
  // statusSummaryData: (request) => {
  //   return axios.post(`${OTHER_API_URL}/reporting/app`, request); //application report status summary section
  // },
  studentSummary: (request) => {
    return axios.post(`${OTHER_API_URL}/reporting/student`, request);
  },
  salesSummaryData: (request) => {
    return axios.post(`${OTHER_API_URL}/reporting/sales`, request);
  },
  pendingSummary: (request) => {
    return axios.post(`${OTHER_API_URL}/reporting/pending`, request);
  },
};
const Communication = {
  deleteUnsubscribeMail: (id) => {
    return axios.delete(`/communications/delete/${id}`);
  },

  getUnsubscribedMailList: (params) =>
    axios
      .get('communications/unsubscribedemaillist', { params })
      .then(responseBody),

  unsubscribedEmail: (emailObj) =>
    requests.post('/communications/unsubscribedemailsave', emailObj), //for unscubribe email and update email

  listContactWhatsappList: (params) =>
    axios
      .get('/communications/commcontactmanagementwhatsapp', { params })
      .then(responseBody),

  sendContactBulkWhatsapp: (payload) =>
    requests.post(
      '/communications/bulkwhatsappsendtocontactmanagement',
      payload
    ), //for sending bulk whastapp  in bulk communications

  sendContactBulkEmail: (payload) =>
    requests.post('/communications/bulkemailsendtocontactmanagement', payload),

  listContactMailList: (params) =>
    axios
      .get('/communications/commcontactmanagementemail', { params })
      .then(responseBody),

  getContactCommunicationActivities: (appuserid) =>
    requests.get(`/communications/bulkcommcontactmanagements/${appuserid}`),

  getManageFromEmailList: (params) =>
    axios.get('/communications/fromemaillist', { params }).then(responseBody),

  createFromEmail: (emailObj) =>
    requests.post('/communications/frommailsave/', emailObj),

  disableFromMail: (id) => {
    return axios.delete(`/communications/inactivefromemail/${id}`);
  },

  getAgentCommsHistory: (appuserid) =>
    requests.get(`communications/bulkemailcommagents/${appuserid}`),
  getReferralCommsHistory: (appuserid) =>
    requests.get(`/communications/bulkemailcommreferrals/${appuserid}`),
  getDatasetCommunsActivities: (appuserid) =>
    requests.get(`communications/bulkemailcommdatasetstudents/${appuserid}`),
  listAgent: (params) =>
    axios.get('/communications/commsagents/', { params }).then(responseBody),
  listReferral: (params) =>
    axios.get('/communications/commsreferrals', { params }).then(responseBody),
  listStudentOld: (params) =>
    axios
      .get('/communications/commdatasetemailstudents', { params })
      .then(responseBody),
  listStudentOldWhatsapp: (params) =>
    axios
      .get('/communications/commdatasetwhatsappstudents', { params })
      .then(responseBody),
  listStudentApp: (params) =>
    axios
      .get('/communications/commdatasetemailapplications', { params })
      .then(responseBody),
  listStudentAppWhatsapp: (params) =>
    axios
      .get('/communications/commdatasetwhatsappapplications', { params })
      .then(responseBody),
  listDatasetGroups: () =>
    axios.get('/datasetgroups/datasetgroups').then(responseBody),

  getWhatsappTemplatesSingleAgent: (countryId) =>
    requests.get(`/communications/dropdownwhatsappagenttemplates/${countryId}`), // templates for one to one agent chat

  getWhatsappTemplateswithCountryId: (countryId) =>
    requests.get(`/communications/dropdownwhatsapptemplates/${countryId}`),

  getWhatsappStudentList: (params) =>
    axios
      .get('/communications/commwhatsappstudents', { params }) //for loading student list in whastapp bulk communications
      .then(responseBody),

  sendbulkWhatsappStudents: (payload) =>
    requests.post('/communications/bulkwhatsappsendtostudent', payload), //for sending bulk whastapp  in bulk communications

  getWhatsappTemplates: () => requests.get('/communications/whatsapptemplates'),
  getAgentWhatsappTemplates: () =>
    requests.get('/communications/whatsappagenttemplates'),
  getReferralWhatsappTemplates: () =>
    requests.get('/communications/whatsappreferraltemplates'),
  bulkWhatsappStudentsLead: (payload) =>
    requests.post('/communications/bulkwhatsappsendtolead', payload),
  SendBulkWhatsappDataset: (payload) =>
    requests.post('/communications/bulkwhatsappsendtodataset', payload),
  SendbulkWhatsappReferral: (payload) =>
    requests.post('/communications/bulkwhatsappsendtoreferral', payload),

  SendbulkWhatsappAgent: (payload) =>
    requests.post('/communications/bulkwhatsappsendtoagent', payload),

  whatsapplistStudents: (params) =>
    axios
      .get('/communications/communicationwhatsappstudents', { params })
      .then(responseBody),

  whatsapplistStudentsLead: (params) =>
    axios
      .get('/communications/commwhatsappstudentleads', { params })
      .then(responseBody),
  whatsapplistReferral: (params) =>
    axios
      .get('/communications/commswhatsappreferrals', { params })
      .then(responseBody),
  whatsapplistAgent: (params) =>
    axios
      .get('/communications/commswhatsappagents', { params })
      .then(responseBody),

  getSingleEmailTemplate: () =>
    requests.get('/communications/dropdownemailtemplates'),

  getFromEmails: () => requests.get('/communications/fromemails'),

  getCommunicationActivities: (appuserid) =>
    requests.get(`/communications/bulkemailcommstudents/${appuserid}`),
  getStudentCommunicationHistory: (id) =>
    requests.get(`/communications/commactivtystudents/${id}`),

  list: (params) =>
    axios.get('/communications/commstudents', { params }).then(responseBody), //For Student Application communication-Bulk email list

  getStudentComList: (params) =>
    axios
      .get('communications/communicationstudents', { params })
      .then(responseBody), // for student communications -BulkEmail

  getEmailTemplates: () => requests.get('/communications/emailtemplates'),

  disableTemplate: (id) => {
    return axios.delete(`/communications/inactive/${id}`);
  },
  enableTemplate: (id) => {
    return axios.put(`/communications/active/${id}`);
  },
  getContactComTemp: () =>
    requests.get('/communications/contactmanagementemailtemplates'),
  getAgentEmailTemplates: () =>
    requests.get('/communications/agentemailtemplates'),
  getReferralEmailTemplates: () =>
    requests.get('/communications/referralemailtemplates'),
  bulkEmailStudents: (payload) =>
    requests.post('/communications/bulkemailsendtostudent', payload),

  getStudentsLeadCommunsActivities: (appuserid) =>
    requests.get(`/communications/bulkemailcommstudentlead/${appuserid}`),
  listStudentsLead: (params) =>
    axios
      .get('/communications/commstudentleads', { params })
      .then(responseBody),

  bulkEmailStudentsLead: (payload) =>
    requests.post('/communications/bulkemailsendtostudentlead', payload),
  bulkEmailStudentsLeadDataset: (payload) =>
    requests.post('/communications/bulkemailsendtodatasetstudents', payload),
  bulkEmailSendAgent: (payload) =>
    requests.post('/communications/bulkemailsendtoagent', payload),
  bulkEmailSendReferral: (payload) =>
    requests.post('/communications/bulkemailsendtoreferral', payload),
};

const StudentLeads = {
  getLeadEmailAndPhone: (id) =>
    requests.get(`/studentleads/leadcontactsbyid/${id}`),

  updateLeadEmailAndInfo: (payloadLeadsEmails) =>
    requests.post('/studentleads/updatecontracts', payloadLeadsEmails),

  getPreferences: (id) => requests.get(`/studentleads/${id}/preferences`),

  updatePreferences: (studentLead) =>
    requests.post('/studentleads/updatepreferences', studentLead),
  convertLead: (studentLeadId) =>
    requests.post(`/studentleads/leadtransfertostudent/${studentLeadId}`),
  saveStudentUpdateLead: (saveStudentLeadsDto) =>
    requests.post('/studentleads/save', saveStudentLeadsDto), //api for update and save lead profile
  getLeadProfile: (id) => requests.get(`/studentleads/${id}`),
  getPublicLeadProfile: (id) =>
    requests.get(`/studentleads/publicstudentlead/${id}`),
  saveQuickStudentLead: (studentleaddto) =>
    requests.post('/studentLeads/quicksave', studentleaddto),
  saveQuickStudentInternal: (studentleaddto) =>
    requests.post('/students/studentquicksave', studentleaddto),
  saveLeadPublicNote: (publicNote) =>
    requests.post('/messages/addleadpublicnote', publicNote),
  savePulicLeadFormData: (studentleaddto) =>
    requests.post('/studentleads/publicquickadd', studentleaddto),

  // getStudentLeads: (params) =>
  //   requests.get('studentleads/studentleadsstudentleads', params),
  getStudentLeads: (params) =>
    axios.get('/studentleads/studentleads', { params }).then(responseBody),
  assign: (assignDto) => requests.put('/studentleads/assign', assignDto),
  disabledLead: (id) => {
    return axios.delete(`/studentleads/disable/${id}`);
  },
  getStudentByLeadId: (id) =>
    requests.get(`/studentleads/getstudentbyleadid/${id}`),
};

const Chat = {
  getPublicChats: (status, country) =>
    requests.get(
      `${OTHER_API_URL}/comms/chats/getpublicchats/${status}/${country}`
    ),
  archiveMessage: (message) => {
    return axios.post(
      `${OTHER_API_URL}/comms/chats/message/updatestatus`,
      message
    );
  },
  getAllChats: (type, phone) =>
    requests.get(`${OTHER_API_URL}/comms/chats/allchats/${type}/${phone}`),
  getNotifications: (appUserId) =>
    requests.get(`${OTHER_API_URL}/comms/gen/notifications/${appUserId}`),
  deleteNotification: (id) =>
    requests.del(`${OTHER_API_URL}/comms/gen/notifications/${id}`),
  sendChat: (request) => {
    return axios.post(`${OTHER_API_URL}/comms/chats/send`, request);
  },
  sendWelcome: (request) => {
    return axios.post(`${OTHER_API_URL}/comms/chats/optin`, request);
  },
  downloadfile: (mediaId, filename) => {
    return axios.get(
      `${OTHER_API_URL}/comms/subscriptions/download/${mediaId}/${filename}`,
      {
        responseType: 'arraybuffer',
      }
    );
  },
};

const Events = {
  updateAttendance: (id, isaddendence, eventid) =>
    requests.post(`/events/addendence/${id}/${isaddendence}/${eventid}`),
  saveEvent: (events) => requests.post('/events/save', events),
  getEvents: (params) =>
    axios.get('/events/events', { params }).then(responseBody),
  getEventById: (id) => requests.get(`/events/${id}`),
  assign: (assignDto) => requests.put('/events/assign', assignDto),
  list: (params) => axios.get('/events/events', { params }).then(responseBody),
  getEventList: () => requests.get('/events/getEvents'),
  eventAttendancelist: (params) =>
    axios.get('/events/addendences', { params }).then(responseBody),
};

const UserActivity = {
  logOutActivity: (AppUserDetailsId, browserUserInfo) =>
    requests.post('/account/logout', {
      AppUserDetailsId,
      browserUserInfo,
    }),

  getSingleLeadActivity: (id) =>
    requests.get(`/studentleads/useractivitiesbyid/${id}`), //single activity for leads

  getSingleStudentActivity: (id) =>
    requests.get(`/students/useractivitiesbyid/${id}`), //single activity for Students

  getActivitylist: (params) =>
    axios.get('/users/useractivities/', { params }).then(responseBody),
};

const BulkUpload = {
  coursesBulkUpload: (file) => {
    let formData = new FormData();
    if (file) {
      formData.append('csvFile', file);
    }
    return axios
      .post('/courses/uploadcoursescsvfile', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },

  contactBulkUpload: (file) => {
    let formData = new FormData();
    if (file) {
      formData.append('csvFile', file);
    }
    return axios
      .post('/contactmanagemant/contactuploadcsv', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },

  datasetBulkUpload: (file) => {
    let formData = new FormData();
    if (file) {
      formData.append('csvFile', file);
    }
    return axios
      .post('/datasetgroups/datasetuploadcsv', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },

  // leadUpload: (file) => {
  //   let formData = new FormData();
  //   if (file) {
  //     formData.append('csvFile', file);
  //   }
  //   return axios
  //     .post('/studentleads/uploadcsv', formData, {
  //       headers: { 'Content-type': 'multipart/form-data' },
  //     })
  //     .then(responseBody);
  // },
  leadUpload: (file) => {
    let formData = new FormData();
    if (file) {
      formData.append('csvFile', file);
    }
    return axios
      .post('/students/uploadcsv', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
};

const GlobalContacts = {
  sendSingleContactMail: (msg) =>
    requests.post('/messages/addcontactnote', msg),

  updateContactProfile: (contactInfo) =>
    requests.post('/contactmanagemant/save', contactInfo),
  getContactProfile: (id) => requests.get(`/contactmanagemant/${id}`),
  addContact: (contactInfo) =>
    requests.post('/contactmanagemant/quicksave', contactInfo),
  getContactNotesById: (id) => requests.get(`/contactmanagemant/${id}`),
  assign: (assignDto) => requests.put('/contactmanagemant/assign', assignDto),
  getContactList: (params) =>
    axios.get('/contactmanagemant/contacts/', { params }).then(responseBody),
};

const ManageTemplats = {
  disableTemplate: (id) => {
    return axios.delete(`/communications/inactive/${id}`);
  },
  getUpdateTempProfile: (id) => requests.get(`/communications/template/${id}`),

  createTemplate: (templateObj) =>
    requests.post('/communications/templatesave', templateObj),

  getTemplateList: (params) =>
    axios.get('/communications/templateslist', { params }).then(responseBody),
};

const Announcement = {
  postAnnouncement: (payload) => requests.post('/announcements/save', payload),
  getAnnouncementList: (params) =>
    axios.get('/announcements/announcements', { params }).then(responseBody),
  getAnnouncementType: (params) =>
    axios
      .get('/announcements/announcementtypes', { params })
      .then(responseBody),
  inActiveAnnouncement: (id) => {
    return axios.delete(`/announcements/inactive/${id}`);
  },
  assign: (assignDto) => requests.put('/announcements/assign', assignDto),
  deleteUserType: (announcementid, usertypeid) => {
    return axios.delete(
      `/announcements/deleteassignuser/${announcementid}/${usertypeid}`
    );
  },
  saveAnnouncementtype: (payload) =>
    requests.post('announcements/savetype', payload),
};

const Agent = {
  Announcement,
  GlobalContacts,
  ManageTemplats,
  StudentLeads,
  Applications,
  Users,
  Programs,
  Students,
  Agents,
  Account,
  Messages,
  Summary,
  UserAssignment,
  CloudFiles,
  Universities,
  Maintenance,
  Common,
  Cosmos,
  Courses,
  Management,
  Requirements,
  Appointment,
  Cache,
  UserTask,
  Employees,
  General,
  Report,
  Communication,
  AutoReportConfigs,
  Chat,
  OTHER_API_URL,
  Events,
  Referral,
  UserActivity,
  BulkUpload,
};

export default Agent;
