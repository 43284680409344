import React, { useState } from 'react';
import { ButtonGroup } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import Agent from '../../../api/agent';

const Documents = ({
  files,
  handleFileDelete,
  agentId,
  studentLeadId,
  applicationId = 0,
  disabled = false,
  softDelete = false,
  documentTypes = {},
}) => {
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fileToDownload, setFileToDownload] = useState();

  const deleteFile = (fileName) => {
    if (softDelete) {
      handleFileDelete(fileName);
      return;
    }
    setDeleting(true);
    Agent.CloudFiles.delete(fileName, agentId, studentLeadId, applicationId)
      .then(() => {
        handleFileDelete(fileName);
        setDeleting(false);
      })
      .catch((error) => {
        console.log(error);
        setDeleting(false);
      });
  };

  const downloadFile = async (fileNameLocal, fileName, containerName) => {
    setDownloading(true);
    await Agent.CloudFiles.download(fileNameLocal, fileName, containerName)
      .then((response) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/octet-stream' })
        );
        link.download = fileNameLocal ? fileNameLocal : fileName;
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch((error) => {
        setDownloading(false);
        console.log(error);
      });
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <table className="w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">File Name</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Document Type</th>
            <th className="px-4 py-2 text-right text-sm font-semibold text-gray-600">Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="px-4 py-3 text-sm text-gray-800">{file.fileNameLocal}</td>
              <td className="px-4 py-3 text-sm text-gray-800">
                {documentTypes[file.id] || file.documentTypeDesc || 'Unknown'}
              </td>
              <td className="px-4 py-3 text-right">
                <ButtonGroup size="small" color="primary">
                  <LoadingButton
                    disabled={file.state === 2}
                    loading={downloading && fileToDownload === file.fileName}
                    onClick={() => {
                      setFileToDownload(file.fileName);
                      downloadFile(file.fileNameLocal, file.fileName, file.containerName);
                    }}
                    startIcon={<CloudDownloadIcon />}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 text-xs rounded-l"
                  >
                    Download
                  </LoadingButton>
                  <LoadingButton
                    disabled={disabled}
                    color="secondary"
                    loading={deleting}
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => deleteFile(file.fileName, file.id)}
                    className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 text-xs rounded-r"
                  >
                    Delete
                  </LoadingButton>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Documents;

