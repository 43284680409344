import Agent from '../api/agent';
import { Countries } from './Util';

export const SUPERADMIN = 'SuperAdmin';
export const SENIORADMIN = 'SeniorAdmin';
export const ADMIN = 'Admin';
export const AGENTSTAFF = 'AgentStaff';
export const AGENT = 'Agent';
export const STUDENT = 'Student';
export const APPLICATIONTEAM = 'ApplicationTeam';
export const APPLICATIONTEAMLEADER = 'ApplicationTeamLeader';
export const REFERRAL = 'Referral';

export const GEO_LOCATION_URL = 'https://geolocation-db.com/json/';

// agent status
export const STATUS_INCOMPLETE = 1;
export const STATUS_PENDING = 2;
export const STATUS_APPROVED = 5;

export const USER_PERMISSIONS_OBJECT_INITIAL = {
  CanAccessCounselorPerformanceReport: false,
  CanAccessGlobalSalesReportB2C: false,
  CanAccessRegionalReport: false,
  CanAccessGlobalLeadSourcePerformanceReportB2C: false,
  CanAccessRegionalSourcePerformanceReportB2C: false,
  CanManageAllAgents: false,
  CanManageAllReferrals: false,
  CanAddDuplicate: false,
  CanDeleteAgent: false,
  CanDeleteReferral: false,
  CanDisableStudent: false,
  CanDisableApplication: false,
  CanManageUniversity: false,
  CanManageOwnEmployees: false,
  CanManageAllEmployees: false,
  CanDownloadData: false,
  CanUseComms: false,
  CanManageAutomatedReports: false,
  CanManageLeads: false,
  CanManageEvents: false,
  CanManageAllLeads: false,
  CanManageWhatsAppUK: false,
  CanManageWhatsAppBD: false,
  CanManageWhatsAppIndia: false,
  CanManageWhatsAppPak: false,
  CanManageWhatsAppSA: false,
  CanManageWhatsAppMor: false,
  CanAccessReferral: false,
  CanAccessPartnershipSection: false,
  CanAccessActivityLog: false,
  CanManageContact: false,
  CanManageAgentAccountsNote: false,
  CanManageStudentAccountsNote: false,
  CanManageStudentChatSection: false,
  CanAddContact: false,
  CanAddEvent: false,
  CanDeleteStudentsDocument: false,
  CanDeleteLeadDocument: false,
  CanDeleteAgentAgreement: false,
  CanAccessTasksSection: false,
  CanAccessReportSection: false,
  CanAccessReportPanel: false,
  CanAccessUniversitySection: false,
  CanAccessNotificationsSection: false,
  CanAccessMessageSection: false,
  CanAccessAppointmentSection: false,
  CanAccessProgramsSection: false,
  CanAccessAdminPanel: false,
  CanAccessLeadContact: false,
  CanAccessStudentContact: false,
  CanAccessAppMethod: false,
  CanAccessAgentContracts: false,
  CanAccessAgentChat: false,
  CanAccessLeadChat: false,
  CanDisableUniversities: false,
  CanAccessRegionalReportUnitedArabEmirates: false,
  CanAccessRegionalReportBangladesh: false,
  CanAccessRegionalReportCotedIvoire: false,
  CanAccessRegionalReportCameroon: false,
  CanAccessRegionalReportAlgeria: false,
  CanAccessRegionalReportEgypt: false,
  CanAccessRegionalReportUK: false,
  CanAccessRegionalReportGhana: false,
  CanAccessRegionalReportIndia: false,
  CanAccessRegionalReportKenya: false,
  CanAccessRegionalReportKuwait: false,
  CanAccessRegionalReportSriLanka: false,
  CanAccessRegionalReportMorocco: false,
  CanAccessRegionalReportMongolia: false,
  CanAccessRegionalReportNigeria: false,
  CanAccessRegionalReportNepal: false,
  CanAccessRegionalReportPakistan: false,
  CanAccessRegionalReportSaudiArabia: false,
  CanAccessRegionalReportTurkey: false,
  CanAccessRegionalReportUSA: false,
  CanAccessRegionalSourceReportUnitedArabEmirates: false,
  CanAccessRegionalSourceReportBangladesh: false,
  CanAccessRegionalSourceReportCotedIvoire: false,
  CanAccessRegionalSourceReportCameroon: false,
  CanAccessRegionalSourceReportAlgeria: false,
  CanAccessRegionalSourceReportEgypt: false,
  CanAccessRegionalSourceReportUK: false,
  CanAccessRegionalSourceReportGhana: false,
  CanAccessRegionalSourceReportIndia: false,
  CanAccessRegionalSourceReportKenya: false,
  CanAccessRegionalSourceReportKuwait: false,
  CanAccessRegionalSourceReportSriLanka: false,
  CanAccessRegionalSourceReportMorocco: false,
  CanAccessRegionalSourceReportMongolia: false,
  CanAccessRegionalSourceReportNigeria: false,
  CanAccessRegionalSourceReportNepal: false,
  CanAccessRegionalSourceReportPakistan: false,
  CanAccessRegionalSourceReportSaudiArabia: false,
  CanAccessRegionalSourceReportTurkey: false,
  CanAccessRegionalSourceReportUSA: false,
};

export const MUI_LICENSE_KEY =
  '4c45d2f0f56d138f91b532fded003b9cTz02ODEyNixFPTE3MTc3NTA5MDc3NzksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const VERIFICATION_COOKIE = 'ver_val';

export const User_Activity_Type = [
  {
    id: 1,
    type: 'AppUserDetails',
  },
  {
    id: 2,
    type: 'Agent',
  },
  {
    id: 3,
    type: 'Student',
  },
  {
    id: 4,
    type: 'Application',
  },
  {
    id: 5,
    type: 'University',
  },
  {
    id: 6,
    type: 'Course',
  },
  {
    id: 7,
    type: 'Login',
  },
  {
    id: 8,
    type: 'Referral',
  },
  {
    id: 9,
    type: 'Logout',
  },
  {
    id: 10,
    type: 'Student Lead',
  },
  {
    id: 11,
    type: 'Event',
  },
  {
    id: 12,
    type: 'Contact Management',
  },
  {
    id: 13,
    type: 'Template',
  },
  {
    id: 14,
    type: 'From Email',
  },
];

export const COMMUNICATION_CATEGORIES = [
  {
    id: 1,
    type: 'Email',
  },
  {
    id: 2,
    type: 'WhatsApp',
  },
  {
    id: 3,
    type: 'SMS',
  },
];

export const DATASET_CATEGORIES = [
  {
    id: 0,
    name: '--Select--',
  },
  {
    id: 1,
    name: 'Student Dataset',
  },
  {
    id: 2,
    name: 'Application Dataset',
  },
];
export const Selection_CATEGORIES_COMS = [
  {
    id: 0,
    name: 'Choose Data Type',
  },
  {
    id: 1,
    name: 'Students',
  },
  {
    id: 2,
    name: 'Applications',
  },
];
export const TASKS_PRIORITIES = [
  {
    id: 1,
    type: 'Low',
  },
  {
    id: 2,
    type: 'Medium',
  },
  {
    id: 3,
    type: 'High',
  },
];
export const EVENT_STATUSES = [
  {
    id: 1,
    type: 'Active',
  },
  {
    id: 2,
    type: 'Inactive',
  },
];
export const EVENT_CATEGORIES = [
  {
    id: 1,
    type: 'Virtual Event',
  },
  {
    id: 2,
    type: 'On-site Event',
  },
  {
    id: 3,
    type: 'Hybrid Event',
  },
  {
    id: 4,
    type: 'Webinar',
  },
  {
    id: 5,
    type: 'Conference',
  },
  {
    id: 6,
    type: 'Seminar',
  },
  {
    id: 7,
    type: 'Spot Assessment',
  },
  {
    id: 8,
    type: 'Fair / Expo',
  },
];
export const TASKS_STATUSES = [
  {
    id: 1,
    type: 'Pending',
  },
  {
    id: 2,
    type: 'Due',
  },
  {
    id: 3,
    type: 'Complete',
  },
];
export const TASKS_GROUPS = [
  {
    id: 1,
    type: 'Assessment',
  },
  {
    id: 2,
    type: 'FollowUp',
  },
  {
    id: 3,
    type: 'Application',
  },
  {
    id: 4,
    type: 'Conversion',
  },
  {
    id: 5,
    type: 'Compliance',
  },
  {
    id: 6,
    type: 'Digital Transformation',
  },
];
export const TASKS_TYPES = [
  {
    id: 1,
    type: 'Assessment Consultation',
  },
  {
    id: 2,
    type: 'Agent Conflict',
  },
  {
    id: 3,
    type: 'File Duplication',
  },
  {
    id: 4,
    type: 'Phone Call',
  },
  {
    id: 5,
    type: 'Email',
  },
  {
    id: 6,
    type: 'Follow-up Call',
  },
  {
    id: 7,
    type: 'Follow-up Email',
  },
  {
    id: 8,
    type: 'Documentation',
  },
  {
    id: 9,
    type: 'Authorization',
  },
  {
    id: 10,
    type: 'Meeting',
  },
  {
    id: 11,
    type: 'Training',
  },
  {
    id: 12,
    type: 'Review',
  },
  {
    id: 13,
    type: 'Reporting',
  },
  {
    id: 14,
    type: 'Application',
  },
  {
    id: 15,
    type: 'Notification',
  },
  {
    id: 16,
    type: 'Offer Letter',
  },
  {
    id: 17,
    type: 'CAS Statement',
  },
  {
    id: 18,
    type: 'Development',
  },
  {
    id: 19,
    type: 'Bug',
  },
  {
    id: 20,
    type: 'Digital Project',
  },
  {
    id: 25,
    type: 'Other',
  },
];

export const USER_PERMISSIONS = [
  {
    type: 'CanManageUniversity',
    value: 'Manage Universities and Courses',
    status: false,
  },
  {
    type: 'CanManageAllAgents',
    value: 'Manage All Students', // manage students means he can manage agent student too
    status: false,
  },
  {
    type: 'CanManageAllReferrals',
    value: 'Manage All Referrals',
    status: false,
  },

  {
    type: 'CanAddDuplicate',
    value: 'Can Add Duplicate Students',
    status: false,
  },
  {
    type: 'CanManageAllLeads',
    value: 'Manage All Direct Leads',
    status: false,
  },
  {
    type: 'CanDeleteAgent',
    value: 'Remove Agents',
    status: false,
  },
  {
    type: 'CanDeleteReferral',
    value: 'Remove Referral',
    status: false,
  },
  {
    type: 'CanDisableStudent',
    value: 'Disable Students',
    status: false,
  },
  {
    type: 'CanDisableApplication',
    value: 'Disable Applications',
    status: false,
  },
  {
    type: 'CanDownloadData',
    value: 'Download Data',
    status: false,
  },
  {
    type: 'CanManageOwnEmployees',
    value: 'Manage Their Own Employees',
    status: false,
  },
  {
    type: 'CanManageAllEmployees',
    value: 'Manage ALL Employees',
    status: false,
  },
  {
    type: 'CanUseComms',
    value: 'Manage Communication',
    status: false,
  },
  {
    type: 'CanManageAutomatedReports',
    value: 'Manage Automated Reports',
    status: false,
  },
  {
    type: 'CanManageLeads',
    value: 'Manage Leads',
    status: false,
  },
  {
    type: 'CanManageEvents',
    value: 'Manage Events',
    status: false,
  },
  {
    type: 'CanAddEvent',
    value: 'Add Event',
    status: false,
  },
  {
    type: 'CanManageWhatsAppUK',
    value: 'WhatsApp - Manage Rest of the world',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanManageWhatsAppBD',
    value: 'WhatsApp - Manage Bangladesh',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanManageWhatsAppIndia',
    value: 'WhatsApp - Manage India',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanManageWhatsAppPak',
    value: 'WhatsApp - Manage Pakistan',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanManageWhatsAppSA',
    value: 'WhatsApp - Manage Saudi Arabia',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanManageWhatsAppMor',
    value: 'WhatsApp - Manage Morocco',
    status: false,
    whatsApp: true,
  },
  {
    type: 'CanAccessReferral',
    value: 'Access to Referral',
    status: false,
  },
  {
    type: 'CanAccessActivityLog',
    value: 'Access to Activity Log',
    status: false,
  },
  {
    type: 'CanManageContact',
    value: 'Access to Contacts',
    status: false,
  },
  {
    type: 'CanManageStudentAccountsNote',
    value: 'Access Student Accounts Note',
    status: false,
  },
  {
    type: 'CanManageAgentAccountsNote',
    value: 'Access Agent Accounts Note',
    status: false,
  },
  {
    type: 'CanAccessAgentContracts',
    value: 'Access to Agent Contracts',
    status: false,
  },
  {
    type: 'CanAccessPartnershipSection',
    value: 'Access to Partnership',
    status: false,
  },
  {
    type: 'CanAccessTasksSection',
    value: 'Access to Tasks',
    status: false,
  },

  {
    type: 'CanAccessNotificationsSection',
    value: 'Access to Notification Section',
    status: false,
  },
  {
    type: 'CanAccessMessageSection',
    value: 'Access to Message Section',
    status: false,
  },
  {
    type: 'CanAccessAppointmentSection',
    value: 'Access to Appointment Section',
    status: false,
  },
  {
    type: 'CanAccessProgramsSection',
    value: 'Access to Programs Section',
    status: false,
  },
  {
    type: 'CanAccessAdminPanel',
    value: 'Access to Admin Panel',
    status: false,
  },

  {
    type: 'CanAccessAppMethod',
    value: 'Access to Application Method',
    status: false,
  },
  {
    type: 'CanAccessLeadContact',
    value: 'Access to Lead Contact Info',
    status: false,
  },
  {
    type: 'CanAccessAgentChat',
    value: 'Access to Agent Chat',
    status: false,
  },
  {
    type: 'CanAccessLeadChat',
    value: 'Access to Lead Chat',
    status: false,
  },
  {
    type: 'CanManageStudentChatSection',
    value: 'Access to Student Chat',
    status: false,
  },
  {
    type: 'CanAccessStudentContact',
    value: 'Access to Student Contact Info',
    status: false,
  },
  {
    type: 'CanAddContact',
    value: 'Access to Add Contact',
    status: false,
  },
  {
    type: 'CanDeleteAgentAgreement',
    value: 'Access to Delete Agent Agreement',
    status: false,
  },
  {
    type: 'CanDeleteStudentsDocument',
    value: 'Access to Delete Students Document',
    status: false,
  },
  {
    type: 'CanDeleteLeadDocument',
    value: 'Access to Delete Lead Document',
    status: false,
  },
  {
    type: 'CanAccessUniversitySection',
    value: 'Access to University Section',
    status: false,
  },
  {
    type: 'CanDisableUniversities',
    value: 'Access to Active/Inactive University ',
    status: false,
  },

  {
    type: 'CanAccessReportSection',
    value: 'Access to Report Section (Old)',
    status: false,
  },

  {
    type: 'CanAccessReportPanel',
    value: 'Access to Report Panel (New)',
    status: false,
  },

  {
    type: 'CanAccessGlobalSalesReportB2C',
    value: 'Access to Global Sales Progress Report',
    status: false,
  },

  {
    type: 'CanAccessRegionalReport',
    value: 'Access to Regional Sales Progress(B2C)',
    status: false,
  },
  {
    type: 'CanAccessCounselorPerformanceReport',
    value: 'Access to Counselor Performance Report',
    status: false,
  },

  {
    type: 'CanAccessRegionalReportUnitedArabEmirates',
    value: 'Manage Regional Report United Arab Emirates',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportBangladesh',
    value: 'Manage Regional  Report Bangladesh',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportCotedIvoire',
    value: "Manage Regional Report Cote d'Ivoire",
    status: false,
  },
  {
    type: 'CanAccessRegionalReportCameroon',
    value: 'Manage Regional Report Cameroon',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportAlgeria',
    value: 'Manage Regional Report Algeria',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportEgypt',
    value: 'Manage Regional  Report Egypt',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportUK',
    value: 'Manage Regional Report UK',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportGhana',
    value: 'Manage Regional Report Ghana',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportIndia',
    value: 'Manage Regional Report India',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportKenya',
    value: 'Manage Regional  Report Kenya',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportKuwait',
    value: 'Manage Regional Report Kuwait',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportSriLanka',
    value: 'Manage Regional Report Sri Lanka',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportMorocco',
    value: 'Manage Regional Report Morocco',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportMongolia',
    value: 'Manage Regional Report Mongolia',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportNigeria',
    value: 'Manage Regional Report Nigeria',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportNepal',
    value: 'Manage Regional Report Nepal',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportPakistan',
    value: 'Manage Regional Report Pakistan',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportSaudiArabia',
    value: 'Manage Regional Report Saudi Arabia',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportTurkey',
    value: 'Manage Regional Source Report Turkey',
    status: false,
  },
  {
    type: 'CanAccessRegionalReportUSA',
    value: 'Manage Regional  Report USA',
    status: false,
  },

  {
    type: 'CanAccessGlobalLeadSourcePerformanceReportB2C',
    value: 'Access to Global Lead Source Performance (B2C)',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourcePerformanceReportB2C',
    value: 'Access to Regional Lead Source Performance (B2C)',
    status: false,
  },

  {
    type: 'CanAccessRegionalSourceReportUnitedArabEmirates',
    value: 'Manage Regional Source Report United Arab Emirates',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportBangladesh',
    value: 'Manage Regional Source Report Bangladesh',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportCotedIvoire',
    value: "Manage Regional Source Report Cote d'Ivoire",
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportCameroon',
    value: 'Manage Regional Source Report Cameroon',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportAlgeria',
    value: 'Manage Regional Source Report Algeria',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportEgypt',
    value: 'Manage Regional Source Report Egypt',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportUK',
    value: 'Manage Regional Source Report UK',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportGhana',
    value: 'Manage Regional Source Report Ghana',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportIndia',
    value: 'Manage Regional Source Report India',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportKenya',
    value: 'Manage Regional Source Report Kenya',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportKuwait',
    value: 'Manage Regional Source Report Kuwait',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportSriLanka',
    value: 'Manage Regional Source Report Sri Lanka',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportMorocco',
    value: 'Manage Regional Source Report Morocco',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportMongolia',
    value: 'Manage Regional Source Report Mongolia',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportNigeria',
    value: 'Manage Regional Source Report Nigeria',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportNepal',
    value: 'Manage Regional Source Report Nepal',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportPakistan',
    value: 'Manage Regional Source Report Pakistan',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportSaudiArabia',
    value: 'Manage Regional Source Report Saudi Arabia',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportTurkey',
    value: 'Manage Regional Source Report Turkey',
    status: false,
  },
  {
    type: 'CanAccessRegionalSourceReportUSA',
    value: 'Manage Regional Source Report USA',
    status: false,
  },
];

export const APPOINTMENTSTATUS = [
  {
    id: 1,
    type: 'Requested',
  },
  {
    id: 2,
    type: 'Confirmed',
  },
  {
    id: 3,
    type: 'Completed',
  },
  {
    id: 4,
    type: 'Cancelled',
  },
];
export const AGENTSTATUS = [
  {
    id: 1,
    type: 'Incomplete',
  },
  {
    id: 2,
    type: 'Pending',
  },
  {
    id: 3,
    type: 'Rejected',
  },
  {
    id: 4,
    type: 'Blocked', //previous status name More documents needed
  },
  {
    id: 5,
    type: 'Approved',
  },
  {
    id: 6,
    type: 'Disabled',
  },
  {
    id: 7,
    type: 'Unverified',
  },
  {
    id: 8,
    type: 'Agreement Expired',
  },
];
export const AGENTSOURCES = [
  {
    id: 0,
    type: 'Unknown',
  },
  {
    id: 1,
    type: 'ICEF Dubai',
  },
  {
    id: 2,
    type: 'ICEF Mumbai',
  },
  {
    id: 3,
    type: 'Website',
  },
  {
    id: 4,
    type: 'CRM',
  },
];
export const REFERRALSOURCE = [
  {
    id: 0,
    type: 'Unknown',
  },
  {
    id: 1,
    type: 'PaidReferral',
  },
  {
    id: 2,
    type: 'UnpaidReferral',
  },
  {
    id: 3,
    type: 'CRM',
  },
];
export const STUDENTSOURCES = [
  // {
  //   id: 0,
  //   type: 'Unknown',
  // },

  // {
  //   id: 2,
  //   type: 'Portal',
  // },
  // {
  //   id: 3,
  //   type: 'CRM',
  // },
  // {
  //   id: 4,
  //   type: 'Marketing',
  // },
  // {
  //   id: 5,
  //   type: 'Voice Contact Centre',
  // },
  // {
  //   id: 6,
  //   type: 'Facebook',
  // },
  // {
  //   id: 7,
  //   type: 'WhatsApp',
  // },
  // {
  //   id: 8,
  //   type: 'Management',
  // },

  {
    id: 1,
    type: 'Website',
  },

  {
    id: 9,
    type: 'Student Referral',
  },
  {
    id: 10,
    type: 'WalkIn',
  },
  // {
  //   id: 11,
  //   type: 'In2Synergy',
  // },
  {
    id: 12,
    type: 'Paid Referral',
  },
  // {
  //   id: 13,
  //   type: 'StudentJourney',
  // },
  // {
  //   id: 14,
  //   type: 'Self Registration',
  // },
  {
    id: 15,
    type: 'Mobile App',
  },
  {
    id: 16,
    type: 'WhatsApp Hotline',
  },
  {
    id: 17,
    type: 'WhatsApp Marketing',
  },
  {
    id: 42,
    type: 'Whatsapp Corporate',
  },

  {
    id: 18,
    type: 'Instagram Message',
  },
  {
    id: 19,
    type: 'Facebook Message',
  },
  {
    id: 20,
    type: 'LinkedIn Message',
  },
  {
    id: 21,
    type: 'YouTube',
  },
  {
    id: 22,
    type: 'Affiliated IN2Synergy UoP(BD)',
  },
  {
    id: 23,
    type: 'Affiliated IN2Synergy Trine(BD)',
  },
  {
    id: 50,
    type: 'Affiliated IN2Synergy Trine(Pakistan)',
  },
  {
    id: 51,
    type: 'Affiliated IN2Synergy NTU(BD)',
  },
  {
    id: 52,
    type: 'Affiliated IN2Synergy UoP(Pakistan)',
  },
  {
    id: 53,
    type: 'Affiliated IN2Synergy Ulster(Pakistan)',
  },
  {
    id: 54,
    type: 'Event Ads',
  },
  {
    id: 24,
    type: 'YouTube Ads',
  },
  {
    id: 25,
    type: 'TikTok Message',
  },
  {
    id: 26,
    type: 'Blogging and Articles',
  },
  {
    id: 27,
    type: 'Webinar and Virtual Events',
  },
  {
    id: 28,
    type: 'Education Fairs',
  },
  {
    id: 29,
    type: 'Virtual Education Fairs',
  },
  {
    id: 30,
    type: 'Employee Referral',
  },
  {
    id: 31,
    type: 'Management Referral',
  },
  {
    id: 44,
    type: 'Management Referral(CEO)',
  },
  // {
  //   id: 32,
  //   type: 'Student Referral',
  // },
  {
    id: 33,
    type: 'Email Incoming',
  },
  {
    id: 34,
    type: 'Email Marketing',
  },
  {
    id: 35,
    type: 'Other Marketing',
  },
  {
    id: 36,
    type: 'School Seminars',
  },
  {
    id: 37,
    type: 'Campus Visits',
  },
  {
    id: 38,
    type: 'Instagram Ads',
  },
  {
    id: 39,
    type: 'Facebook Ads',
  },
  {
    id: 40,
    type: 'LinkedIn Ads',
  },
  {
    id: 41,
    type: 'Partner Agent',
  },
  // {
  //   id: 42,
  //   type: 'Whatsapp Corporate',
  // },
  {
    id: 43,
    type: 'Spot Assessment',
  },
  {
    id: 45,
    type: 'University Website',
  },
  {
    id: 46,
    type: 'Google Ads',
  },
  {
    id: 47,
    type: 'Mobile App Ads',
  },
  {
    id: 48,
    type: 'Inbound Call Inquiry',
  },
  {
    id: 49,
    type: 'Student SignUp Link',
  },
];

export const LEADORIGINS = [
  {
    id: 0,
    type: 'Unknown',
  },
  {
    id: 1,
    type: 'Digital Leads',
  },
  {
    id: 2,
    type: 'Inbound Contact Leads',
  },
  {
    id: 3,
    type: 'Referral Leads',
  },
  {
    id: 4,
    type: 'Office Visit',
  },
  {
    id: 5,
    type: 'Affiliated Company',
  },
  {
    id: 6,
    type: 'Partner Agent',
  },
];

export const LEADCHANNELS = [
  {
    id: 0,
    type: 'Unknown',
  },
  {
    id: 1,
    type: 'Outbound Email Marketing',
  },
  {
    id: 2,
    type: 'Facebook',
  },
  {
    id: 3,
    type: 'WhatsApp Hotline',
  },
  {
    id: 4,
    type: 'Spot Assessment',
  },
  {
    id: 5,
    type: 'BD Contact Center',
  },
  {
    id: 6,
    type: 'IND Contact Center',
  },
  {
    id: 7,
    type: 'Facebook Page Name',
  },
  {
    id: 8,
    type: 'Country Specific',
  },
  {
    id: 9,
    type: 'Managing Director',
  },
  {
    id: 10,
    type: 'Top Management',
  },
  {
    id: 11,
    type: 'Employee Referral',
  },
  {
    id: 12,
    type: 'New Student',
  },
  {
    id: 13,
    type: 'Old Student',
  },
  {
    id: 14,
    type: 'Branch Name Selection',
  },
  {
    id: 15,
    type: 'CountryBranch',
  },
  {
    id: 16,
    type: 'Outbound WhatsApp',
  },
  {
    id: 17,
    type: 'Fair',
  },
  {
    id: 18,
    type: 'Regular campaign',
  },
  {
    id: 19,
    type: 'Traditional campaign',
  },
  {
    id: 20,
    type: 'Event',
  },
  {
    id: 21,
    type: 'TCL Global Bangladesh',
  },
  {
    id: 22,
    type: 'TCL Global Pakistan',
  },
  {
    id: 23,
    type: 'TCL Global UAE',
  },
  {
    id: 24,
    type: 'TCL Global',
  },
  {
    id: 25,
    type: 'IN2Synergy',
  },
  {
    id: 26,
    type: 'Agent Referral',
  },
  {
    id: 27,
    type: 'TCL Global Dhanmondi',
  },
  {
    id: 28,
    type: 'TCL Global Sylhet',
  },
  {
    id: 29,
    type: 'TCL Global Uttara',
  },
  {
    id: 30,
    type: 'TCL Global Chattogram',
  },
  {
    id: 31,
    type: 'TCL Global India',
  },
  {
    id: 32,
    type: 'TCL Global Islamabad',
  },
  {
    id: 33,
    type: 'TCL Global Lahore',
  },
  {
    id: 34,
    type: 'TCL Global Saudi Arabia',
  },
  {
    id: 35,
    type: 'TCL Global UK',
  },
  {
    id: 36,
    type: 'TCL Global Egypt',
  },
  {
    id: 37,
    type: 'TCL Global Nigeria',
  },
  {
    id: 38,
    type: 'TCL Global Morocco',
  },
  {
    id: 39,
    type: 'Bangladesh',
  },
  {
    id: 40,
    type: 'Trine University',
  },
  {
    id: 41,
    type: 'University of Portsmouth',
  },
  {
    id: 42,
    type: 'Providence College ',
  },
  {
    id: 43,
    type: 'Nottingham Trent University',
  },
  {
    id: 44,
    type: 'Public Form',
  },
];
export const COMPLIANCE_STATUS = [
  {
    id: 0,
    type: 'All Compliance Status',
  },
  {
    id: 1,
    type: 'Documents Cerification Request',
  },
  {
    id: 2,
    type: 'Documents Genuine',
  },
  {
    id: 3,
    type: 'Interview No Show',
  },
  {
    id: 4,
    type: 'Request For Initial Interview',
  },
  {
    id: 5,
    type: 'PS Plagiarism',
  },
  {
    id: 6,
    type: 'Compliance Review Request',
  },
  {
    id: 7,
    type: 'Cleared',
  },
  {
    id: 8,
    type: 'Hold',
  },
];

export const STUDENTSTATUS = [
  { id: 21, type: 'New Lead', display: true },
  { id: 2, type: 'Priority Lead', display: true },
  { id: 3, type: 'Prospect', display: true },
  { id: 36, type: 'Not Prospect', display: true },
  { id: 16, type: 'Event Participant-Prospect', display: true },
  { id: 22, type: 'Contacted', display: true },
  { id: 35, type: 'Unreachable', display: true },
  { id: 23, type: 'Interested', display: true },
  { id: 24, type: 'Follow-Up Required', display: true },
  { id: 25, type: 'Documents Requested', display: true },
  { id: 26, type: 'Documents Received-Full', display: true },
  { id: 40, type: 'Documents Received-Partial', display: true },
  { id: 27, type: 'Under Review', display: true },
  { id: 17, type: 'Pre-Qualified', display: true },
  { id: 5, type: 'Qualified', display: true },
  { id: 6, type: 'Application Submitted', display: true },
  { id: 28, type: 'Offer Received', display: true },
  { id: 29, type: 'Offer Accepted', display: true },
  { id: 7, type: 'Enrolled', display: true },
  { id: 30, type: 'Inactive', display: true },
  { id: 15, type: 'Dead Lead', display: true },
  { id: 11, type: 'Not Interested', display: true },
  { id: 9, type: 'Disqualified', display: true },
  { id: 8, type: 'Fake Documents', display: true },
  { id: 10, type: 'Junk Lead', display: true },
  { id: 12, type: 'Future Lead', display: true },
  { id: 18, type: 'Compliance Required', display: true },
  { id: 37, type: 'Refund', display: true },
  { id: 38, type: 'Application Tagging', display: true },
  { id: 39, type: 'Appointment Scheduled', display: true },
];

export const GENDER = [
  {
    id: 1,
    type: 'Male',
  },
  {
    id: 2,
    type: 'Female',
  },
  {
    id: 3,
    type: 'Other',
  },
];
export const MARITALSTATUS = [
  {
    id: 1,
    type: 'Single',
  },
  {
    id: 2,
    type: 'Married or Committed',
  },
  {
    id: 3,
    type: 'Divorced',
  },
  {
    id: 4,
    type: 'Unknown',
  },
];
export const HaveIELTS = [
  {
    id: 0,
    type: 'No',
  },
  {
    id: 1,
    type: 'Yes',
  },
  {
    id: 2,
    type: 'Preparing ',
  },
];
export const EDUCATION_LEVELS = [
  {
    id: 1,
    type: 'Grade 12 Certificate / HSC',
  },
  {
    id: 2,
    type: 'A-levels',
  },
  {
    id: 3,
    type: 'O-levels',
  },
  {
    id: 4,
    type: 'Foundation Course',
  },
  {
    id: 5,
    type: 'Diploma Course',
  },
  {
    id: 6,
    type: 'Bachelor Degree',
  },
  {
    id: 7,
    type: 'Master Degree',
  },
  {
    id: 8,
    type: 'Doctoral Degree',
  },
  {
    id: 9,
    type: 'Others',
  },
];

export const QUALIFICATIONS = [
  {
    id: 1,
    type: 'IELTS',
  },
  {
    id: 2,
    type: 'TOEFL',
  },
  {
    id: 3,
    type: 'iBT PTE',
  },
  {
    id: 4,
    type: 'ESOL',
  },
  {
    id: 5,
    type: 'Duolingo',
  },
  {
    id: 6,
    type: 'GCSE/ IGCSE/ O-Level English',
  },
  {
    id: 7,
    type: 'Others',
  },
];
export const CONTACT_SERVICE_TYPE = [
  {
    id: 1,
    type: 'Accommodation',
  },
  {
    id: 2,
    type: 'Taxi Service',
  },
  {
    id: 3,
    type: 'SIM Service',
  },
  {
    id: 4,
    type: 'Training Centre',
  },
  {
    id: 5,
    type: 'IELTS Centre',
  },

  {
    id: 6,
    type: 'Summer School',
  },
  {
    id: 7,
    type: 'College',
  },
  {
    id: 8,
    type: 'Student Recruitment',
  },
  {
    id: 9,
    type: 'Visa Service',
  },
  {
    id: 10,
    type: 'University',
  },
];
export const CONTACT_TYPE = [
  {
    id: 1,
    type: 'Institution',
  },
  // {
  //   id: 2,
  //   type: 'Agent',
  // },
  {
    id: 3,
    type: 'Service providers',
  },
  {
    id: 4,
    type: 'Partnership',
  },
  {
    id: 5,
    type: 'Franchise',
  },
];
export const UNI_TYPES = [
  {
    id: 1,
    type: 'University',
  },
  {
    id: 2,
    type: 'College',
  },
  {
    id: 3,
    type: 'Language Center',
  },
];

export const INTAKES = [
  {
    id: 1,
    type: 'January',
    typeShort: 'Jan',
  },
  {
    id: 2,
    type: 'February',
    typeShort: 'Feb',
  },
  {
    id: 3,
    type: 'March',
    typeShort: 'Mar',
  },
  {
    id: 4,
    type: 'April',
    typeShort: 'Apr',
  },
  {
    id: 5,
    type: 'May',
    typeShort: 'May',
  },
  {
    id: 6,
    type: 'June',
    typeShort: 'Jun',
  },
  {
    id: 7,
    type: 'July',
    typeShort: 'Jul',
  },
  {
    id: 8,
    type: 'August',
    typeShort: 'Aug',
  },
  {
    id: 9,
    type: 'September',
    typeShort: 'Sep',
  },
  {
    id: 10,
    type: 'October',
    typeShort: 'Oct',
  },
  {
    id: 11,
    type: 'November',
    typeShort: 'Nov',
  },
  {
    id: 12,
    type: 'December',
    typeShort: 'Dec',
  },
];

export const Durations = [
  {
    id: 1,
    type: 'January',
    typeShort: 'Jan',
  },
  {
    id: 2,
    type: 'February',
    typeShort: 'Feb',
  },
  {
    id: 3,
    type: 'March',
    typeShort: 'Mar',
  },
  {
    id: 4,
    type: 'April',
    typeShort: 'Apr',
  },
  {
    id: 5,
    type: 'May',
    typeShort: 'May',
  },
  {
    id: 6,
    type: 'June',
    typeShort: 'Jun',
  },
  {
    id: 7,
    type: 'July',
    typeShort: 'Jul',
  },
  {
    id: 8,
    type: 'August',
    typeShort: 'Aug',
  },
  {
    id: 9,
    type: 'September',
    typeShort: 'Sep',
  },
  {
    id: 10,
    type: 'October',
    typeShort: 'Oct',
  },
  {
    id: 11,
    type: 'November',
    typeShort: 'Nov',
  },
  {
    id: 12,
    type: 'December',
    typeShort: 'Dec',
  },
];

export const ENTRY_YEARS = [
  {
    id: 1,
    type: 'Year 1',
  },
  {
    id: 2,
    type: 'Year 2',
  },
  {
    id: 3,
    type: 'Year 3',
  },
];

export const FUNDINGS = [
  {
    id: 0,
    type: 'Unknown',
  },
  {
    id: 1,
    type: 'Self-Funded',
  },
  {
    id: 2,
    type: 'Sponsored',
  },
  {
    id: 3,
    type: 'Scholarship',
  },
];

export const STUDY_LEVEL_GROUPS = [
  {
    id: 1,
    type: 'Undergraduate',
  },
  {
    id: 2,
    type: 'Postgraduate',
  },
  {
    id: 3,
    type: 'Doctorate',
  },
];

export const STUDY_LEVELS = [
  {
    id: 0,
    type: 'Unknown',
    display: true,
  },
  {
    id: 5,
    type: 'Foundation',
    display: true,
  },
  {
    id: 1,
    type: 'Undergraduate',
    display: true,
  },
  {
    id: 3,
    type: 'Master',
    display: true,
  },
  {
    id: 15,
    type: 'Graduate Diploma',
    display: true,
  },
  {
    id: 4,
    type: 'English language',
    display: true,
  },
  {
    id: 6,
    type: 'Pre-master',
    display: true,
  },
  {
    id: 2,
    type: 'PhD',
    display: true,
  },

  {
    id: 7,
    type: 'PG Foundation',
    display: false,
  },
  {
    id: 12,
    type: 'Presessional English',
    display: false,
  },
  {
    id: 14,
    type: 'International Year One',
    display: true,
  },
  {
    id: 17,
    type: 'International Diploma',
    display: false,
  },
  {
    id: 18,
    type: 'UG Diploma',
    display: true,
  },
];
export const yearList = [
  {
    id: 1,
    year: 2010,
  },

  {
    id: 2,
    year: 2011,
  },

  {
    id: 3,
    year: 2012,
  },
  {
    id: 4,
    year: 2013,
  },
  {
    id: 5,
    year: 2014,
  },
  {
    id: 6,
    year: 2015,
  },
  {
    id: 7,
    year: 2016,
  },
  {
    id: 8,
    year: 2017,
  },
  {
    id: 9,
    year: 2018,
  },
  {
    id: 10,
    year: 2019,
  },
  {
    id: 11,
    year: 2020,
  },
  {
    id: 12,
    year: 2021,
  },
  {
    id: 13,
    year: 2022,
  },
  {
    id: 14,
    year: 2023,
  },
  {
    id: 15,
    year: 2024,
  },
  {
    id: 16,
    year: 2025,
  },
  {
    id: 17,
    year: 2026,
  },
  {
    id: 18,
    year: 2027,
  },
  {
    id: 19,
    year: 2028,
  },
];

export const contactEventYear = [
  {
    id: 0,
    year: 0,
    type: 'Select',
  },
  {
    id: 1,
    year: 1, //for empty year filter loading
    type: 'Unknown',
  },
  {
    id: 2,
    year: 2020,
    type: '2020',
  },

  {
    id: 3,
    year: 2021,
    type: '2021',
  },
  {
    id: 4,
    year: 2022,
    type: '2022',
  },
  {
    id: 5,
    year: 2023,
    type: '2023',
  },
  {
    id: 6,
    year: 2024,
    type: '2024',
  },
  {
    id: 7,
    year: 2025,
    type: '2025',
  },
  {
    id: 8,
    year: 2026,
    type: '2026',
  },
  {
    id: 9,
    year: 2027,
    type: '2027',
  },
  {
    id: 10,
    year: 2028,
    type: '2028',
  },
  {
    id: 11,
    year: 2029,
    type: '2029',
  },
];

const previousyear = 2022; //  Year starts with 2022 as required
export const intakeYearList = Array.from({ length: 8 }, (_, i) => ({
  id: i + 1,
  year: previousyear + i,
}));

const previousEventYear = 2020; //  Year starts with 2022 as required
export const eventYearList = Array.from({ length: 8 }, (_, i) => ({
  id: i + 1,
  year: previousEventYear + i,
}));

export const APPLICATION_FILE_STATUSES = [
  {
    id: 1,
    type: 'Assessment & Follow Up',
    displayInApp: true,
  },
  {
    id: 2,
    type: 'Conversion',
    displayInApp: true,
  },
  {
    id: 3,
    type: 'Compliance',
    displayInApp: true,
  },
];

export const APPLICATION_STATUSES = [
  {
    id: 7,
    type: 'Docs/Info Missing',
    displayInApp: true,
  },
  {
    id: 12,
    type: 'Default student',
    displayInApp: true,
  },
  {
    id: 8,
    type: 'Pending',
    displayInApp: true,
  },
  {
    id: 6,
    type: 'Review',
    displayInApp: true,
  },
  {
    id: 1,
    type: 'Applied',
    displayInApp: true,
  },
  {
    id: 2,
    type: 'Conditional Offer',
    displayInApp: true,
  },
  {
    id: 3,
    type: 'Unconditional Offer',
    displayInApp: true,
  },
  {
    id: 4,
    type: 'Rejected',
    displayInApp: true,
  },
  {
    id: 5,
    type: 'Cancel',
    displayInApp: true,
  },
  {
    id: 13,
    type: 'Financial Guarantee/Payment Received',
    displayInApp: false,
  },
  {
    id: 9,
    type: 'CAS issued/visa letter issued/I20 issued',
    displayInApp: false,
  },
  {
    id: 14,
    type: 'Visa Received',
    displayInApp: false,
  },
  {
    id: 10,
    type: 'Enrolled',
    displayInApp: false,
  },
  {
    id: 11,
    type: 'Dead Enrolled',
    displayInApp: false,
  },
  {
    id: 15,
    type: 'Deferral',
    displayInApp: false,
  },
  {
    id: 16,
    type: 'Refund',
    displayInApp: false,
  },
  {
    id: 17,
    type: 'Hold',
    displayInApp: true,
  },
  {
    id: 18,
    type: 'Visa Rejected',
    displayInApp: true,
  },
  {
    id: 19,
    type: 'Not Eligible',
    displayInApp: true,
  },
  {
    id: 20,
    type: 'Conditional Offer Dead Enrolled',
    displayInApp: true,
  },
  {
    id: 21,
    type: 'Unconditional Offer Dead Enrolled',
    displayInApp: true,
  },
  {
    id: 22,
    type: 'Invoice Issued',
    displayInApp: true,
  },
  {
    id: 23,
    type: 'Invoice Paid',
    displayInApp: true,
  },
  {
    id: 24,
    type: 'App Withdrawal',
    displayInApp: true,
  },
  {
    id: 25,
    type: 'Cas Withdrawal',
    displayInApp: true,
  },
  {
    id: 26,
    type: 'Course Withdrawal',
    displayInApp: true,
  },
  {
    id: 27,
    type: 'Progression',
    displayInApp: true,
  },
  {
    id: 28,
    type: 'Conditional not progression',
    displayInApp: true,
  },
  {
    id: 29,
    type: 'Unconditional not progression',
    displayInApp: true,
  },
  {
    id: 30,
    type: 'Refund Requested',
    displayInApp: true,
  },
  {
    id: 31,
    type: 'Refund Complete',
    displayInApp: true,
  },
  {
    id: 32,
    type: 'Visa Applied',
    displayInApp: true,
  },
  {
    id: 33,
    type: 'Visa Reapplied',
    displayInApp: true,
  },
];

export const AGENT_DOCUMENT_TYPES = [
  {
    id: 1,
    type: 'Agent Passport',
  },
  {
    id: 4,
    type: 'Company Logo',
  },
  {
    id: 17,
    type: 'Agent Agreement',
  },
];

export const Announcement_TYPE = [
  {
    id: 1,
    type: 'Admissions',
  },
  {
    id: 2,
    type: 'System Updates',
  },
  {
    id: 3,
    type: 'HR Announcements',
  },
  {
    id: 4,
    type: 'Application Instructions',
  },
  {
    id: 5,
    type: 'New Institution Onboarding',
  },
  {
    id: 6,
    type: 'Compliance Updates',
  },

  {
    id: 7,
    type: 'Scholarships',
  },
  {
    id: 8,
    type: 'Partnerships',
  },

  {
    id: 9,
    type: 'Industry News',
  },
  {
    id: 10,
    type: 'Student Achievements',
  },

  {
    id: 11,
    type: 'Event Updates',
  },
  {
    id: 12,
    type: 'Service Expansion',
  },
  {
    id: 13,
    type: 'Exam Prep',
  },
  {
    id: 14,
    type: 'Career Guidance',
  },

  {
    id: 15,
    type: 'Internships & Jobs',
  },
  {
    id: 16,
    type: 'International Education',
  },

  {
    id: 17,
    type: 'Language Courses',
  },
  {
    id: 18,
    type: 'Financial Aid',
  },
  {
    id: 19,
    type: 'Training And Development',
  },
];

export const User_TYPE_Announcement = [
  {
    id: 1,
    type: 'SuperAdmin',
  },
  {
    id: 2,
    type: 'SeniorAdmin',
  },
  {
    id: 3,
    type: 'Admin',
  },
  {
    id: 4,
    type: 'Agent Staff',
  },
  {
    id: 5,
    type: 'Agent',
  },
  {
    id: 6,
    type: 'Student',
  },

  {
    id: 7,
    type: 'Application Team ',
  },
  {
    id: 8,
    type: 'Application Team Leader',
  },

  {
    id: 10,
    type: 'Referral',
  },
];
export const Templates_TYPE = [
  {
    id: 1,
    type: 'Student / Lead Template',
  },
  {
    id: 2,
    type: 'Agent Template',
  },
  {
    id: 3,
    type: 'Referral Template',
  },
  {
    id: 4,
    type: 'Contact Management Template',
  },
];

export const TEMP_STU_DOCUMENT = [
  2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 28, 29,
];

export const STUDENT_DOCUMENT_TYPES = [
  {
    id: 1,
    type: 'Agent Passport Copy',
  },
  {
    id: 2,
    type: 'Student Passport Copy',
  },
  // {
  //   id: 3,
  //   type: 'Profile Photo',
  // },
  {
    id: 4,
    type: 'Company Logo',
  },
  {
    id: 5,
    type: 'Degree Certificate',
  },
  {
    id: 6,
    type: 'Degree Transcript',
  },
  {
    id: 7,
    type: 'High School Certificate',
  },
  {
    id: 8,
    type: 'High School Transcript',
  },
  {
    id: 9,
    type: 'IELTS Copy/Other Language Proficiency',
  },
  {
    id: 10,
    type: 'Recommendation/Reference Letter',
  },
  {
    id: 11,
    type: 'CV & Work Experience Certificate',
  },
  {
    id: 12,
    type: 'Personal Statement',
  },
  {
    id: 13,
    type: 'Previous CAS copies if existing',
  },
  {
    id: 14,
    type: 'Previous Visa Copies if existing',
  },
  {
    id: 15,
    type: 'Previous Visa Rejection Letter if existing',
  },
  // {
  //   id: 16,
  //   type: 'Student Photo',
  // },
  {
    id: 17,
    type: 'Agent Agreement',
  },
  {
    id: 18,
    type: 'Application Document',
  },
  {
    id: 19,
    type: 'Others',
  },
  {
    id: 20,
    type: 'Application Form',
  },
  {
    id: 21,
    type: 'University Logo',
  },
  {
    id: 22,
    type: 'University Cover Photo',
  },
  {
    id: 26,
    type: 'Consent Letter',
  },
  {
    id: 28,
    type: 'Consent Letter',
  },
  {
    id: 29,
    type: 'Payment/FGL/Scholarship',
  },
  {
    id:30,
    type: 'Offer letter',

  },
  {
    id:31,
    type: 'CAS letter',

  },
  {
    id:33,
    type: ' Payment receipt',

  },
  {
    id:34,
    type: 'Visa',

  }
];

export const Contact_DOCUMENT_TYPES = [
  {
    id: 25,
    type: 'Agreement Doc',
  },
  {
    id: 26,
    type: 'Business Doc',
  },
  {
    id: 27,
    type: 'Others',
  },
];

export const Application_Methods = [
  {
    id: 1,
    name: 'Online',
  },
  {
    id: 2,
    name: 'Portal',
  },
  {
    id: 3,
    name: 'Paper',
  },
  {
    id: 4,
    name: 'Email',
  },
  {
    id: 5,
    name: 'Pathway Providers',
  },
  {
    id: 6,
    name: 'UCAS',
  },
];

export const Email_From = [
  {
    id: 1,
    name: 'noreply@tclglobal.co.uk',
  },
  {
    id: 2,
    name: 'portal@tclglobal.co.uk',
  },
  {
    id: 3,
    name: 'partner@tclglobal.co.uk',
  },
  {
    id: 4,
    name: 'pakistan@tclglobal.co.uk',
  },
  {
    id: 5,
    name: 'bangladesh@tclglobal.co.uk',
  },
  {
    id: 6,
    name: 'india@tclglobal.co.uk',
  },
  {
    id: 7,
    name: 'study@tclglobal.co.uk',
  },
  {
    id: 8,
    name: 'support@tclglobal.co.uk',
  },
  {
    id: 9,
    name: 'info@tclglobal.co.uk',
  },
  {
    id: 10,
    name: 'ghana@tclglobal.co.uk',
  },
  {
    id: 11,
    name: 'ksa@tclglobal.co.uk',
  },
  {
    id: 12,
    name: 'morocco@tclglobal.co.uk',
  },
  {
    id: 13,
    name: 'nigeria@tclglobal.co.uk',
  },
  {
    id: 14,
    name: 'lagos@tclglobal.co.uk',
  },
  {
    id: 15,
    name: 'mena@tclglobal.co.uk',
  },
  {
    id: 16,
    name: 'uk@tclglobal.co.uk',
  },
  {
    id: 17,
    name: 'uttara@tclglobal.co.uk',
  },
  {
    id: 18,
    name: 'm.miah@tclglobal.co.uk',
  },
];

export const Contact_Lead_Status = [
  {
    id: 1,
    name: 'New',
  },
  {
    id: 2,
    name: 'Interested',
  },
  {
    id: 3,
    name: 'Information Collection',
  },
  {
    id: 4,
    name: 'Initiate agreement',
  },
  {
    id: 5,
    name: 'Agreement Signed',
  },
  {
    id: 6,
    name: 'Confirm',
  },
  {
    id: 7,
    name: 'Not Interested',
  },
  {
    id: 9,
    name: 'Follow up',
  },
  {
    id: 10,
    name: 'Wrong data',
  },
  {
    id: 11,
    name: 'Unresponsive',
  },
  {
    id: 21,
    name: 'Not Prospective',
  },
];
// export const temp_studentLead_Status = [
//   1, 2, 3, 4, 5, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 21,
// ];

export const StudentLead_Status = [
  { id: 21, name: 'New Lead' },
  { id: 2, name: 'Priority Lead' },
  { id: 3, name: 'Prospect' },
  { id: 36, name: 'Not Prospect' },
  { id: 16, name: 'Event Participant - Prospect' },
  { id: 22, name: 'Contacted' },
  { id: 35, name: 'Unreachable' },
  { id: 23, name: 'Interested' },
  { id: 24, name: 'Follow-Up Required' },
  { id: 25, name: 'Documents Requested' },
  { id: 26, name: 'Documents Received-Full' },
  { id: 40, name: 'Documents Received-Partial' },
  { id: 27, name: 'Under Review' },
  { id: 17, name: 'Pre-Qualified' },
  { id: 5, name: 'Qualified' },
  { id: 6, name: 'Application Submitted' },
  { id: 28, name: 'Offer Received' },
  { id: 29, name: 'Offer Accepted' },
  { id: 7, name: 'Enrolled' },
  { id: 30, name: 'Inactive' },
  { id: 15, name: 'Dead Lead' },
  { id: 11, name: 'Not Interested' },
  { id: 9, name: 'Disqualified' },
  { id: 8, name: 'Fake Documents' },
  { id: 10, name: 'Junk Lead' },
  { id: 12, name: 'Future Lead' },
  { id: 18, name: 'Compliance Required' }, //Authorization required old name
  { id: 37, name: 'Refund' },
  { id: 38, name: 'Application Tagging' },
  { id: 39, name: 'Appointment Scheduled' },
];

export const StudentLead_Activities = [
  {
    id: 0,
    name: 'All Activity',
  },
  {
    id: 1,
    name: 'Lead Capture',
  },
  {
    id: 2,
    name: 'Lead Initial Conversation',
  },
  {
    id: 3,
    name: 'Connected',
  },
  {
    id: 4,
    name: 'Interested in Pipeline',
  },
  {
    id: 5,
    name: 'Interested start profiling - Prospect',
  },
  {
    id: 6,
    name: 'Not interested - Dead Lead',
  },
  {
    id: 7,
    name: 'Call Back later - Follow up',
  },
  {
    id: 8,
    name: 'Wrong Data - Junk',
  },
  {
    id: 9,
    name: 'Not Connected',
  },
  {
    id: 10,
    name: 'Ringing - Not connected',
  },
  {
    id: 11,
    name: 'Wrong Number - Junk',
  },
  {
    id: 12,
    name: 'Disconnected - Not connected',
  },
  {
    id: 13,
    name: 'Out of reach - Not connected',
  },
  {
    id: 14,
    name: 'Number busy - Not connected',
  },
  {
    id: 15,
    name: 'Lead profiling',
  },
  {
    id: 16,
    name: 'Lead assessment',
  },
  {
    id: 17,
    name: 'Lead application',
  },
  {
    id: 18,
    name: 'Lead admission',
  },
  {
    id: 19,
    name: 'WhatsApp Opt-in sent',
  },
];

export const Service_Charge_Status = [
  {
    id: 0,
    name: 'Unknown',
  },
  {
    id: 1,
    name: 'Free',
  },
  {
    id: 2,
    name: 'Pending',
  },
  {
    id: 3,
    name: 'Paid',
  },
];

export const ApplicationMethodPortalTypes = [
  {
    id: 1,
    name: 'Global',
  },
  // {
  //   id: 2,
  //   name: 'Local',
  // },
  {
    id: 3,
    name: 'Regional',
  },
];

export const Disqualified_Reason = [
  {
    id: 1,
    name: 'IncorrectData',
  },
  {
    id: 2,
    name: 'Not Eligible',
  },
];

export const countryFlag = 'https://flagcdn.com/';

// export const AllEnglishTests = [
//   'IELTS (Academic)',
//   'TOEFL',
//   'Pearson (PTE Academic)',
//   'In House English language test',
//   'Trinity Secure English Language Tests',
//   'Duolingo',
//   'PASSWORD Test',
//   'OIETC',
//   'Cambridge English Qualifications',
//   'Business English Certificate (BEC)',
//   'EFSET',
//   'ESOL',
//   'Test of English for International Communication (TOEIC)',
//   'Oxford International English',
// ];
// export const EnglishTestBitwiseValues = {
//   'IELTS (Academic)': 1,
//   TOEFL: 2,
//   'Pearson (PTE Academic)': 4,
//   'In House English language test': 8,
//   'Trinity Secure English Language Tests': 16,
//   Duolingo: 32,
//   'PASSWORD Test': 64,
//   OIETC: 128,
//   'Cambridge English Qualifications': 256,
//   'Business English Certificate (BEC)': 512,
//   EFSET: 1024,
//   ESOL: 2048,
//   'Test of English for International Communication (TOEIC)': 4096,
//   'Oxford International English': 8192,
// };

export const ChatGetMessage = 'GetMessage';
export const ChatJoinChat = 'JoinChat';
