import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Agent from '../../../api/agent';
import { INTAKES, intakeYearList } from '../../../Util/Constants';
import { TextField, Select, MenuItem, InputLabel, FormControl, Autocomplete, Chip, Paper, Grid, Typography } from '@mui/material';
import { Countries } from '../../../Util/Util';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorPopup from '../RegionalReport/ErrorPopUp';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { motion } from 'framer-motion';

const statusTypes = [
  { id: 0, type: 'Target', key: 'target' },
  { id: 16, type: 'Enrolled', key: 'enrolled' },
  { id: 17, type: 'Enroll. Ratio', key: 'enrolledRatio', isRatio: true },
  { id: 1, type: 'Lead Organic', key: 'organicLead' },
  { id: 2, type: 'Lead Paid', key: 'paidLead' },
  { id: 3, type: 'Total Lead', key: 'totalLead' },
  { id: 4, type: 'Potential', key: 'potentialLead' },
  { id: 5, type: 'Qualified', key: 'qualified' },
  { id: 6, type: 'Applied', key: 'applied' },
  { id: 7, type: 'Apply Ratio', key: 'appliedRatio', isRatio: true },
  { id: 8, type: 'Cond. Offer', key: 'conditionalOffer' },
  { id: 9, type: 'Uncond. Offer', key: 'unconditionalOffer' },
  { id: 10, type: 'Total Offer', key: 'totalOffer' },
  { id: 11, type: 'Offer Ratio', key: 'offerRatio', isRatio: true },
  { id: 12, type: 'Rejection', key: 'rejection' },
  { id: 13, type: 'Reject. Ratio', key: 'rejectionRatio', isRatio: true },
  { id: 14, type: 'Deposit', key: 'deposite' },
  { id: 15, type: 'Num of CAS', key: 'numOfCAS' },
];

const LoadingAnimation = () => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <motion.div
      className="w-32 h-32 flex items-center justify-center bg-white rounded-full"
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      }}
    >
      <motion.span
        className="block w-24 h-24 border-t-8 border-blue-500 rounded-full"
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      />
    </motion.div>
  </div>
);

const CounselorPerformanceReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState('');
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [selectedCitizenships, setSelectedCitizenships] = useState([]);
  const [showFilterMessage, setShowFilterMessage] = useState(false);

  const hasAccess = permissions.CanAccessCounselorPerformanceReport;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await Agent.Users.list();
        setUserOptions(users
          .filter(user => user.isDisabled === false)
          .filter(user => [1, 2, 3].includes(user.userType))
          .map(user => ({ id: user.id.toString(), label: user.name }))
        );
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to fetch users. Please try again later.');
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await Agent.Cache.getBranchOffices();
          if (Array.isArray(response)) {
            setBranches(response.map(branch => ({ id: branch.id.toString(), label: branch.name })));
          } else {
            console.error('Unexpected response format for branch offices:', response);
          }
        } catch (err) {
          console.error('Error fetching branch offices:', err);
          setError('Failed to fetch branch offices. Please try again later.');
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [hasAccess]);

  const fetchData = useCallback(async () => {
    if (!hasAccess) return;

    if (intakeYears.length === 0 && intakeMonths.length === 0 && !startDate && !endDate && !year && selectedUsers.length === 0 && selectedBranches.length === 0 && selectedCitizenships.length === 0) {
      setData([]);
      setShowFilterMessage(true);
      return;
    } else {
      setShowFilterMessage(false);
    }

    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.counselorPerformance({
        intakeYear: intakeYears.map(y => y.year),
        intakeMonth: intakeMonths.map(m => m.id),
        startDate,
        endDate,
        branchId: selectedBranches.map(branch => branch.id),
        citizenship: selectedCitizenships.map(citizenship => citizenship.id),
        userId: selectedUsers.map(user => user.id),
        year: year ? parseInt(year, 10) : undefined,
        type
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
      } else {
        setError('No data available for the selected criteria.');
        setData([]);
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch data. Please try again.');
      setShowErrorPopup(true);
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [hasAccess, intakeYears, intakeMonths, startDate, endDate, selectedBranches, selectedCitizenships, selectedUsers, year, type]);

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setError(null);
  };

  const handleGenerateReport = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setYear('');
    setType(1);
    setData([]);
    setError(null);
    setShowErrorPopup(false);
    setSelectedUsers([]);
    setSelectedBranches([]);
    setSelectedCitizenships([]);
    setShowFilterMessage(false);
  };

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === '' || value === 0) return '';
    if (isRatio) {
      const formattedValue = Number(value).toFixed(2);
      return formattedValue === '0.00' ? '' : `${formattedValue.replace(/\.00$/, '')}%`;
    }
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue >= 1000) {
      return numValue.toLocaleString('en-US', { maximumFractionDigits: 0 });
    }
    return value.toString();
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#e0f2fe',
              fontSize: '0.65rem',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#fefce8',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '0.7rem',
              padding: '2px 4px',
            },
          },
        },
      },
    },
  });

  const columns = [
  { 
    field: 'userName', 
    headerName: 'Counselor', 
    width: 150,
    sortable: true,
    filterable: true,
    hide: false,
    align: 'left',
    headerAlign: 'left',
    headerClassName: "text-xs font-semibold text-blue-800 bg-sky-100 py-2 px-2",
    cellClassName: "text-xs py-1 px-2",
  },
  { 
    field: 'target', 
    headerName: 'Target', 
    width: 70, 
    sortable: true,
    filterable: true,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    headerClassName: "text-xs font-semibold text-blue-800 bg-sky-100 py-2 px-2",
    cellClassName: "text-xs py-1 px-2",
  },
  ...statusTypes.slice(1).map(status => ({
    field: status.key,
    headerName: status.type,
    width: 80,
    sortable: true,
    filterable: true,
    type: status.isRatio ? 'number' : 'string',
    valueFormatter: (params) => formatValue(params.value, status.isRatio),
    align: 'center',
    headerAlign: 'center',
    headerClassName: "text-xs font-semibold text-blue-800 bg-sky-100 py-2 px-2",
    cellClassName: "text-xs py-1 px-2",
    renderHeader: (params) => (
      <div className="flex flex-col items-center justify-center h-full w-full">
        {params.colDef.headerName.split(' ').map((word, index) => (
          <div key={index} className="text-center whitespace-nowrap leading-tight">{word}</div>
        ))}
      </div>
    ),
  })),
];

  const rows = data.map((counselor) => ({
    id: counselor.userId,
    userName: counselor.userName,
    target: counselor.target,
    ...statusTypes.slice(1).reduce((acc, status) => ({
      ...acc,
      [status.key]: counselor[status.key],
    }), {}),
  }));

  const customStyles = `
  .MuiDataGrid-root .MuiDataGrid-cell {
    @apply whitespace-nowrap overflow-hidden text-ellipsis leading-none py-2 px-2;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    @apply whitespace-normal leading-tight min-h-[48px] max-h-[48px] overflow-hidden flex flex-col justify-center py-1 px-2;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders {
    @apply min-h-[48px] max-h-[48px];
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    @apply text-xs font-semibold text-blue-800 text-center leading-tight whitespace-normal overflow-hidden text-ellipsis block;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
`;


  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Counselor Performance Report!" />
      </Paper>
    );
  }

  return (
    <Paper elevation={3} className="p-4 bg-gray-50">
      <div className="mb-4 text-center">
        <h2 className="text-xl font-bold text-gray-800 mb-2">
          Counselor Performance Report
        </h2>
        <div className="flex justify-between items-center">
          <Link
            to="/reportpanel"
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 min-w-[120px] justify-center"
          >
            <ArrowBackIcon className="w-4 h-4 mr-1" />
            Back
          </Link>
          <div className="flex items-center space-x-2">
            <FormControl size="small" className="min-w-[120px]">
              <InputLabel id="type-select-label">Report Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Report Type"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={1}>Assessment</MenuItem>
                <MenuItem value={2}>Followup</MenuItem>
                <MenuItem value={3}>Compliance</MenuItem>
                <MenuItem value={4}>Regional Manager</MenuItem>
                <MenuItem value={5}>Country Manager</MenuItem>
                <MenuItem value={6}>Branch Manager</MenuItem>
              </Select>
            </FormControl>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 min-w-[120px] justify-center"
            >
              <FilterListIcon className="w-4 h-4 mr-1" />
              {showFilters ? 'Hide' : 'Show'}
            </button>
          </div>
        </div>
      </div>
      {showFilters && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-years"
                options={intakeYearList}
                value={intakeYears}
                onChange={(_, newValue) => setIntakeYears(newValue)}
                getOptionLabel={(option) => option.year.toString()}
                renderInput={(params) => <TextField {...params} label="Intake Years" placeholder="Select intake years" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.year}
                      variant="outlined"
                      label={option.year}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-months"
                options={INTAKES}
                value={intakeMonths}
                onChange={(_, newValue) => setIntakeMonths(newValue)}
                getOptionLabel={(option) => option.type}
                renderInput={(params) => <TextField {...params} label="Intake Months" placeholder="Select intake months" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.type}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="branches"
                options={branches}
                value={selectedBranches}
                onChange={(_, newValue) => setSelectedBranches(newValue)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label="Branches" placeholder="Select branches" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
                loading={loadingBranches}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries.map(country => ({ id: country.id.toString(), label: country.name }))}
                value={selectedCitizenships}
                onChange={(_, newValue) => setSelectedCitizenships(newValue)}
                renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenships" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="users"
                options={userOptions}
                value={selectedUsers}
                onChange={(_, newValue) => setSelectedUsers(newValue)}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label="Users" placeholder="Select users" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
          </Grid>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={handleResetFilters}
              className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 min-w-[120px]"
            >
              Reset
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading}
              className={`px-3 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 min-w-[120px] ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? 'Generating...' : 'Generate'}
            </button>
          </div>
        </>
      )}

      {showFilterMessage && (
        <Typography variant="body2" className="mt-4 text-center text-red-500">
          Please select at least one filter to generate the report.
        </Typography>
      )}

      {error ? (
        <Paper elevation={3} className="mt-4 p-4">
          <Typography variant="body2" className="text-center text-red-500">
            {error}
          </Typography>
        </Paper>
      ) : data.length > 0 ? (
        <ThemeProvider theme={theme}>
          <style>{customStyles}</style>
          <div style={{ height: 'calc(100vh - 300px)', width: '100%' }} className="mt-4">
            <DataGridPro
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: false,
                  printOptions: { disableToolbarButton: true },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'userName', sort: 'asc' }],
                },
                pagination: {
                  pageSize: 25,
                },
                density: 'standard',
              }}
              pagination
              className="w-full h-full bg-white shadow-md rounded-lg overflow-hidden text-xs"
              headerHeight={48}
              rowHeight={36}
              density="standard"
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </div>
        </ThemeProvider>
      ) : null}
      {loading && <LoadingAnimation />}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(CounselorPerformanceReport);

