import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';

const CustomToolbar = (show, isInternal) => {
  return (
    <GridToolbarContainer className="bg-gray-100 p-2 rounded-t-lg">
      <div className="flex space-x-2">
        {isInternal && (
          <>
            <GridToolbarColumnsButton className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded text-sm" />
            <GridToolbarFilterButton className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded text-sm" />
          </>
        )}
        <GridToolbarDensitySelector className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded text-sm" />
        {show && <GridToolbarExport className="bg-blue-500 text-white hover:bg-blue-600 px-2 py-1 rounded text-sm" />}
      </div>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;

