import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import {
  INTAKES,
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
  STUDY_LEVELS,
  Service_Charge_Status,
} from '../../../Util/Constants';
import { intakeYearList } from '../../Report/Helper/HelperFunction';
import { Countries } from '../../../Util/Util';
import { Done } from '@mui/icons-material';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import * as yup from 'yup';
import Agent from '../../../api/agent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PhoneInput from 'react-phone-number-input';
import phoneInput from '../LeadUtils/phoneInput';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'minimum character 2')
    .max(50, 'maximum character 50')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(3, 'minimum character 3')
    .max(50, 'minimum character 50')
    .required('Last Name is required'),
  // selectedlLeadOrigin: yup.string().required('Lead Origin is required'),
  selectedlLeadSource: yup.string().required('Lead Source is required'),
  // selectedlLeadChannel: yup.string().required('Lead Channel is required'),

  // email: yup.string().email('Invalid email address'),
  // .required('Email Address is required'),
  // .required('Email Address is required'),
  // email: yup
  //   .string()
  //   .required('Email address is required')
  //   .matches(
  //     /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/,
  //     'Invalid email address'
  //   )
  //   .test('unique-email', 'Only one email address allowed', (value) => {
  //     // Split the value by comma and semicolon and check the count
  //     const emailAddresses = value.split(/[;,]/);
  //     return emailAddresses.length === 1;
  //   })
  //   .max(40),
  // PhoneNumber: yup.string().required('Phone Number is required'),
  citizenshipFilter: yup.number().required('Citizenship is required'),
  studyLevelFilter: yup.string().required('Study Level is required'),
  // intakeMonthFilter: yup.string().required('Intake Month is required'),
  intakeYear: yup.string().required('Intake Year is required'),

  preferredDestinations: yup
    .array()
    .min(1, 'Select at least one preferred destination'),
  branchId: yup.string().required('Branch is required'),
});

const AddLeadForm = ({ appUserId }) => {
  const [intakeYear, setIntakeYear] = useState(null);
  const [studyLevelFilter, setStudyLevelFilter] = useState('');
  const [intakeMonthFilter, setIntakeMonthFilter] = useState('');
  const [citizenshipFilter, setCitizenshipFilter] = useState();
  // const [selectedlLeadOrigin, setSelectedlLeadOrigin] = useState('');
  const [selectedlLeadSource, setSelectedlLeadSource] = useState('');
  // const [selectedlLeadChannel, setSelectedlLeadChannel] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(null);
  const [PhoneNumber, setPhoneNo] = useState(null);
  const [serviceChargeStatus, setServiceChargeStatus] = useState(0);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [remarks, setRemarks] = useState('');
  const [preferredDestinations, setPreferredDestinations] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [branchoffice, setBranchOffices] = useState([]);
  const [branchId, setBranchId] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    //... (other field errors)
    // Initialize with an empty string 
  });

  const [phoneError, setPhoneError] = useState('');
  const [formResetKey, setFormResetKey] = useState(0);

  const handlePhoneChange = (value, country) => {
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNo(value);
        setPhoneError('');
      } else {
        setPhoneNo(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number
      setPhoneNo(null);
      setPhoneError('');
    }
  };

  // useEffect(()=>{
  //   if(Countries.length==248){
  //    Countries.push({
  //       id: 0,
  //       name: 'Unknown', 
  //       order: '',
  //       code: '',
  //     })
  //     Countries.sort(function (a, b) {
  //         if ((a.id < b.id)) {
  //           return -1;
  //         }
  //         if ((a.id > b.id)) {
  //           return 1;
  //         }
  //       return 0;
  //     });
  //    }
  //  },[])

  const handleSaveLead = async () => {
    try {
      // Validate form data
      await validationSchema.validate(
        {
          firstName,
          lastName,
          PhoneNumber,
          // selectedlLeadOrigin,
          selectedlLeadSource,
          // selectedlLeadChannel,
          citizenshipFilter,
          studyLevelFilter,
          intakeMonthFilter,
          intakeYear,
          serviceChargeStatus,
          branchId,
          preferredDestinations,
        },
        { abortEarly: false }
      );

      // If validation passes, proceed to save the lead
      if (typeof branchId === 'number' && branchId !== 0) {
        const payload = {
          PreferenceStudyYear: intakeYear,
          PreferenceStudyLevelId: studyLevelFilter,
          PreferenceStudyMonth: intakeMonthFilter,
          Citizenship: citizenshipFilter,
          // Origin: selectedlLeadOrigin,
          Source: selectedlLeadSource,
          // Channel: selectedlLeadChannel,
          FirstName: firstName,
          LastName: lastName,
          Email: email||null,
          AdditionalEmail1: null,
          AdditionalEmail2: null,
          LeadListName: null,
          PhoneNumber: PhoneNumber,
          ServiceChargeStatus: serviceChargeStatus,
          DateOfBirth: dateOfBirth,
          BranchOfficeId: branchId,
          Status: 0,
          Gender: 0,
          MaritalStatus: 0,
          CountryOfResidence: 0,
          CountryOfIssue: 0,
          Country: 0,
          ProfileComplete: false,
          FundType: 0,
          remarks,
          AppUserId: appUserId,
          preferredDestinations: preferredDestinations.map(
            (destination) => destination.id
          ),
        };

        const response = await Agent.StudentLeads.saveQuickStudentInternal(payload);
        setSuccessMessage('Student Saved successfully!', response);
        setSuccessOpen(true);
        clearForm();
      } else {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          branchId: 'Branch is required',
        }));
      }
    } catch (error) {
      console.error(error);
      const errors = {};
      error?.inner?.forEach((e) => {
        errors[e.path] = e.message;
      });

      setFieldErrors(errors);
      setErrorMessage('error' + error);
    }
  };

  const clearForm = () => {
    setIntakeYear(null);
    setStudyLevelFilter('');
    setIntakeMonthFilter('');
    setCitizenshipFilter(0);
    // setSelectedlLeadOrigin('');
    setSelectedlLeadSource('');
    // setSelectedlLeadChannel('');
    setFirstName('');
    setLastName('');
    setEmail(null);
    setPhoneNo(null);
    setServiceChargeStatus(0);
    setDateOfBirth(new Date());
    setRemarks('');
    setPreferredDestinations([]);
    setFormResetKey((prevKey) => prevKey + 1); // Increment the key to force re-render for  prefered study destination field
    setBranchId('');
  };

  const handleLeadFirstName = (event) => {
    setFirstName(event.target.value);
    fieldErrors.firstName = '';
  };

  const handleLeadLastName = (event) => {
    setLastName(event.target.value);
    fieldErrors.lastName = '';
  };
  const handleLeadEmail = (event) => {
    setEmail(event.target.value);
    // fieldErrors.email = '';
  };
  // const handleLeadPhone = (event) => {
  //   setPhoneNo(event.target.value);
  //   // fieldErrors.PhoneNumber = '';
  // };
  const handleBranch = (event) => {
    setBranchId(event.target.value);
    fieldErrors.branchId = '';
  };

  const handleIntakeYear = (event) => {
    setIntakeYear(event.target.value);
    fieldErrors.intakeYear = '';
  };

  const handleServiceCharge = (event) => {
    setServiceChargeStatus(event.target.value);
    // fieldErrors.serviceChargeStatus='';
  };

  const handleDateOfBirth = (event) => {
    setDateOfBirth(event.target.value);
    fieldErrors.dateOfBirth = '';
  };

  const handleStudyLevelFilterChange = (event) => {
    setStudyLevelFilter(event.target.value);
    fieldErrors.studyLevelFilter = '';
  };

  const handleIntakeMonthFilterChange = (event) => {
    setIntakeMonthFilter(event.target.value);
    fieldErrors.intakeMonthFilter = '';
  };
  // const handleSelectedLeadOrigin = (event) => {
  //   setSelectedlLeadOrigin(event.target.value);
  //   fieldErrors.selectedlLeadOrigin = '';
  // };
  const handleSelectedLeadSource = (event) => {
    setSelectedlLeadSource(event.target.value);
    fieldErrors.selectedlLeadSource = '';
  };
  // const handleSelectedLeadChannel = (event) => {
  //   setSelectedlLeadChannel(event.target.value);
  //   fieldErrors.selectedlLeadChannel = '';
  // };

  const handleCitizenshipFilterChange = (event, newValue) => {
    if (newValue) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        citizenshipFilter: false, // Reset the error state
      }));
      setCitizenshipFilter(newValue.id);
      fieldErrors.citizenshipFilter = '';
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        citizenshipFilter: true, // Set the error state to true
      }));
      setCitizenshipFilter(0);
    }
  };

  const handlePreferredDestination = (event, newValue) => {
    setPreferredDestinations(newValue);

    fieldErrors.preferredDestinations = '';
  };

  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };

  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setBranchOffices);
  }, []);

  return (
    // <div className='bg-cyan-950	rounded-lg w-auto'>
    <div className="bg-gradient-to-r from-sky-100 to-sky-200 min-h-screen flex justify-center items-center">
      <Container maxWidth="md" className="pt-4 pb-2">
        <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow-lg rounded-3xl sm:p-10">
          <h2 className="text-3xl text-center font-bold mb-6 mt-0">
            Add Student
          </h2>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.firstName}
                helperText={fieldErrors.firstName}
                value={firstName}
                onChange={handleLeadFirstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.lastName}
                helperText={fieldErrors.lastName}
                value={lastName}
                onChange={handleLeadLastName}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.selectedlLeadOrigin}
                  helperText={fieldErrors.selectedlLeadOrigin}
                  label="Lead Origin"
                  name="selectedlLeadOrigin"
                  variant="outlined"
                  value={selectedlLeadOrigin}
                  onChange={handleSelectedLeadOrigin}
                >
                  {LEADORIGINS.map((origin) => (
                    <MenuItem key={origin.id} value={origin.id}>
                      {origin.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.selectedlLeadSource}
                  helperText={fieldErrors.selectedlLeadSource}
                  label="Lead Source"
                  name="selectedlLeadSource"
                  variant="outlined"
                  value={selectedlLeadSource}
                  onChange={handleSelectedLeadSource}
                >
                  {STUDENTSOURCES.map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      {source.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.selectedlLeadChannel}
                  helperText={fieldErrors.selectedlLeadChannel}
                  label="Lead Channel"
                  name="selectedlLeadChannel"
                  variant="outlined"
                  value={selectedlLeadChannel}
                  onChange={handleSelectedLeadChannel}
                >
                  {LEADCHANNELS.map((channel) => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid> */}

            <Grid item xs={6}>
              <TextField
                label="Email"
                variant="outlined"
                // error={!!fieldErrors.email}
                // helperText={fieldErrors.email}
                fullWidth
                value={email}
                onChange={handleLeadEmail}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <PhoneInput
                  placeholder="Phone Number"
                  value={PhoneNumber}
                  onChange={handlePhoneChange}
                  inputComponent={phoneInput}
                />
                {phoneError && (
                  <FormHelperText error>{phoneError}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    Countries.find(
                      (country) => country.id === citizenshipFilter
                    ) || null
                  }
                  onChange={handleCitizenshipFilterChange}
                  options={Countries.filter((item)=>item.id!=0)}
                  getOptionLabel={(country) => country.name}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        label="Citizenship"
                        variant="outlined"
                        required
                        error={fieldErrors.citizenshipFilter} // Use the error state from fieldError
                        helperText={
                          fieldErrors.citizenshipFilter
                            ? 'Citizenship is required'
                            : ''
                        }
                      />
                    </div>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Date Of Birth"
                  type="date"
                  error={!!fieldErrors.dateOfBirth}
                  helperText={fieldErrors.dateOfBirth}
                  value={dateOfBirth}
                  onChange={handleDateOfBirth}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.studyLevelFilter}
                  helperText={fieldErrors.studyLevelFilter}
                  label="Preferred Study Level"
                  name="studyLevelFilter"
                  variant="outlined"
                  value={studyLevelFilter}
                  onChange={handleStudyLevelFilterChange}
                >
                  {STUDY_LEVELS.map((studyLevel) => (
                    <MenuItem key={studyLevel.id} value={studyLevel.id}>
                      {studyLevel.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  // required
                  // error={!!fieldErrors.intakeMonthFilter}
                  // helperText={fieldErrors.intakeMonthFilter}
                  label="Intake Month"
                  name="intakeMonthFilter"
                  variant="outlined"
                  value={intakeMonthFilter}
                  onChange={handleIntakeMonthFilterChange}
                >
                  {INTAKES.map((intakeMonth) => (
                    <MenuItem key={intakeMonth.id} value={intakeMonth.id}>
                      {intakeMonth.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.intakeYear}
                  helperText={fieldErrors.intakeYear}
                  label="Intake Year"
                  name="intakeYear"
                  variant="outlined"
                  value={intakeYear}
                  onChange={handleIntakeYear}
                >
                  {intakeYearList.map((intakeYear) => (
                    <MenuItem key={intakeYear.id} value={intakeYear.year}>
                      {intakeYear.year}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  // error={!!fieldErrors.serviceChargeStatus}
                  // helperText={fieldErrors.serviceChargeStatus}
                  label="Service Charge"
                  name="serviceChargeStatus"
                  variant="outlined"
                  value={serviceChargeStatus}
                  onChange={handleServiceCharge}
                >
                  {Service_Charge_Status.map((charge) => (
                    <MenuItem key={charge.id} value={charge.id}>
                      {charge.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.branchId}
                  helperText={fieldErrors.branchId}
                  label="Branch"
                  name="branchId"
                  variant="outlined"
                  value={branchId}
                  onChange={handleBranch}
                >
                  {branchoffice.map((branchId) => (
                    <MenuItem key={branchId.id} value={branchId.id}>
                      {branchId.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Autocomplete
                key={formResetKey}
                multiple
                options={Countries}
                onChange={handlePreferredDestination}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Preferred Destinations"
                    placeholder="Select Preferred Destinations"
                    required
                    error={!!fieldErrors.preferredDestinations}
                    helperText={
                      fieldErrors.preferredDestinations
                        ? 'Preferred Destination is required'
                        : ''
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Remarks"
                variant="outlined"
                fullWidth
                multiline
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                rows={4}
              />
            </Grid>

            <Grid item xs={12} className="flex justify-center">
              <Button
                variant="contained"
                onClick={handleSaveLead}
                color="success"
                className="min-w-fit py-2 px-6 mt-6 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                endIcon={<Done />}
              >
                Save
              </Button>
            </Grid>

            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              <DialogTitle id="success-dialog-title">Success</DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
              }}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </div>
      </Container>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLeadForm);
