
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableChart, Map, BarChart, Assessment, Home, Visibility, Person } from '@mui/icons-material';
import { Typography, Button, Breadcrumbs, Link } from '@mui/material';
import { motion } from 'framer-motion';

const reportTypes = [
  {
    title: 'Global Sales Progress',
    description: 'Comprehensive analysis of worldwide sales metrics',
    icon: TableChart,
    path: '/globalsalesreport',
    color: 'bg-blue-50',
    iconColor: 'text-blue-600',
    buttonColor: 'bg-blue-600',
  },
  {
    title: 'Regional Sales Progress (B2C)',
    description: 'In-depth evaluation of branch efficiency by country',
    icon: Map,
    path: '/regionalreport',
    color: 'bg-emerald-50',
    iconColor: 'text-emerald-600',
    buttonColor: 'bg-emerald-600',
  },
  {
    title: 'Global Lead Source Performance (B2C)',
    description: 'Detailed analysis of global lead generation channels',
    icon: BarChart,
    path: '/sourceperformancereport',
    color: 'bg-violet-50',
    iconColor: 'text-violet-600',
    buttonColor: 'bg-violet-600',
  },
  {
    title: 'Regional Source Performance (B2C)',
    description: 'Region-based evaluation of lead source performance',
    icon: Assessment,
    path: '/regionalsourcereport',
    color: 'bg-amber-50',
    iconColor: 'text-amber-600',
    buttonColor: 'bg-amber-600',
  },
  {
    title: 'Counselor Performance (Global & Regional)',
    description: 'Overview of individual counselor performance globally',
    icon: Person,
    path: '/counselorperformance',
    color: 'bg-rose-50',
    iconColor: 'text-rose-600',
    buttonColor: 'bg-rose-600',
  },
];

const ReportCard = ({ report, index }) => {
  const navigate = useNavigate();
  
  return (
    <motion.div
      className={`rounded-lg overflow-hidden shadow-lg transition-all duration-300 ${report.color} border border-gray-200`}
      whileHover={{ y: -5, boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
    >
      <div className="p-4 flex flex-col h-full">
        <div className="flex items-start mb-3">
          <div className={`p-2 rounded-full mr-3 ${report.iconColor} bg-white shadow-sm flex-shrink-0`}>
            <report.icon className="w-5 h-5" />
          </div>
          <h2 className="text-md font-semibold text-gray-800 leading-tight min-h-[2rem] flex items-center">
            {report.title}
          </h2>
        </div>
        <p className="text-sm text-gray-600 mb-4 flex-grow h-10 overflow-hidden">
          {report.description}
        </p>
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate(report.path)}
          className={`mt-2 text-white ${report.buttonColor} rounded-full py-2 h-10 flex items-center justify-center`}
          startIcon={<Visibility className="h-4 w-4" />}
        >
          <span className="font-medium">Access Report</span>
        </Button>
      </div>
    </motion.div>
  );
};

const ReportingPanel = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-sky-100 to-sky-200 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <Breadcrumbs aria-label="breadcrumb" className="mb-4">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="/"
            className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
          >
            <Home sx={{ mr: 0.5 }} fontSize="small" />
            Home
          </Link>
          <Typography color="text.primary" className="font-medium">Report Panel</Typography>
        </Breadcrumbs>
        
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" component="h1" className="text-2xl font-bold text-gray-800 mb-2">
            TCL Global Business Intelligence Hub
          </Typography>
          <Typography variant="subtitle1" className="text-lg text-gray-600 mb-8">
            Access comprehensive reports and analytics to drive TCL Global&apos;s business forward
          </Typography>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {reportTypes.map((report, index) => (
            <ReportCard key={index} report={report} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportingPanel;
