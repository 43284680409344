/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import {
  INTAKES,
  intakeYearList,
  STUDENTSOURCES,
} from '../../../Util/Constants';
import ErrorPopup from './ErrorPopUp';

const statusTypes = [
  { id: 1, type: 'Lead Organic', key: 'organicLead' },
  { id: 2, type: 'Lead Paid', key: 'paidLead' },
  { id: 3, type: 'Total Lead', key: 'totalLead' },
  { id: 4, type: 'Potential Lead', key: 'potentialLead' },
  { id: 5, type: 'Qualified', key: 'qualified' },
  { id: 6, type: 'Applied', key: 'applied' },
  { id: 7, type: 'Applied Ratio', key: 'appliedRatio', isRatio: true },
  { id: 8, type: 'Conditional Offer', key: 'conditionalOffer' },
  { id: 9, type: 'Unconditional Offer', key: 'unconditionalOffer' },
  { id: 10, type: 'Total Offer', key: 'totalOffer' },
  { id: 11, type: 'Offer Ratio', key: 'offerRatio', isRatio: true },
  { id: 12, type: 'Rejection', key: 'rejection' },
  { id: 13, type: 'Rejection Ratio', key: 'rejectionRatio', isRatio: true },
  { id: 14, type: 'Deposit', key: 'deposite' },
  { id: 15, type: 'Num of CAS', key: 'numOfCAS' },
  { id: 16, type: 'Enrolled', key: 'enrolled' },
  { id: 17, type: 'Enrolled Ratio', key: 'enrolledRatio', isRatio: true },
];

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const RegionalReport = ({ permissions }) => {
  const navigate = useNavigate();
  const { country } = useParams();
  const [branches, setBranches] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBranches = useCallback(async () => {
    try {
      const branchData = await Agent.Cache.getBranchOffices();
      setBranches(branchData);

      const uniqueCountries = Array.from(
        new Set(
          branchData
            .filter((branch) => branch.countryId !== 0)
            .map((branch) => {
              const countryInfo = Countries.find(
                (c) => c.id === branch.countryId
              );
              return countryInfo &&
                permissions[
                  `CanAccessRegionalReport${countryInfo.name.replace(
                    /\s+/g,
                    ''
                  )}`
                ]
                ? JSON.stringify({
                    id: branch.countryId,
                    name: countryInfo.name,
                    code: countryInfo.code,
                  })
                : null;
            })
            .filter(Boolean)
        )
      ).map((country) => JSON.parse(country));

      setCountries(uniqueCountries);

      if (uniqueCountries.length === 0) {
        setError('You do not have permission to access any regional reports.');
      } else if (
        !country ||
        !uniqueCountries.some(
          (c) => c.name.toLowerCase() === country.toLowerCase()
        )
      ) {
        navigate(
          `/regionalreport/${uniqueCountries[0].name.toLowerCase()}`
        );
      }
    } catch (err) {
      console.error('Error fetching branch data:', err);
      setError('Failed to fetch branch information');
    } finally {
      setLoading(false);
    }
  }, [permissions, navigate, country]);

  useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  const handleCountryChange = (countryName) => {
    navigate(`/regionalreport/${countryName.toLowerCase()}`);
  };

  const getFlagUrl = (countryCode) => {
    return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <ErrorPopup error={error} />;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="w-full mx-auto px-2 sm:px-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Regional Report (Country)
          </h1>
          <Link
            to="/reportpanel"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Back to Reporting Panel
          </Link>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-2">
            {countries.map((c) => (
              <button
                key={c.id}
                onClick={() => handleCountryChange(c.name)}
                className={`px-2 py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 flex items-center justify-center gap-1 ${
                  country && country.toLowerCase() === c.name.toLowerCase()
                    ? 'bg-blue-500 text-white'
                    : 'bg-white text-gray-700 hover:bg-blue-100'
                }`}
              >
                <img
                  src={getFlagUrl(c.code)}
                  alt={`${c.name} flag`}
                  className="w-4 h-3 object-cover rounded-sm shadow-sm"
                  loading="lazy"
                />
                <span className="truncate">
                  {country && country.toLowerCase() === c.name.toLowerCase()
                    ? c.name.toUpperCase()
                    : c.name}
                </span>
              </button>
            ))}
          </div>
        </div>
        {country && (
          <CountryReport
            country={country}
            branches={branches}
            Countries={Countries}
            getFlagUrl={getFlagUrl}
          />
        )}
      </div>
    </div>
  );
};

const CountryReport = ({ country, branches, Countries, getFlagUrl }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('branches');
  const countryInfo = Countries.find(
    (c) => c.name.toLowerCase() === country.toLowerCase()
  );
  const countryBranches = branches.filter(
    (branch) => branch.countryId === countryInfo?.id
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 flex items-center gap-3 mb-4 md:mb-0">
          {countryInfo && (
            <img
              src={getFlagUrl(countryInfo.code)}
              alt={`${country} flag`}
              className="w-6 h-4 md:w-8 md:h-6 object-cover rounded-sm shadow-sm"
            />
          )}
          {country.toUpperCase()}
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab('branches')}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === 'branches'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Branches
          </button>
          <button
            onClick={() => setActiveTab('allBranches')}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === 'allBranches'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            All Branches Report
          </button>
        </div>
      </div>

      {activeTab === 'branches' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {countryBranches.map((branch) => (
            <div
              key={branch.id}
              className="bg-gray-50 rounded-lg p-4 shadow transition-all duration-200 hover:shadow-lg"
            >
              <h3 className="text-lg font-medium mb-2 text-gray-700">
                {branch.name}
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                {branch.branchEmail || 'No email available'}
              </p>
              <button
                onClick={() => navigate(`/branchreport/${branch.id}`)}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300"
              >
                View Report
              </button>
            </div>
          ))}
        </div>
      )}

      {activeTab === 'allBranches' && (
        <AllBranchesReport country={country} branches={countryBranches} />
      )}
    </div>
  );
};

const AllBranchesReport = ({ country, branches }) => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sources, setSources] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [year, setYear] = useState('');
  const [citizenship, setCitizenship] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const months = INTAKES.map((intake) => intake.type);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.globalSalesProgress({
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources,
        branches: branches.map((branch) => branch.id),
        year: year ? [year] : [],
        citizenship,
        type: 2,
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
      } else {
        setError('No data available for the selected criteria.');
      }
    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    sources,
    branches,
    year,
    citizenship,
  ]);

  const handleGenerateReport = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setSources([]);
    setYear('');
    setCitizenship([]);
    setData([]);
    setError(null);
  };

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === 0 || value === '')
      return '';
    if (isRatio) {
      const formattedValue = Number(value).toFixed(0);
      return formattedValue === '0' ? '' : `${formattedValue}%`;
    }
    return value.toString();
  };

  const calculateTotal = (statusKey) => {
    return data.reduce((sum, monthData) => {
      const value = monthData[statusKey];
      return sum + (value !== undefined && value !== null ? Number(value) : 0);
    }, 0);
  };

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
      <div className="flex justify-between items-center mb-4">
        <Typography
          variant="h5"
          component="h2"
          className="text-gray-800 font-bold"
        >
          All Branches Report: {country.toUpperCase()}
        </Typography>
        {showFilters && (
          <button
            onClick={() => setShowFilters(false)}
            className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FilterListIcon className="w-5 h-5 mr-1" />
            Hide Filters
          </button>
        )}
      </div>

      {showFilters ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-years"
                options={intakeYearList.map((year) => ({
                  id: year.year.toString(),
                  label: year.year.toString(),
                }))}
                value={intakeYears.map((id) => ({ id, label: id }))}
                onChange={(_, newValue) =>
                  setIntakeYears(newValue.map((v) => v.id))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Intake Years"
                    placeholder="Select intake years"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="intake-months"
                options={[
                  { id: '', label: 'All Months' },
                  ...INTAKES.map((month) => ({
                    id: month.id.toString(),
                    label: month.type,
                  })),
                ]}
                value={intakeMonths.map((id) => ({
                  id,
                  label:
                    INTAKES.find((m) => m.id.toString() === id)?.type || id,
                }))}
                onChange={(_, newValue) =>
                  setIntakeMonths(newValue.map((v) => v.id))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Intake Months"
                    placeholder="Select intake months"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                id="sources"
                options={STUDENTSOURCES.map((source) => ({
                  id: source.id.toString(),
                  label: source.type,
                }))}
                value={sources.map((id) => ({
                  id,
                  label:
                    STUDENTSOURCES.find((s) => s.id.toString() === id)?.type ||
                    id,
                }))}
                onChange={(_, newValue) =>
                  setSources(newValue.map((v) => v.id))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sources"
                    placeholder="Select sources"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries.map((country) => ({
                  id: country.id.toString(),
                  label: country.name,
                }))}
                value={citizenship.map((id) => ({
                  id,
                  label:
                    Countries.find((c) => c.id.toString() === id)?.name || id,
                }))}
                onChange={(_, newValue) =>
                  setCitizenship(newValue.map((v) => v.id))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Citizenship"
                    placeholder="Select citizenship"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
          </Grid>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset Filters
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? 'Generating...' : 'Generate Report'}
            </button>
          </div>
        </>
      ) : (
        <button
          onClick={() => setShowFilters(true)}
          className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <FilterListIcon className="w-5 h-5 mr-1" />
          Show Filters
        </button>
      )}

      {error && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-md shadow-md"
          role="alert"
        >
          <div className="flex items-center">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-red-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Error</p>
              <p className="text-sm">{error}</p>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Paper elevation={3} className="mt-6 p-4">
          <Typography variant="body1" className="mb-2 text-center">
            Generating report...
          </Typography>
          <LoadingAnimation />
        </Paper>
      ) : (
        !error &&
        data.length > 0 && (
          <Paper
            elevation={3}
            className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg"
          >
            <div className="overflow-x-auto">
              <table className="w-full text-xs border-collapse">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                      rowSpan={2}
                    >
                      Status Type
                    </th>
                    {months.map((month) => (
                      <th
                        key={month}
                        className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                        colSpan={2}
                      >
                        {month.slice(0, 3)}
                      </th>
                    ))}
                    <th
                      className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-green-200"
                      colSpan={2}
                    >
                      Total
                    </th>
                  </tr>
                  <tr>
                    {months.map((month) => (
                      <React.Fragment key={`header-${month}`}>
                        <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                          Target
                        </th>
                        <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                          Achieve
                        </th>
                      </React.Fragment>
                    ))}
                    <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                      Target
                    </th>
                    <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                      Achieve
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statusTypes.map((status, index) => (
                    <tr
                      key={status.id}
                      className={
                        index % 2 === 0
                          ? 'bg-white hover:bg-sky-50'
                          : 'bg-gray-50 hover:bg-sky-100'
                      }
                    >
                      <td
                        className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? '#FFFFFF' : '#F9FAFB',
                        }}
                      >
                        {status.type}
                      </td>
                      {months.map((month, monthIndex) => {
                        const monthData =
                          data.find((d) => d.month === monthIndex + 1) || {};
                        const achievement = monthData[status.key];
                        const target = '';
                        return (
                          <React.Fragment key={`${status.id}-${month}`}>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-red-600 text-xs">
                                {formatValue(target, status.isRatio)}
                              </div>
                            </td>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-green-600 text-xs">
                                {formatValue(achievement, status.isRatio)}
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                      <td className="py-1 px-1 text-center border-r border-gray-300">
                        <div className="font-medium text-red-600 text-xs">
                          {formatValue('', status.isRatio)}
                        </div>
                      </td>
                      <td className="py-1 px-1 text-center border-r border-gray-300">
                        <div className="font-medium text-green-600 text-xs">
                          {formatValue(
                            calculateTotal(status.key),
                            status.isRatio
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Paper>
        )
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(RegionalReport);

