import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Alert, Grid, Paper, Snackbar, Tooltip } from '@mui/material';
import Agent from '../../../../api/agent';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import user from '../../../../assets/images/user_placeholder.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckIcon from '@mui/icons-material/Check';
import { format } from 'date-fns';
import UserContext from '../../../../context/user-context';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
  TimelineDot,
  LoadingButton,
} from '@mui/lab';
import { APPLICATION_STATUSES } from '../../../../Util/Constants';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import LoadingView from '../../../Common/Loading/LoadingView';
import Appointment from '../../../Appointments/Appointment';
import useWindowSize from '../../common/useWindowSize';

const PREFIX = 'Dashboard';

const classes = {
  root: `${PREFIX}-root`,
  details: `${PREFIX}-details`,
  content: `${PREFIX}-content`,
  cover: `${PREFIX}-cover`,
  controls: `${PREFIX}-controls`,
  playIcon: `${PREFIX}-playIcon`,
  paper: `${PREFIX}-paper`,
  secondaryTail: `${PREFIX}-secondaryTail`,
  timelineConnector: `${PREFIX}-timelineConnector`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.details}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '280px',
  },
  [`& .${classes.content}`]: {
    flex: '1 0 auto',
  },
  [`& .${classes.cover}`]: {
    width: 151,
  },
  [`& .${classes.controls}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  [`& .${classes.playIcon}`]: {
    height: 38,
    width: 38,
  },
  [`& .${classes.paper}`]: {
    padding: '6px 16px',
  },
  [`& .${classes.secondaryTail}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
  [`& .${classes.timelineConnector}`]: {
    height: 100,
  },
}));

const Dashboard = ({ role, permissions, isInternal }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState();
  const [studentPhoto, setStudentPhoto] = useState();
  const [timeline, setTimeline] = useState();
  const userContext = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [addMode, setAddMode] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [openConfirmLoginCreate, setOpenConfirmLoginCreate] = useState(false);

  //for server response
  const [email, setEmail] = useState('');
  const [emailRes, setEmailRes] = useState('');
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [openConfirmMobileLoginCreate, setOpenConfirmMobileLoginCreate] =
    useState(false);

  useEffect(() => {
    Agent.Students.getStudent(id)
      .then((std) => {
        setStudent(std);
        setStudentPhoto(
          std.studentCloudFiles.filter((file) => file.documentType == 16)[0]
        );
        if (std.applications.length == 0) {
          updateTimeline(null, null, null, false);
        } else {
          updateTimeline(
            std.applications[0].appliedOn,
            std.applications[0].status,
            std.applications[0].updatedOn,
            true
          );
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        debugger;
      });
  }, []);

  const addRecord = (record) => {
    let stu = {
      studentId: student.id,
      name: student.firstName + ' ' + student.lastName,
      countryOfResidence: student.countryOfResidence,
      email: student.email,
    };
    setAppointment({ ...record, ...stu });
    setAddMode(true);
  };

  const handleAppointmentFinish = (record) => {
    setAddMode(false);
    navigate(`/appointments`);
  };
  const updateTimeline = (
    appliedOn,
    status,
    updatedOn,
    applicationAvailable
  ) => {
    setTimeline({
      appliedOn: applicationAvailable
        ? format(new Date(appliedOn), 'MMMM d, yyyy')
        : '',
      status: applicationAvailable
        ? APPLICATION_STATUSES.find(({ id }) => id == status).type
        : '',
      updatedOn: applicationAvailable
        ? format(new Date(updatedOn), 'MMMM d, yyyy')
        : '',
    });
  };

  const apply = () => {
    userContext.setUserContext({
      ...userContext,
      ...{
        applicationId: 0,
        studentId: parseInt(id),
        studentName: `${student.firstName} ${student.lastName}`,
      },
    });
    navigate(`/programs/${id}`);
  };

  const createStudentLogin = () => {
    Agent.Account.createStudentLogin(student.email)
      .then((res) => {
        setEmail(student.email);
        setEmailRes(res);

        if (res) {
          setResponseMessage(res);
          setOpenConfirmLoginCreate(true);
        } else {
          setErrorMessage('An error occurred.');
          setErrorAlertOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error);
        setErrorAlertOpen(true);
      });
  };

  // function for creating crm student login mobile app user
  const createMobileAppLogin = () => {
    Agent.Account.createStudentLoginForMobileApp(id)
      .then((res) => {
        setEmail(student.email);
        setEmailRes(res);

        if (res) {
          setResponseMessage(res);
          setOpenConfirmMobileLoginCreate(false);
        } else {
          setErrorMessage('An error occurred.');
          setErrorAlertOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error);
        setErrorAlertOpen(true);
      });
  };

  const handleSnackbarClose = () => {
    setErrorAlertOpen(false);
  };

  const disableStudent = () => {
    setUpdating(true);
    Agent.Students.disableStudent(parseInt(id))
      .then(() => {
        setUpdating(false);
        navigate('/students');
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);

        debugger;
      });
  };
  const size = useWindowSize();
  return loading ? (
    <LoadingView />
  ) : size.width > 844 ? (
    <Root>
      <Grid container>
        <Grid item xs={8} container direction="column">
          <Grid item container spacing={5}>
            <Grid item xs={5}>
              <Card
                className={classes.root}
                style={{ minHeight: 150 }}
                elevation={10}
              >
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ float: 'left', paddingRight: 10 }}
                    >
                      Profile
                    </Typography>

                    {student.profileComplete && (
                      <Tooltip
                        id="tooltip-top"
                        title="Profile Complete"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <CheckIcon color="success" />
                      </Tooltip>
                    )}
                    <Typography color="textSecondary" style={{ clear: 'both' }}>
                      {`${student.firstName} ${student.lastName}`}
                    </Typography>
                  </CardContent>
                  <div className={classes.controls}>
                    <Button
                      className="bg-sky-400 hover:bg-sky-500 text-white"
                      variant="contained"
                      onClick={() => {
                        navigate(`/students/${id}/manage`);
                      }}
                    >
                      Manage Profile
                    </Button>
                  </div>
                </div>
                <CardMedia
                  className={classes.cover}
                  image={
                    studentPhoto && studentPhoto.fileUrl
                      ? studentPhoto.fileUrl
                      : user
                  }
                  title="Profile Photo"
                />
              </Card>
            </Grid>
            {student.profileComplete &&
              (isInternal || student.applications.length < 3) && (
                <Grid item xs={5}>
                  <Card className={classes.root} elevation={10}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                          {student.applications.length == 0
                            ? 'No Application Found'
                            : `No of Applications: ${student.applications.length}`}
                        </Typography>
                      </CardContent>
                      {student.applications.length <= 3 ? (
                        <div className={classes.controls}>
                          <Button
                            className="bg-sky-400 hover:bg-sky-500 text-white"
                            variant="contained"
                            onClick={apply}
                          >
                            {student.applications.length > 0
                              ? 'Apply for Another'
                              : 'Apply Now'}
                          </Button>
                        </div>
                      ) : role.isSuperAdmin ? (
                        <div className={classes.controls}>
                          <Button
                            className="bg-sky-400 hover:bg-sky-500 text-white"
                            variant="contained"
                            onClick={apply}
                          >
                            {student.applications.length > 0
                              ? 'Apply for Another'
                              : 'Apply Now'}
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <SchoolIcon
                      className="text-sky-400/70"
                      style={{ fontSize: 150 }}
                      color="primary"
                    />
                  </Card>
                </Grid>
              )}
          </Grid>
          <Grid
            container
            columnSpacing={5}
            rowSpacing={3}
            style={{ marginTop: 20 }}
          >
            {student.applications.length > 0 ? (
              student.applications.map((app) => (
                <Grid item xs={5} key={app.id}>
                  <Card className={classes.root} elevation={10}>
                    <div className={classes.details} style={{ width: '100%' }}>
                      <CardContent className={classes.content}>
                        <Grid item container direction="column">
                          <Grid item>
                            <Typography component="h5" variant="h5">
                              {app.universityName}
                            </Typography>
                            <Typography color="textSecondary">
                              {app.courseName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            style={{ marginTop: 10 }}
                            justifyContent="space-between"
                          >
                            <Grid item xs={8} container direction="column">
                              <Grid item>
                                <Typography
                                  color={
                                    app.status == 3 ? 'green' : 'secondary'
                                  }
                                >
                                  {
                                    APPLICATION_STATUSES.find(
                                      ({ id }) => id == app.status
                                    ).type
                                  }
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    updateTimeline(
                                      app.appliedOn,
                                      app.status,
                                      app.updatedOn,
                                      true
                                    );
                                  }}
                                >
                                  Show Timeline
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                className="bg-sky-400 hover:bg-sky-500"
                                variant="contained"
                                onClick={() => {
                                  navigate(
                                    `/students/${id}/applications/${app.id}`
                                  );
                                }}
                              >
                                View Application
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="h6">
                  You do not currently have any active applications
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          container
          direction="column"
          spacing={3}
          alignItems="center"
        >
          <Grid item>
            <Typography align="center" color="primary" variant="h6">
              Student Timeline
            </Typography>
            <Timeline>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="h6" color="textSecondary">
                    {format(new Date(student.createdOn), 'MMMM d, yyyy')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>
                    <AccountCircleIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      Profile Created
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="h6" color="textSecondary">
                    {student.profileComplete
                      ? format(
                          new Date(student.profileCompletedOn),
                          'MMMM d, yyyy'
                        )
                      : 'Not Completed Yet'}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <AccountCircleIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      Profile Completed
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="h6" color="textSecondary">
                    {timeline.appliedOn}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined">
                    <SchoolIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.timelineConnector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      Application Made
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="h6" color="textSecondary">
                    {timeline.updatedOn}
                  </Typography>
                  <Typography color="textSecondary">
                    {timeline.status}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary">
                    <DescriptionIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      Current Status
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
          {(role.isSuperAdmin || permissions.CanDisableStudent) && (
            <Grid item>
              <LoadingButton
                loading={updating}
                onClick={() => setOpenConfirm(true)}
                variant="contained"
                className="bg-rose-400 hover:bg-rose-500 text-white"
              >
                Disable Student
              </LoadingButton>
            </Grid>
          )}
        </Grid>

        {!role.isAgent &&
          student.agentId == null &&
          !student.isLoginCreated &&
          student.email != null && (
            <Grid item style={{ margin: 40 }}>
              {' '}
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setOpenConfirmLoginCreate(true)}
              >
                Create Student Login
              </Button>
            </Grid>
          )}

        {!role.isAgent && student.agentId == null && (
          <Grid item style={{ margin: 40 }}>
            {' '}
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => addRecord({ id: 0 })}
            >
              Create New Appointment
            </Button>
          </Grid>
        )}

        {!role.isAgent &&
          student.agentId == null &&
          student.firstAppLogin == null &&
          student.email != null && (
            <Grid item style={{ margin: 40 }}>
              {' '}
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => setOpenConfirmMobileLoginCreate(true)}
              >
                Send App Login Invitation
              </Button>
            </Grid>
          )}
      </Grid>

      <ConfirmationModal
        open={openConfirmLoginCreate}
        cancel={setOpenConfirmLoginCreate}
        confirm={createStudentLogin}
        confirmText="Confirm Action"
        content="Are you sure that you would like to create student login send email to below mail ID?"
        email={student.email}
      />

      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={disableStudent}
        confirmText="Confirm Action"
        content="Are you sure that you would like to disable this student record?"
      />

      <ConfirmationModal
        open={openConfirmMobileLoginCreate}
        cancel={setOpenConfirmMobileLoginCreate}
        confirm={createMobileAppLogin}
        confirmText="Confirm Action"
        content="Are you sure that you would like to send an app login invitation to this student?"
        email={student.email}
      />

      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          className="alert alert-error m-80"
        >
          <span>{errorMessage}</span>
        </Alert>
      </Snackbar>

      <Snackbar
        open={responseMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setResponseMessage('')}
      >
        <Alert
          onClose={() => setResponseMessage('')}
          severity="success"
          className="alert alert-success m-80"
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      {addMode && (
        <Appointment
          record={appointment}
          setRecordParent={handleAppointmentFinish}
          setAddMode={setAddMode}
          id={0}
        />
      )}
    </Root>
  ) : (
    <Root>
      <Grid item container direction="column" spacing={5}>
        <Grid item xs={5}>
          <Card
            className={classes.root}
            style={{ minHeight: 150 }}
            elevation={10}
          >
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ float: 'left', paddingRight: 10 }}
                >
                  Profile
                </Typography>

                {student.profileComplete && (
                  <Tooltip
                    id="tooltip-top"
                    title="Profile Complete"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <CheckIcon color="success" />
                  </Tooltip>
                )}
                <Typography color="textSecondary" style={{ clear: 'both' }}>
                  {`${student.firstName} ${student.lastName}`}
                </Typography>
              </CardContent>
              <div className={classes.controls}>
                <Button
                  className="bg-sky-400 hover:bg-sky-500 text-white"
                  variant="contained"
                  onClick={() => {
                    navigate(`/students/${id}/manage`);
                  }}
                >
                  Manage Profile
                </Button>
              </div>
            </div>
            <CardMedia
              className={classes.cover}
              image={
                studentPhoto && studentPhoto.fileUrl
                  ? studentPhoto.fileUrl
                  : user
              }
              title="Profile Photo"
            />
          </Card>
        </Grid>
        {student.profileComplete &&
          (isInternal || student.applications.length < 3) && (
            <Grid item xs={5}>
              <Card className={classes.root} elevation={10}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                      {student.applications.length == 0
                        ? 'No Application Found'
                        : `No of Applications: ${student.applications.length}`}
                    </Typography>
                  </CardContent>
                  {student.applications.length <= 3 && (
                    <div className={classes.controls}>
                      <Button
                        className="bg-sky-400 hover:bg-sky-500 text-white"
                        variant="contained"
                        onClick={apply}
                      >
                        {student.applications.length > 0
                          ? 'Apply for Another'
                          : 'Apply Now'}
                      </Button>
                    </div>
                  )}
                </div>
                <SchoolIcon
                  className="text-sky-400/70"
                  style={{ fontSize: 150 }}
                  color="primary"
                />
              </Card>
            </Grid>
          )}
      </Grid>
      <Grid
        container
        columnSpacing={5}
        rowSpacing={3}
        direction="column"
        style={{ marginTop: 20 }}
      >
        {student.applications.length > 0 ? (
          student.applications.map((app) => (
            <Grid item xs={5} key={app.id}>
              <Card className={classes.root} elevation={10}>
                <div className={classes.details} style={{ width: '100%' }}>
                  <CardContent className={classes.content}>
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography component="h5" variant="h5">
                          {app.universityName}
                        </Typography>
                        <Typography color="textSecondary">
                          {app.courseName}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        style={{ marginTop: 10 }}
                        justifyContent="space-between"
                      >
                        <Grid item xs={8} container direction="column">
                          <Grid item>
                            <Typography
                              color={app.status == 3 ? 'green' : 'secondary'}
                            >
                              {
                                APPLICATION_STATUSES.find(
                                  ({ id }) => id == app.status
                                ).type
                              }
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                updateTimeline(
                                  app.appliedOn,
                                  app.status,
                                  app.updatedOn,
                                  true
                                );
                              }}
                            >
                              Show Timeline
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            className="bg-sky-400 hover:bg-sky-500"
                            variant="contained"
                            onClick={() => {
                              navigate(
                                `/students/${id}/applications/${app.id}`
                              );
                            }}
                          >
                            View Application
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography variant="h6">
              You do not currently have any active applications
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={4}
        container
        direction="column"
        spacing={3}
        alignItems="center"
      >
        <Grid item>
          <Typography align="center" color="primary" variant="h6">
            Student Timeline
          </Typography>
          <Timeline>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" color="textSecondary">
                  {format(new Date(student.createdOn), 'MMMM d, yyyy')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <AccountCircleIcon />
                </TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Profile Created
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" color="textSecondary">
                  {student.profileComplete
                    ? format(
                        new Date(student.profileCompletedOn),
                        'MMMM d, yyyy'
                      )
                    : 'Not Completed Yet'}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <AccountCircleIcon />
                </TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Profile Completed
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" color="textSecondary">
                  {timeline.appliedOn}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined">
                  <SchoolIcon />
                </TimelineDot>
                <TimelineConnector className={classes.timelineConnector} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Application Made
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" color="textSecondary">
                  {timeline.updatedOn}
                </Typography>
                <Typography color="textSecondary">{timeline.status}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary">
                  <DescriptionIcon />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Current Status
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        {(role.isSuperAdmin || permissions.CanDisableStudent) && (
          <Grid item>
            <LoadingButton
              loading={updating}
              onClick={() => setOpenConfirm(true)}
              variant="contained"
              className="bg-rose-400 hover:bg-rose-500 text-white"
            >
              Disable Student
            </LoadingButton>
          </Grid>
        )}
      </Grid>

      {!role.isAgent &&
        student.agentId == null &&
        !student.isLoginCreated &&
        student.email != null && (
          <Grid item style={{ margin: 40 }}>
            {' '}
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setOpenConfirmLoginCreate(true)}
            >
              Create Student Login
            </Button>
          </Grid>
        )}

      {!role.isAgent && student.agentId == null && (
        <Grid item style={{ margin: 40 }}>
          {' '}
          <Button
            color="secondary"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => addRecord({ id: 0 })}
          >
            Create New Appointment
          </Button>
        </Grid>
      )}

      {!role.isAgent &&
        student.agentId == null &&
        student.firstAppLogin == null &&
        student.email != null && (
          <Grid item style={{ margin: 40 }}>
            {' '}
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => setOpenConfirmMobileLoginCreate(true)}
            >
              Send App Login Invitation
            </Button>
          </Grid>
        )}
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
