/* eslint-disable react/jsx-key */
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Agent from '../../../api/agent';
import { INTAKES, intakeYearList, STUDENTSOURCES } from '../../../Util/Constants';
import { TextField, Select, MenuItem, InputLabel, FormControl, Autocomplete, Chip, CircularProgress, Paper, Grid, Typography } from '@mui/material';
import { Countries } from '../../../Util/Util';
import { motion } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorPopup from '../RegionalReport/ErrorPopUp';
import { connect } from 'react-redux';

const months = [
  'jan', 'feb', 'mar', 'apr', 'may', 'jun',
  'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
];

const leadSources = STUDENTSOURCES;

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <motion.div
      className="w-24 h-24 flex items-center justify-center"
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      }}
    >
      <motion.span
        className="block w-12 h-12 border-t-4 border-blue-500 rounded-full"
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: Infinity,
        }}
      />
    </motion.div>
  </div>
);

const SourcePerformanceReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [year, setYear] = useState('');
  const [citizenship, setCitizenship] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [leadType, setLeadType] = useState('2');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const hasAccess = permissions.CanAccessGlobalLeadSourcePerformanceReportB2C;

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await Agent.Cache.getBranchOffices();
          if (Array.isArray(response)) {
            setBranches(response.map(branch => ({ id: branch.id.toString(), label: branch.name })));
          } else {
            console.error('Unexpected response format for branch offices:', response);
          }
        } catch (err) {
          console.error('Error fetching branch offices:', err);
          setError('Failed to fetch branch offices. Please try again later.');
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [hasAccess]);

  const fetchData = useCallback(async () => {
    if (!hasAccess) return;
    
    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.leadsBySource({
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        branches: selectedBranches.map(branchId => parseInt(branchId, 10)),
        year: year ? [year] : [],
        citizenship,
        type: parseInt(leadType, 10)
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
      } else {
        setError('No data available for the selected criteria.');
        setShowErrorPopup(true);
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch data. Please try again.');
      setShowErrorPopup(true);
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [hasAccess, intakeYears, intakeMonths, startDate, endDate, selectedBranches, year, citizenship, leadType]);

  const handleGenerateReport = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setSelectedBranches([]);
    setYear('');
    setCitizenship([]);
    setLeadType('2');
    setData([]);
    setError(null);
    setShowErrorPopup(false);
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setError(null);
  };

  const calculateTotal = (source) => {
    return months.reduce((sum, month) => sum + (source[month] || 0), 0);
  };

  const formatValue = (value) => {
    return value > 0 ? value.toString() : '';
  };


  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Source Performance Report." />
      </Paper>
    );
  }

  if (loading) {
    return (
      <Paper elevation={3} className="mt-6 p-4">
        <Typography variant="body1" className="mb-2 text-center">Generating report...</Typography>
        <LoadingAnimation />
      </Paper>
    );
  } 

  if (error) {
    return (
      <Paper elevation={3} className="mt-6 p-4">
        {showErrorPopup && <ErrorPopup error={error} onClose={handleCloseErrorPopup} />}
      </Paper>
    );
  }

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
      <div className="flex justify-between items-center mb-6">
        <Typography variant="h5" component="h2" className="text-gray-800 font-bold">
          Source Performance Analysis - Global and Regional
        </Typography>
        <div className="flex space-x-2">
          <Link
            to="/reportpanel"
            className="flex items-center px-3 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowBackIcon className="w-5 h-5 mr-1" />
            Back to Reporting Panel
          </Link>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FilterListIcon className="w-5 h-5 mr-1" />
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>
      </div>
      {showFilters && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
                <Select
                  labelId="lead-type-select-label"
                  id="lead-type-select"
                  value={leadType}
                  label="Lead Type"
                  onChange={(e) => setLeadType(e.target.value)}
                >
                  <MenuItem value="1">Paid Leads</MenuItem>
                  <MenuItem value="2">Organic Leads</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="intake-years"
                options={intakeYearList.map(year => ({ id: year.year.toString(), label: year.year.toString() }))}
                value={intakeYears.map(id => ({ id, label: id }))}
                onChange={(_, newValue) => setIntakeYears(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Intake Years" placeholder="Select intake years" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="intake-months"
                options={[{ id: '', label: 'All Months' }, ...INTAKES.map(month => ({ id: month.id.toString(), label: month.type }))]}
                value={intakeMonths.map(id => ({ id, label: INTAKES.find(m => m.id.toString() === id)?.type || id }))}
                onChange={(_, newValue) => setIntakeMonths(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Intake Months" placeholder="Select intake months" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries.map(country => ({ id: country.id.toString(), label: country.name }))}
                value={citizenship.map(id => ({ id, label: Countries.find(c => c.id.toString() === id)?.name || id }))}
                onChange={(_, newValue) => setCitizenship(newValue.map(v => v.id))}
                renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenship" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="branches"
                options={branches}
                loading={loadingBranches}
                value={selectedBranches.map(id => branches.find(b => b.id === id) || { id, label: id })}
                onChange={(_, newValue) => setSelectedBranches(newValue.map(v => v.id))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Branches"
                    placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
          </Grid>

          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset Filters
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {loading ? 'Generating...' : 'Generate Report'}
            </button>
          </div>
        </>
      )}

      {data.length > 0 && (
        <Paper elevation={3} className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg">
          <div className="overflow-x-auto">
            <table className="w-full text-xs border-collapse">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40" rowSpan={2}>Lead Source</th>
                  {months.map((month) => (
                    <th key={month} className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200" colSpan={2}>{month.toUpperCase()}</th>
                  ))}
                  <th className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-amber-200" colSpan={2}>Total</th>
                </tr>
                <tr>
                  {months.map((month) => (
                    <React.Fragment key={`${month}-header`}>
                      <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">Target</th>
                      <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">Achieve</th>
                    </React.Fragment>
                  ))}
                  <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">Target</th>
                  <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">Achieve</th>
                </tr>
              </thead>
              <tbody>
                {data.map((source, index) => (
                  <tr key={source.source} className={index % 2 === 0 ? 'bg-white hover:bg-sky-50' : 'bg-gray-50 hover:bg-sky-100'}>
                    <td className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300" style={{backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F9FAFB'}}>
                      {source.source.trim()}
                    </td>
                    {months.map((month) => (
                      <React.Fragment key={`${source.source}-${month}`}>
                        <td className="py-1 px-1 text-center border-r border-gray-300">
                          <div className="font-medium text-red-600 text-xs"></div>
                        </td>
                        <td className="py-1 px-1 text-center border-r border-gray-300">
                          <div className="font-medium text-green-600 text-xs">{formatValue(source[month])}</div>
                        </td>
                      </React.Fragment>
                    ))}
                    <td className="py-1 px-1 text-center border-r border-gray-300">
                      <div className="font-medium text-red-600 text-xs">--</div>
                    </td>
                    <td className="py-1 px-1 text-center border-r border-gray-300">
                      <div className="font-medium text-green-600 text-xs">{formatValue(calculateTotal(source))}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Paper>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(SourcePerformanceReport);

