import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AllBranchesSourceReport from './AllBranchesSourceReport';
import { Countries } from '../../../Util/Util';

const CountrySourceReport = ({ country, branches, getFlagUrl }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('branches');
  const countryInfo = Countries.find(c => c.name === country);
  //const countryBranches = branches.filter(branch => branch.countryId === countryInfo?.id);

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 flex items-center gap-3 mb-4 md:mb-0">
          {countryInfo && (
            <img 
              src={getFlagUrl(countryInfo.code)} 
              alt={`${country} flag`}
              className="w-8 h-6 md:w-8 md:h-6 object-cover rounded-sm shadow-sm"
            />
          )}
          {country.toUpperCase()} Source Report
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab('branches')}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === 'branches'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Branches
          </button>
          <button
            onClick={() => setActiveTab('allBranches')}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === 'allBranches'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            All Branches Source Report
          </button>
        </div>
      </div>
      
      {activeTab === 'branches' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {branches.map((branch) => (
            <div key={branch.id} className="bg-gray-50 rounded-lg p-4 shadow transition-all duration-200 hover:shadow-lg">
              <h3 className="text-lg font-medium mb-2 text-gray-700">{branch.name}</h3>
              <p className="text-sm text-gray-500 mb-4">{branch.branchEmail || 'No email available'}</p>
              <button 
                onClick={() => navigate(`/branchsourcereport/${branch.id}`)}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300"
              >
                View Source Report
              </button>
            </div>
          ))}
        </div>
      )}
      
      {activeTab === 'allBranches' && (
        <AllBranchesSourceReport country={country} branches={branches} />
      )}
    </div>
  );
};

export default CountrySourceReport;

