import React, { useState, useEffect } from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import * as actions from '../../../store/actions/index';
import {
  MUI_LICENSE_KEY,
  STUDENTSOURCES,
  LEADORIGINS,
  LEADCHANNELS,
  GENDER,
  StudentLead_Activities,
  STUDY_LEVELS,
  COMPLIANCE_STATUS,
  STUDY_LEVEL_GROUPS,
} from '../../../Util/Constants';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import EditIcon from '@mui/icons-material/Edit';
import LoadingView from '../../Common/Loading/LoadingView';
import Agent from '../../../api/agent';
import { connect, useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay, addHours, addMonths } from 'date-fns';
import { Countries } from '../../../Util/Util';
import { StudentLead_Status } from './../../../Util/Constants';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { StudentLeadAssignment } from './StudentLeadAssign';
import { intakeYearList } from '../../Report/MockData/MockData';
import Description from '@mui/icons-material/Description';
import LeadNotes from './LeadNotes';
import PendingOutlined  from '@mui/icons-material/PendingOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {show && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const StudentLead = ({
  permissions,
  users,
  role,
  loadUsers,
  agents,
  loadAgents,
  userName,
  isInternal,
  agentId = 0,
}) => {
  const dispatch = useDispatch();
  console.log(users)
  const eventUrlId = useParams();
  const [leadStatus, setLeadStatus] = useState(0);
  const [leadactivity, setLeadActivity] = useState(0);
  const [leadOrigin, setLeadOrigin] = useState(0);
  const [leadSource, setLeadSource] = useState(0);
  const [leadChannel, setLeadChannel] = useState(0);
  const navigate = useNavigate();
  const [studentLoaded, setStudentLoaded] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [start, setStart] = useState(
    eventUrlId.id ? null : addMonths(new Date(), -1)
  );
  const [end, setEnd] = useState(new Date());
  const [dateType, setDateType] = useState(1);
  const [students, setStudents] = useState([]);
  const [studentLead, setStudentLead] = useState();
  const [bulkUpdate, setBulkUpdate] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 500;
  const [passportNumber, setPassportNumber] = useState('');
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const [adminAssignMode, setAdminAssignMode] = useState(false);
  const [agentAssignMode, setAgentAssignMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [ready, setReady] = useState(false);
  const [offices, setOffices] = useState([]);
  const [branchOfficeId, setBranchOfficeId] = useState(0);
  const [events, setEventList] = useState([]);
  const [eventId, setEventId] = useState(eventUrlId.id ? eventUrlId.id : 0);
  const [citizenship, setCitizenship] = useState(0);
  const [TeamLeader, setTeamLeader] = useState(0);
  const [assessmentTeam, setAssessmentTeam] = useState(0);
  const [followupTeamId, setFollowUpTeam] = useState(0);
  const [intake, setIntake] = useState(0);
  const [studyLevel, setStudyLevel] = useState(0);
  const [studyLevelFilter, setStudyLevelFilter] = useState('');
  const [msgName, setMsgName] = useState();
  const [leadId, setLeadId] = useState();
  const [openAddNote, setOpenAddNote] = useState(false);
  const [referralList, setReferralList] = useState([{}]);
  const [filteredTeamLeaders, setFilteredTeamLeaders] = useState([]);
  const [complianceStatus, setComplianceStatus] = useState(0);
  const [referralRelaod, setReferralReload] = useState(false);
  const [leadType, setLeadType] = useState(0);
  const [referralAssignId, setReferralAssignId] = useState(0);
  const [eventAttendee, setEventAttendee] = useState(0);
  const [
    filteredFollowAndAssessmentTeams,
    setFilteredFollowAndAssessmentTeams,
  ] = useState([]);

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);
  //console.log('userName',userName);
  useEffect(() => {
    Agent.Events.getEventList().then((res) => {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setEventList(res);
    });
  }, []);

  useEffect(() => {
    Agent.Referral.List().then((res) => {
      setReferralList(res);
      console.log('referralList', referralList);
    });
  }, []);

  const handleAddLead = () => {
    navigate('/addleadform');
  };


  


  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? name.trim() : '');
    params.append('email', filter ? email.trim() : '');
    params.append('phoneNumber', filter ? phone.trim() : '');
    if (agentId > 0) {
      params.append('agentId', agentId.toString());
    }
    if (dateOfBirth && filter) {
      params.append('dateOfBirth', new Date(dateOfBirth).toDateString());
    }
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    params.append('dateType', dateType);
    if (filter) {
      params.append('branchOfficeId', branchOfficeId);
    }
    if (filter) {
      params.append('eventId', eventId);
    }
    if (filter) {
      params.append('LeadType', leadType);
    }
    if (filter) {
      params.append('ReferralId', referralAssignId);
    }

    if (filter) {
      params.append('ComplianceStatus', complianceStatus);
    }
    if (filter) {
      params.append('Status', leadStatus);
    }
    if (filter) {
      params.append('LeadActivity', leadactivity);
    }
    if (filter) {
      params.append('Origin', leadOrigin);
    }
    if (filter) {
      params.append('Channel', leadChannel);
    }
    if (filter) {
      params.append('Source', leadSource);
    }
    if (filter) {
      params.append('citizenship', citizenship);
    }
    if (filter) {
      params.append('TeamLeader', TeamLeader);
    }
    if (filter) {
      params.append('AssessmentTeamId', assessmentTeam);
    }
    if (filter) {
      params.append('isEventAttendance', eventAttendee);
    }
    if (filter) {
      params.append('PassportNumber', passportNumber);
    }
    if (filter) {
      params.append('FollowupTeamId', followupTeamId);
    }
    if (filter) {
      params.append('Intake', intake);
    }
    if (filter) {
      params.append('PreferenceStudyLevelId', studyLevelFilter);
    }
    return params;
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };
  useEffect(() => {
    // Filter the users when the component mounts or when the users prop changes
    const filteredTeamLeaders = users.filter(
      (user) =>
        !user.isDisabled &&
        (user.role === 'ApplicationTeamLeader' ||
          user.role === 'SuperAdmin' ||
          user.role === 'SeniorAdmin' ||
          user.role === 'Admin')
    );
    const filteredFollowAndAssessmentTeams = users.filter(
      (user) => !user.isDisabled && [1, 2, 3].includes(user.roleId)
    );

    // Update the local state with the filtered users
    setFilteredTeamLeaders(filteredTeamLeaders);
    setFilteredFollowAndAssessmentTeams(filteredFollowAndAssessmentTeams);
  }, [users]);

  const getRows = (rows) => {
    console.log('rows', rows);
    let studentRows = [];
    rows.map((studentLead) => {
      studentRows.push({
        id: studentLead.id,
        name: studentLead.firstName + ' ' + studentLead.lastName,

        email: studentLead.email,
        citizenship:
          studentLead.citizenship && studentLead.citizenship > 0
            ? Countries.find(({ id }) => id === studentLead.citizenship).name
            : '',
        residence:
          studentLead.countryOfResidence && studentLead.countryOfResidence > 0
            ? Countries.find(({ id }) => id === studentLead.countryOfResidence)?.name
            : '',
        applicationCount:
          studentLead.noOfApplications == 0 ? '' : studentLead.noOfApplications,
        appUserDetailsId: studentLead.appUserDetailsId,
        assessmentTeamId: studentLead.assessmentTeamId,
        complianceStatus: studentLead.complianceStatus
          ? studentLead.complianceStatus
          : 0,
        complianceStatusName:
          studentLead.complianceStatus && studentLead.complianceStatus > 0
            ? COMPLIANCE_STATUS.find(
                ({ id }) => id === studentLead.complianceStatus
              )?.type
            : null,
        assessmentTeam:
          studentLead.assessmentTeamId && users.length > 0
            ? users.find(({ id }) => id === studentLead.assessmentTeamId)?.name
            : '',
        followupTeamId: studentLead.followupTeamId,
        followUpTeam:
          studentLead.followupTeamId && users.length > 0
            ? users.find(({ id }) => id === studentLead.followupTeamId)?.name
            : '',
        complianceTeamId: studentLead.complianceTeamId,
        complianceTeam:
          studentLead.complianceTeamId && users.length > 0
            ? users.find(({ id }) => id === studentLead.complianceTeamId)?.name
            : '',
        admin: studentLead.adminName,
        agentId: studentLead.agentId,
        agent: studentLead.agentCompanyName,
        bdm:
          studentLead.businessDevAppUserDetailsId && users.length > 0
            ? users.find(
                ({ id }) => id === studentLead.businessDevAppUserDetailsId
              )?.name
            : '',
        TeamLeader:
          studentLead.appUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === studentLead.appUserDetailsId)?.name
            : '',
        profile: studentLead.profileComplete,
        leadprofile: studentLead.profileComplete,
        leadstatus: studentLead.status,
        leadactivity: studentLead.leadActivity,
        isConverted: studentLead.isConverted,
        branchOfficeId: studentLead.branchOfficeId,
        branch: studentLead.branchOfficeName,
        // citizenship: studentLead.citizenship,
        eventId: studentLead.eventId,
        referralId: studentLead.referralId,
        referralName: studentLead.referralName,
        eventName: studentLead.eventName,
        isEventAttendance: studentLead.isEventAddendence,
        sourceId: studentLead.source,
        Intake: studentLead.preferenceStudyYear,
        preferenceStudyLevel:
          studentLead.preferenceStudyLevelId &&
          studentLead.preferenceStudyLevelId > 0
            ? STUDY_LEVEL_GROUPS.find(
                ({ id }) => id == studentLead.preferenceStudyLevelId
              )?.type
            : '',
        source:
          studentLead.source && studentLead.source > 0
            ? STUDENTSOURCES.find(({ id }) => id == studentLead.source)?.type
            : '',
        originId: studentLead.origin,
        origin:
          studentLead.origin > 0
            ? LEADORIGINS.find(({ id }) => id == studentLead.origin)?.type
            : '',
        channelId: studentLead.channel,
        channel:
          studentLead.channel > 0
            ? LEADCHANNELS.find(({ id }) => id == studentLead.channel)?.type
            : '',
        campaign: studentLead.campaign,
        passport: studentLead.passportNumber,
        gender:
          studentLead.gender > 0
            ? GENDER.find(({ id }) => id == studentLead.gender)?.type
            : '',
        phone: studentLead.phoneNumber,
        dateOfBirth:
          studentLead.dateOfBirth == null
            ? ''
            : new Date(studentLead.dateOfBirth).toISOString().split('T')[0],
        regionalManagerId: studentLead.regionalManagerId,
        regionalManager:
          studentLead.regionalManagerId && users.length > 0
            ? users.find(({ id }) => id === studentLead.regionalManagerId)?.name
            : '',
        countryManagerId: studentLead.countryManagerId,
        countryManager:
          studentLead.countryManagerId && users.length > 0
            ? users.find(({ id }) => id === studentLead.countryManagerId)?.name
            : '',
        branchManagerId: studentLead.branchManagerId,
        branchManager:
          studentLead.branchManagerId && users.length > 0
            ? users.find(({ id }) => id === studentLead.branchManagerId)?.name
            : '',
        virtualTeamId: studentLead.virtualTeamId,

        virtualTeam:
          studentLead.virtualTeamId && users.length > 0
            ? users.find(({ id }) => id === studentLead.virtualTeamId)?.name
            : '',
        isLogin: studentLead.isLoginCreated,
        updatedOn:
          studentLead.updatedOn == null ||
          studentLead.updatedOn === '0001-01-01T00:00:00'
            ? ''
            : new Date(studentLead.updatedOn).toISOString().split('T')[0],

        convertedDate:
          studentLead.convertedDate == null
            ? ''
            : new Date(studentLead.convertedDate).toISOString().split('T')[0],
        createdOn:
          studentLead.createdOn == null
            ? ''
            : new Date(studentLead.createdOn).toISOString().split('T')[0],
        statusupdatedon:
          studentLead.statusUpdatedOn == null
            ? ''
            : new Date(studentLead.statusUpdatedOn).toISOString().split('T')[0],
      });
    });
    // console.log('studentRows', studentRows);
    setStudents(studentRows);
    setStudentLoaded(true);
    setLoading(false);
  };
  const handleNote = (row) => {
    console.log('row.id', row.id);
    setOpenAddNote(true);
    setLeadId(row.id);
    setMsgName(row.firstName + ' ' + row.lastName);
  };
  // useEffect(() => {
  //   if (isInternal) {
  //     if (users.length == 0) {
  //       loadUsers()
  //         .then(() => {
  //           //  getData();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           //debugger;
  //         });
  //     } else {
  //       getData(true);
  //     }
  //   } else {
  //     getData(true);
  //   }
  // }, [users, page, referralRelaod]);


  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        dispatch(actions.loadUsers())
          .then(() => {
            getData(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [dispatch, isInternal, users, page, referralRelaod]);



  const LeadIcons = {
    id: '🔒',
    name: '👩🏻‍🎓',
    residence: '🏠',
    citizenship: '🌍',
    branch: '🏬',
    preferenceStudyLevel: '📋',
    leadstatus: '🔰',
    leadactivity: '📅',
    leadprofile: '✅',
    admin: '👨🏾‍💼',
    assessmentTeam: '📋',
    followUpTeam: '🕵️',
    profile: '📄',
    campaign: ' 📈',
    dateOfBirth: '🎂',
    createdOn: '🕒',
    passport: '✈️',
    gender: '⚧️',
    bdm: '👨‍💼',
    source: '📥',
    Intake: '📆',
    origin: '🌐',
    channel: '📢',
    complianceTeam: '🛡️',
    countryManager: '🌍👩‍💼',
    branchManager: '🏬👩‍💼',
    regionalManager: '🗺️👩‍💼',
    virtualTeam: '🌍👩‍🎓',
    isEventAttendance: '📅✅',
    eventName: '🎉',
    statusupdatedon: '🕒',
    updatedOn: '🕒',
    convertedDate: '📅',
    isConverted: '✅',
    edit: '✏️',
  };

  let studentColumns = [
    {
      field: 'id',
      renderHeader: () => (
        <strong>
          {LeadIcons['id']} {'id'}
        </strong>
      ),
      width: 80,
      hide: true,
    },

    {
      field: 'name',
      align: 'left',
      headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['name']} {'Full Name'}
        </strong>
      ),

      renderCell: (params) => (
        <MyTooltip title={params.row.name} color="primary">
          <Typography
          // className={`clickable ${
          //   params.row.leadstatus === 5 ? 'cursor-not-allowed' : 'cursor-pointer'
          // }`}
          >
            {params.row.name}
          </Typography>
        </MyTooltip>
      ),
    },

    {
      field: 'residence',
      headerName: 'Residence',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['residence']} {'Residence'}
        </strong>
      ),
    },

    {
      field: 'citizenship',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['citizenship']} {'Citizenship'}
        </strong>
      ),
      hide: false,
    },

    {
      field: 'branch',
      headerName: 'Branch',
      hide: false,
      align: 'left',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['branch']} {'Branch'}
        </strong>
      ),
    },
    {
      field: 'preferenceStudyLevel',
      headerName: 'Preference Study Level',
      hide: true,
      align: 'center',
      headerAlign: 'center',
      width: 220,
      minWidth: 160,
      maxWidth: 350,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['preferenceStudyLevel']} {'Preference Study Level'}
        </strong>
      ),
    },
    
    {
      field: 'complianceStatusName',
      align: 'center',
      headerAlign: 'center',
      width: 240,
      resizable: true,
      renderHeader: () => (
        <strong>
          {' '}
          {LeadIcons['leadstatus']} {'Compliance Status'}
        </strong>
      ),
      // valueGetter: (params) => {
      //   const statusId = params.row.complianceStatus;
      //   const status = COMPLIANCE_STATUS.find(
      //     (complianceStatus) => complianceStatus.id == statusId
      //   );
      //   return status ? status.type : null;
      // },
    },
    {
      field: 'leadstatus',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {' '}
          {LeadIcons['leadstatus']} {'Lead Status'}
        </strong>
      ),
      valueGetter: (params) => {
        const statusId = params.row.leadstatus;
        const status = StudentLead_Status.find(
          (leadStatus) => leadStatus.id == statusId
        );
        return status ? status.name : null;
      },
    },
    {
      field: 'leadactivity',
      hide: true,
      align: 'left',
      headerAlign: 'left',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {' '}
          {LeadIcons['leadactivity']} {'Lead Activity'}
        </strong>
      ),
      valueGetter: (params) => {
        const activityId = params.row.leadactivity;
        const activity = StudentLead_Activities.find(
          (activity) => activity.id == activityId
        );
        return activity ? activity.name : null;
      },
    },

    {
      field: 'leadprofile',
      hide: true,
      align: 'center',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Lead Profile'}</strong>,
      renderCell: (params) => (
        <strong>
          {params.row.leadprofile ? (
            <Tooltip title="Profile Complete" arrow>
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <Tooltip title="Profile Incomplete" arrow>
              <PendingActionsIcon color="warning" />
            </Tooltip>
          )}
        </strong>
      ),
    },

    {
      field: 'admin',
      align: 'left',
      headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['admin']}
          {'Team Leader'}
        </strong>
      ),
      hide: !isInternal,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.admin}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'assessmentTeam',
      hide: !isInternal,
      headerAlign: 'center',
      align: 'left',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['assessmentTeam']}
          {'Assessment'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.assessmentTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'followUpTeam',
      hide: false,
      align: 'left',
      headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['followUpTeam']}
          {' Follow Up Team'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.followUpTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'profile',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: isInternal ? 140 : 200,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'🔍 Profile Status'}</strong>,
      renderCell: (params) => (
        <strong>
          {params.row.profile ? (
            <MyTooltip
              icon={<HiCheckCircle color="success" />}
              title="Profile Complete"
            >
              <IconButton size="large">
                <HiCheckCircle color="#03C988" />
              </IconButton>
            </MyTooltip>
          ) : null}
        </strong>
      ),
    },

    {
      field: 'campaign',
      headerName: 'Campaign',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['campaign']}
          {'Campaign'}
        </strong>
      ),
    },

    {
      field: 'dateOfBirth',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['dateOfBirth']}
          {'Date Of Birth'}
        </strong>
      ),
    },
    {
      field: 'createdOn',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['createdOn']}
          {'Created On'}
        </strong>
      ),
    },

    {
      field: 'passport',
      hide: true,
      align: 'left',
      headerAlign: 'center',
      width: 120,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['passport']}
          {'Passport'}
        </strong>
      ),
    },
    {
      field: 'gender',
      align: 'center',
      headerAlign: 'center',
      width: 120,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['gender']}
          {' Gender'}
        </strong>
      ),
      hide: true,
    },
    {
      field: 'bdm',
      align: 'left',
      headerAlign: 'center',
      width: 200,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['bdm']}
          {' Business Dev Manager'}
        </strong>
      ),
      hide: true,
    },
    {
      field: 'referralName',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      hide: false,
      renderHeader: () => (
        <strong>
          {LeadIcons['bdm']}
          {'Referral Name'}
        </strong>
      ),
    },
    {
      field: 'source',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {' '}
          {LeadIcons['source']}
          {'Lead Source'}
        </strong>
      ),
      hide: false,
    },
    {
      field: 'Intake',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['Intake']}
          {'Intake Year'}
        </strong>
      ),
      hide: true,
    },
    {
      field: 'origin',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['origin']}
          {'Lead Origin'}
        </strong>
      ),
      hide: false,
    },
    {
      field: 'channel',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['channel']}
          {'Lead Channel'}
        </strong>
      ),
      hide: false,
    },
    {
      field: 'note',
      align: 'center',
      width: 120,
      hide: !isInternal,
      renderHeader: () => (
        <strong>
          {LeadIcons['Notes']}
          {'Notes (Internal)'}
        </strong>
      ),
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
    {
      field: 'complianceTeam',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['complianceTeam']}
          {'Compliance'}
        </strong>
      ),
      hide: true,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.complianceTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'countryManager',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['countryManager']}
          {'Country Manager'}
        </strong>
      ),
      hide: true,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.countryManager}</Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'branchManager',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      hide: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['branchManager']}
          {'Branch Manager'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.branchManager}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'regionalManager',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      hide: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['regionalManager']}
          {'Regional Manager'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.regionalManager}</Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'virtualTeam',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      hide: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['virtualTeam']}
          {'Uni. Representative'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.virtualTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'isEventAttendance',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      hide: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['isEventAttendance']}
          {'Event Attendance'}
        </strong>
      ),
      disabled: true,
      renderCell: (params) => (
        <Checkbox checked={params.row.isEventAttendance} />
      ),
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      align: 'left',
      headerAlign: 'center',
      width: 300,
      minWidth: 150,
      maxWidth: 500,
      resizable: true,
      hide: eventUrlId.id ? false : true,
      renderHeader: () => (
        <strong>
          {LeadIcons['eventName']}
          {'Event Name'}
        </strong>
      ),
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.eventName}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'statusupdatedon',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['statusupdatedon']}
          {'Status Updated Date'}
        </strong>
      ),
      hide: true,
    },
    {
      field: 'updatedOn',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['updatedOn']}
          {'Last Updated Date'}
        </strong>
      ),
      hide: false,
    },

    {
      field: 'isConverted',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['isConverted']}
          {'Lead Converted'}
        </strong>
      ),
      hide: false,
      renderCell: (params) => (
        <strong>
          {params.row.isConverted ? (
            <MyTooltip
              icon={<CheckCircleIcon className="bg-green-500" />}
              title="lead converted to student"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<PendingOutlined />} title="Not Converted">
              <IconButton size="large">
                <PendingOutlined className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },

    {
      field: 'convertedDate',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => (
        <strong>
          {LeadIcons['convertedDate']}
          {'Lead Converted Date'}
        </strong>
      ),
      hide: true,
    },

    {
      field: 'edit',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{LeadIcons['edit']} Edit</strong>,
      hide: !isInternal || bulkUpdate,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
   
  ];

  const getData = (filter = false) => {
    setLoading(true);
    setStudentLoaded(true)
    Agent.StudentLeads.getStudentLeads(getParams(filter))

      .then((leads) => {
        const { pagination, data } = leads;
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);

        console.log('response', data);
        getRows(data);
      })
      .catch((error) => {
        console.log(error);
        //debugger;
      });
  };
  const resetFilter = () => {
    setId('');
    setName('');
    setEmail('');
    setPhoneNumber('');
    setPassportNumber('');
    setLeadOrigin(0);
    setLeadSource(0);
    setLeadChannel(0);
    setLeadStatus(0);
    setLeadActivity(0);
    setCitizenship(0);
    setBranchOfficeId(0);
    setTeamLeader(0);
    setAssessmentTeam(0);
    setFollowUpTeam(0);
    setIntake(0);
    setEventId(0);
    setComplianceStatus(0);
    setDateOfBirth(null);
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    getData(false);
    setDateType(1);
    setReferralAssignId(0);
    setLeadType(0);
    setEventAttendee(0);
    setStudyLevelFilter('');
  };

  const actionSelectedUser = (record) => {
    const assignDto = {
      StudentLeadIds:
        selectionModel.length > 0 ? selectionModel : [studentLead.id],
      appUserDetailsId: record.appUserDetailsId,
      assessmentTeamId: record.assessmentTeamId,
      followupTeamId: record.followupTeamId,
      eventId: record.eventId,
      complianceTeamId: record.complianceTeamId,
      agentId: record.agentId,
      referralId: record.referralId,
      leadStatus: parseInt(record.status),
      branchOfficeId: record.branchOfficeId
        ? parseInt(record.branchOfficeId)
        : null,
      branchOfficeChanged: record.branchOfficeChanged,
      campaign: record.campaign,
      source: record.source,
      origin: record.origin,
      channel: record.channel,
      regionalManagerId: record.regionalManagerId,
      countryManagerId: record.countryManagerId,
      branchManagerId: record.branchManagerId,
      virtualTeamId: record.virtualTeamId,
      createdOn: record.createdOn,
    };
    //console.log('Assign DTO:', assignDto, record);

    Agent.StudentLeads.assign({ ...record, ...assignDto })
      .then(() => {
        // console.log('Assignment Successful');

        if (bulkUpdate) {
          setReady(false);
          return;
        }
        //lets update the grid now
        let temp = [];
        students.map((item) => {
          if (item.id == studentLead.id) {
            if (item.leadStatus != record.leadStatus) {
              item.leadStatus = parseInt(record.leadStatus);
              item.statusName = StudentLead_Status.find(
                ({ id }) => id == record.leadStatus
              ).type;
            }
            if (item.agentId != record.agentId) {
              item.agentId = record.agentId;
              item.agent = record.agent;
            }
            if (item.appUserDetailsId != record.appUserDetailsId) {
              item.appUserDetailsId = record.appUserDetailsId;
              item.admin = record.admin;
            }
            if (item.assessmentTeamId != record.assessmentTeamId) {
              item.assessmentTeamId = record.assessmentTeamId;
              item.assessmentTeam = record.assessment;
            }
            if (item.eventId != record.eventId) {
              item.eventId = record.eventId;
            }
            if (item.followupTeamId != record.followupTeamId) {
              item.followupTeamId = record.followupTeamId;
              item.followUpTeam = record.followUp;
            }
            if (item.complianceTeamId != record.complianceTeamId) {
              item.complianceTeamId = record.complianceTeamId;
              item.complianceTeam = record.compliance;
            }
            if (item.branchOfficeId != record.branchOfficeId) {
              item.branchOfficeId = record.branchOfficeId;
              item.branch = record.branchOffice;
            }
            item.campaign = record.campaign;
            item.channelId = record.channel;
            item.originId = record.origin;
            item.sourceId = record.source;
            item.createdOn = record.createdOn;
            (item.source =
              record.source && record.source > 0
                ? STUDENTSOURCES.find(({ id }) => id == record.source).type
                : ''),
              (item.origin =
                record.origin > 0
                  ? LEADORIGINS.find(({ id }) => id == record.origin).type
                  : ''),
              (item.channel =
                record.channel > 0
                  ? LEADCHANNELS.find(({ id }) => id == record.channel).type
                  : '');

            if (item.regionalManagerId != record.regionalManagerId) {
              item.regionalManagerId = record.regionalManagerId;
              item.regionalManager = record.regionalManager;
            }
            if (item.countryManagerId != record.countryManagerId) {
              item.countryManagerId = record.countryManagerId;
              item.countryManager = record.countryManager;
            }
            if (item.branchManagerId != record.branchManagerId) {
              item.branchsetStudentLeadManagerId = record.branchManagerId;
              item.branchManager = record.branchManager;
            }
            if (item.virtualTeamId != record.virtualTeamId) {
              item.virtualTeamId = record.virtualTeamId;
              item.virtualTeam = record.virtualTeam;
            }
          }
          temp.push(item);
        });
        setStudents(temp);
        setReady(false);
        setReferralReload(true);
        setStudentLead(null);
      })
      .catch((error) => {
        console.log(error);
        setStudentLead(null);
        setAdminAssignMode(false);
        setAgentAssignMode(false);
        setReady(false);
        // debugger;
      });
  };

  //console.log('users', users);

  const showEditOptions = (row) => {
    setStudentLead(row);
    console.log('Row', row);
    if (users.length == 0) {
      loadUsers()
        .then(() => {
          if (agents.length == 0) {
            loadAgents()
              .then(() => {
                setReady(true);
              })
              .catch((error) => {
                console.log(error);
                // debugger;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      if (agents.length == 0) {
        loadAgents()
          .then(() => {
            setReady(true);
          })
          .catch((error) => {
            console.log(error);
            // debugger;
          });
      } else {
        setReady(true);
      }
    }
  };

  return studentLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        {/* {(permissions.CanManageLeads) && (
          <Grid item justify="flex-end" className=" ml-auto ">
            <Button
              variant="contained"
              size="small"
              onClick={handleAddLead}
              startIcon={<PersonAddAltIcon />}
              className="bg-gradient-to-r from-indigo-700 to-purple-700 hover:scale-105 transform transition-all duration-300 rounded-full text-white font-bold px-6 py-1 shadow-md"
            >
              Quick Lead
            </Button>
          </Grid>
        )} */}
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Id"
              value={id}
              onChange={(e) => setId(e.target.value)}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <SearchIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Phone"
              value={phone}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(dateOfBirth && new Date(dateOfBirth)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setDateOfBirth(newVal);
              }}
              customInput={
                <TextField
                  label="Date of Birth"
                  fullWidth
                  // onChange={onChange}
                  value={dateOfBirth}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          {isInternal && (
            <Grid item xs={2}>
              <Select
                fullWidth
                size="small"
                native
                label="Branch Office"
                value={branchOfficeId}
                onChange={(e) => {
                  setBranchOfficeId(e.target.value);
                }}
              >
                <option value={0}>All Branches</option>
                {offices.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  // onChange={onChange}
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Date Type"
              value={dateType}
              onChange={(e) => {
                setDateType(e.target.value);
              }}
            >
              <option value={1}>Lead Added</option>
              <option value={2}>Lead Modified</option>
              <option value={3}>Status Changed</option>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Lead Status"
              value={leadStatus}
              onChange={(e) => {
                setLeadStatus(e.target.value);
              }}
            >
              <option value={0}>Lead Status</option>
              {StudentLead_Status.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Lead Activity"
              value={leadactivity}
              onChange={(e) => {
                setLeadActivity(e.target.value);
              }}
            >
              <option value={0}>Lead Activity</option>
              {StudentLead_Activities.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Lead Origin</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Origin"
                value={leadOrigin}
                onChange={(e) => {
                  setLeadOrigin(e.target.value);
                }}
              >
                <option value={0}>Lead Origin</option>
                {LEADORIGINS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Lead Source</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Source"
                value={leadSource}
                onChange={(e) => {
                  setLeadSource(e.target.value);
                }}
              >
                <option value={0}>Lead Source</option>
                {STUDENTSOURCES.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Lead Channel</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Channel"
                value={leadChannel}
                onChange={(e) => {
                  setLeadChannel(e.target.value);
                }}
              >
                <option value={0}>Lead Channel</option>
                {LEADCHANNELS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Citizenship"
              value={citizenship}
              onChange={(e) => {
                setCitizenship(e.target.value);
              }}
            >
              <option value={0}>Citizenship</option>
              {Countries.filter((item)=>item.id!=0).map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Team Leader</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="TeamLeader"
                value={TeamLeader}
                onChange={(e) => {
                  setTeamLeader(e.target.value);
                }}
              >
                <option value={0}>Unassigned</option>
                {filteredTeamLeaders.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Assessment</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Assessment"
                value={assessmentTeam}
                onChange={(e) => {
                  setAssessmentTeam(e.target.value);
                }}
              >
                <option value={0}>Unassigned</option>
                {filteredFollowAndAssessmentTeams.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Follow-up Team</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Follow-up Team"
                value={followupTeamId}
                onChange={(e) => {
                  setFollowUpTeam(e.target.value);
                }}
              >
                <option value={0}>Unassigned</option>
                {filteredFollowAndAssessmentTeams.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={1} className="pb-2">
          {isInternal && (
            <Grid item xs={2}>
              <Select
                fullWidth
                size="small"
                native
                label="Event Name"
                value={eventId}
                onChange={(e) => {
                  setEventId(e.target.value);
                }}
              >
                <option value={0}>All Event</option>
                {events.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.eventName}
                  </option>
                ))}
              </Select>
            </Grid>
          )}

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Intake Year</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Intake Year"
                value={intake}
                onChange={(e) => {
                  setIntake(e.target.value);
                }}
              >
                <option value={0}>Intake</option>
                {intakeYearList.map((item) => (
                  <option key={item.id} value={item.year}>
                    {item.year}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isInternal && (
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel>Compliance Status</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Compliance Status"
                  value={complianceStatus}
                  onChange={(e) => {
                    setComplianceStatus(e.target.value);
                  }}
                >
                  {/* <option value={0}> Unknown</option> */}
                  {COMPLIANCE_STATUS.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={2}>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Type"
                value={leadType}
                onChange={(e) => {
                  setLeadType(e.target.value);
                }}
              >
                <option value={0}>All Leads Type</option>
                <option value={1}>Referral Leads</option>
              </Select>
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Referral</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Referral"
                  value={referralAssignId}
                  onChange={(e) => {
                    setReferralAssignId(e.target.value);
                  }}
                >
                  <option value={0}>All Referral</option>
                  {referralList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.firstName + ' ' + item.lastName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {isInternal && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Event Attendee</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Event Attendee"
                  value={eventAttendee}
                  onChange={(e) => {
                    setEventAttendee(e.target.value);
                  }}
                >
                  <option value={0}>Both Attendee</option>
                  <option value={1}>Attendee</option>
                  <option value={2}>Not Attendee</option>
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid
            container
            justifyContent={'flex-end'}
            spacing={1}
            className="pt-2 pl-2"
          >
             {isInternal && ( <Grid item xs={2} >
          <FormControl fullWidth>
            <TextField
              select
              label="Preferred Study Level"
              variant="outlined"
              size="small"
              value={studyLevelFilter}
              onChange={(e) => {
                setStudyLevelFilter(e.target.value);
              }}
            >
              {STUDY_LEVEL_GROUPS.map((studyLevel) => (
                <MenuItem key={studyLevel.id} value={studyLevel.id}>
                  {studyLevel.type}
                </MenuItem>
              ))}
            </TextField>
           </FormControl>
          </Grid> )}
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="small"
                label="Passport Number"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                onClick={getData}
                variant="contained"
                className="bg-sky-400 hover:bg-sky-500"
              >
                Apply Filters
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={resetFilter} variant="outlined">
                Reset Filters
              </Button>
            </Grid>

            {isInternal && (
              <Grid item xs={2} container justifyContent={'flex-end'}>
                <Grid item>
                  <Button
                    fullWidth
                    onClick={() => setBulkUpdate(!bulkUpdate)}
                    variant="text"
                  >
                    {bulkUpdate ? 'Disable Bulk Update' : 'Enable Bulk Update'}
                  </Button>
                </Grid>
                {bulkUpdate && (
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        if (selectionModel.length > 0) {
                          showEditOptions(null);
                        }
                      }}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item style={{ height: 900, width: '100%' }}>
          <DataGridPro
            paginationMode="server"
            filterMode="server"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[100]}
            onPageChange={handlePageChange}
            rows={students}
            columns={studentColumns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'name') {
                window.open(`/leads/${params.row.id}/leadprofile`, '_blank');
              }
            }}
            checkboxSelection={isInternal && bulkUpdate}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel); //onRowSelection use for datagridpro v6 and onselection is use for current datagrid
            }}
            selectionModel={selectionModel}
          />
        </Grid>
      </Grid>
      {ready && (
        <StudentLeadAssignment
          bulkUpdate={bulkUpdate}
          studentlead={bulkUpdate ? null : studentLead}
          users={users.filter((item) => item.isDisabled == false)}
          cancel={setReady}
          events={events}
          referralList={referralList}
          setReferralReload={setReferralReload}
          action={actionSelectedUser}
          role={role}
        />
      )}
      {openAddNote && (
        <LeadNotes
          // records={notes}
          //setRecords={setNotes}
          open={openAddNote}
          cancel={setOpenAddNote}
          leadId={leadId}
          toName={userName}
        />
      )}
    </>
  ) : (
    <LoadingView />
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    userName: state.auth.name,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentLead);
