import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { Countries, getNext3Years } from '../../../../Util/Util';
import {
  APPLICATION_STATUSES,
  DATASET_CATEGORIES,
  INTAKES,
  STUDENTSOURCES,
  STUDENTSTATUS,
  STUDY_LEVELS,
  Selection_CATEGORIES_COMS,
  StudentLead_Activities,
  StudentLead_Status,
  yearList,
} from '../../../../Util/Constants';
import { AiFillMail } from 'react-icons/ai';
import {  addMonths } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import Agent from '../../../../api/agent';
import { intakeYearList } from '../../../Report/MockData/MockData';
import ApplicationStage from '../../../Students/Student/Stages/Applications/ApplicationStage';

const StudentComFilters = ({
  handleCountryOfResidence,
  countryOfResidence,
  login,
  setLogin,
  profileType,
  setProfileType,
  citizenship,
  university,
  intakeMonth,
  intakeYear,
  studyLevelFilter,
  Name,
  appStatus,
  handleAppStatus,
  handleCitizenshipFilterChange,
  handleUniversityChange,
  handleIntakeMonthChange,
  handleIntakeYearChange,
  handleStudyLevelFilterChange,
  handleNameChange,
  handleClearFilters,
  handleApplyFilters,
  handleApplyFiltersApp,
  handleSendBulkEmail,
  totalSelected,
  setStartDateFilter,
  setEndDateFilter,
  startDateFilter,
  endDateFilter,
  handleDatasetChange,
  datasetOption,
  BranchOfficeId,
  setBranchOfficeFilter,
  setDestination,
  destination,
  setStudentType,
  studentType,
  setAppType,
  appType,
  setstudentStatus,
  studentStatus,
  source,
  setSource, 
}) => {
  const [universityList, setUniversityList] = useState([]);
  const [offices, setOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Universities.list()
      .then((res) => {
        setUniversityList(res);
        //console.log('University List', res);
      })
      .catch((err) => {
        console.log('university err', err);
      });
  }, [datasetOption]);

  return (
    <>
      <div className="flex justify-around items-center gap-6">
        <div className="flex flex-col gap-2">
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth>
              <Autocomplete
                value={Selection_CATEGORIES_COMS.find(
                  (dataSet) => dataSet.id == parseInt(datasetOption)
                )}
                onChange={handleDatasetChange}
                options={Selection_CATEGORIES_COMS}
                className="w-56"
                getOptionLabel={(DATASET_CATEGORIES) => DATASET_CATEGORIES.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Select Type"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </div>
        <Grid container spacing={2} className="mb-0">
          {datasetOption == 1 ? (
            <div className="flex flex-col ml-12">
              <div className="flex justify-between items-center gap-6">
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      label="Name"
                      size="small"
                      value={Name}
                      onChange={handleNameChange}
                      variant="outlined"
                      // className="w-50"
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={12} sm={6} md={2}>
                    <Grid>
                      <FormControl fullWidth>
                        <InputLabel>Source</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label="Source"
                          value={source}
                          onChange={(e) => {
                            setSource(e.target.value);
                          }}
                        >
                          <option value={0}> Source</option>
                          {STUDENTSOURCES.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.type}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>


                {/* <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      size="small"
                      value={email}
                      onChange={handleEmailChange}
                      variant="outlined"
                      // className="w-50"
                    />
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        Countries.find(
                          (country) => country.id == countryOfResidence
                        ) || null
                      }
                      onChange={handleCountryOfResidence}
                      options={Countries}
                      // className="w-56"
                      getOptionLabel={(country) => country.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Residence"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={2} xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Branch Office</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Branch Office"
                      value={BranchOfficeId}
                      onChange={(e) =>
                        setBranchOfficeFilter(parseInt(e.target.value))
                      } // Parse to int and set the new value
                    >
                      <option value={0}>All Branches</option>
                      {offices.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Profile Type</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Profile Type"
                      value={profileType}
                      onChange={(e) => {
                        setProfileType(e.target.value);
                      }}
                    >
                      <option value={0}>Profile Type</option>
                      <option value={1}>Profile Completed</option>
                      <option value={2}>Profile Not Completed</option>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Login Type</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Login Type"
                      value={login}
                      onChange={(e) => {
                        setLogin(e.target.value);
                      }}
                    >
                      <option value={0}>Login Status</option>
                      <option value={1}>Logged In</option>
                      <option value={2}>Not Logged In</option>
                    </Select>
                  </FormControl>
                </Grid>
              </div>

              <div className="flex justify-between items-center mt-4 mb-3">
                <div className="flex flex-end gap-4">
                  <div>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <DatePicker
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addMonths(newVal, -1);
                            }
                            setStartDateFilter(newVal);
                          }}
                          customInput={
                            <TextField
                              label="Start Date"
                              fullWidth
                              value={startDateFilter}
                              variant="outlined"
                              size="small"
                              className="w-56"
                            />
                          }
                          selected={
                            (startDateFilter && new Date(startDateFilter)) ||
                            null
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                  <div>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <DatePicker
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addMonths(newVal, -1);
                            }
                            setEndDateFilter(newVal);
                          }}
                          customInput={
                            <TextField
                              label="End Date"
                              fullWidth
                              value={endDateFilter}
                              variant="outlined"
                              size="small"
                              className="w-56"
                            />
                          }
                          selected={
                            (endDateFilter && new Date(endDateFilter)) || null
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>All Students Type</InputLabel>
                      <Grid item xs={12}>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label="All Student Type"
                          className="w-56"
                          value={studentType}
                          onChange={(e) => {
                            setStudentType(e.target.value);
                          }}
                        >
                          <option value={0}>All Student Type</option>
                          <option value={1}>Referral Students</option>
                        </Select>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel fullWidth>
                        Applicaton/Not Application
                      </InputLabel>
                      <Grid item xs={12}>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label="Applicaton/Not Application"
                          className="w-56"
                          value={appType}
                          onChange={(e) => {
                            setAppType(e.target.value);
                          }}
                        >
                          <option value={0}>Both App and Not App</option>
                          <option value={1}>Application</option>
                          <option value={2}>Not Application</option>
                        </Select>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid>
                      <FormControl fullWidth>
                        <InputLabel> Status</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label=" Status"
                          value={studentStatus}
                          onChange={(e) => {
                            setstudentStatus(e.target.value);
                          }}
                        >
                          <option value={0}> Status</option>
                          {STUDENTSTATUS.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.type}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                </div>
              </div>


              <Grid container justifyContent="end" gap={3}>
                <Button
                  variant="outlined"
                  className="ml-2"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-blue-500 text-white ml-2"
                  onClick={handleApplyFilters}
                >
                  Apply Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-sky-800 text-white"
                  onClick={handleSendBulkEmail}
                  disabled={totalSelected === 0}
                >
                  <AiFillMail className="mr-2 text-lg" />
                  Send Bulk
                </Button>
              </Grid>
            </div>
          ) : datasetOption == 2 ? ( // for Application  type
            <div className="flex flex-col m-4">
              <div className="flex justify-between items-center gap-2 ml-12">
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        Countries.find(
                          (country) => country.id == citizenship
                        ) || null
                      }
                      onChange={handleCitizenshipFilterChange}
                      options={Countries}
                      getOptionLabel={(country) => country.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Citizenship"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Grid>
                      <FormControl fullWidth>
                        <InputLabel>Source</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label="Source"
                          value={source}
                          onChange={(e) => {
                            setSource(e.target.value);
                          }}
                        >
                          <option value={0}> Source</option>
                          {STUDENTSOURCES.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.type}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        universityList.find((univ) => univ.id == university) ||
                        null
                      }
                      onChange={handleUniversityChange}
                      options={universityList}
                      getOptionLabel={(universityList) => universityList.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="University"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        STUDY_LEVELS.find(
                          (dataSetGroup) =>
                            dataSetGroup.id == parseInt(studyLevelFilter)
                        ) || null
                      }
                      onChange={handleStudyLevelFilterChange}
                      options={STUDY_LEVELS}
                      className="w-56"
                      getOptionLabel={(STUDY_LEVELS) => STUDY_LEVELS.type}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Study Level"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        INTAKES.find(
                          (intake) => intake.id == parseInt(intakeMonth)
                        ) || null
                      }
                      onChange={handleIntakeMonthChange}
                      options={INTAKES}
                      getOptionLabel={(INTAKES) => INTAKES.type}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Intake Month"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Intake Year</InputLabel>
                    <Select
                      size="small"
                      label="Intake Year"
                      className="w-48 ml-2"
                      value={intakeYear}
                      onChange={handleIntakeYearChange}
                      variant="outlined"
                    >
                      {intakeYearList.map((intakeYear) => (
                        <MenuItem key={intakeYear.id} value={intakeYear.year}>
                          {intakeYear.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>App Status</InputLabel>
                    <Select
                      size="small"
                      label="App Status"
                      className="w-48 ml-2"
                      value={appStatus}
                      onChange={handleAppStatus}
                      variant="outlined"
                    >
                      {APPLICATION_STATUSES.map((appstatus) => (
                        <MenuItem key={appstatus.id} value={appstatus.id}>
                          {appstatus.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </div>
              <div className="flex flex-col m-4">
                <Grid
                  container
                  spacing={1}
                  className="flex justify-between items-center gap-1 ml-12"
                >
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <DatePicker
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addMonths(newVal, -1);
                          }
                          setStartDateFilter(newVal);
                        }}
                        customInput={
                          <TextField
                            label="Start Date"
                            fullWidth
                            value={startDateFilter}
                            variant="outlined"
                            size="small"
                          />
                        }
                        selected={
                          (startDateFilter && new Date(startDateFilter)) || null
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <DatePicker
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addMonths(newVal, -1);
                          }
                          setEndDateFilter(newVal);
                        }}
                        customInput={
                          <TextField
                            label="End Date"
                            fullWidth
                            value={endDateFilter}
                            variant="outlined"
                            size="small"
                          />
                        }
                        selected={
                          (endDateFilter && new Date(endDateFilter)) || null
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} md={2} xs={2}>
                    <FormControl fullWidth>
                      <InputLabel>Branch Office</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        native
                        label="Branch Office"
                        value={BranchOfficeId}
                        onChange={(e) =>
                          setBranchOfficeFilter(parseInt(e.target.value))
                        } // Parse to int and set the new value
                      >
                        <option value={0}>All Branches </option>
                        {offices.map((branch) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={destination}
                      onChange={(_, newVal) => {
                        setDestination(newVal);
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={Countries}
                      //loading={appTeamLoading}
                      renderInput={(params) => (
                        <TextField
                          // inputRef={citizenshipRef}
                          {...params}
                          label="Destination"
                          placeholder="Destination"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel>All Students Type</InputLabel>
                      <Grid item xs={12}>
                        <Select
                          fullWidth
                          size="small"
                          native
                          label="All Student Type"
                          className="w-64"
                          value={studentType}
                          onChange={(e) => {
                            setStudentType(e.target.value);
                          }}
                        >
                          <option value={0}>All Student Type</option>
                          <option value={1}>Referral Students</option>
                        </Select>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid container justifyContent="end" className="pt-3">
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="contained"
                        className="bg-blue-500 text-white ml-0 w-full md:w-48"
                        onClick={handleApplyFiltersApp}
                      >
                        Apply Filters
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="outlined"
                        className=" w-full md:w-48"
                        onClick={handleClearFilters}
                      >
                        Clear Filters
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="contained"
                        className="bg-sky-800 text-white w-full md:w-48"
                        onClick={handleSendBulkEmail}
                        disabled={totalSelected === 0}
                      >
                        <AiFillMail className="mr-2 text-lg" />
                        Send Bulk
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ''
          )}
        </Grid>
      </div>
    </>
  );
};

export default StudentComFilters;
