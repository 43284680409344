import React, { useState, useEffect, useContext, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormControl, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';

import StudentProgramList from '../Students/Student/ProgramsList/ProgramList';
import { INTAKES, STUDY_LEVELS } from '../../Util/Constants';
import LoadingView from '../Common/Loading/LoadingView';
import UserContext from '../../context/user-context';
import { Countries } from '../../Util/Util';
import CourseList from './CourseList';

import {
  InputAdornment,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Agent from '../../api/agent';

const Programs = ({ role, isInternal, isAgent, isAgentStaff }) => {
  const userContext = useContext(UserContext);
  const { state } = useParams('state');

  useEffect(() => {
    console.log('Component mounted. Role:', role);
    console.log('Is Agent:', role.isAgent);
    console.log('Is Agent Staff:', role.isAgentStaff);
  }, []);

  const [basedOnPreferences, setBasedOnPreferences] = useState(role.isStudent);
  useEffect(() => {
    if (!state) {
      // we will reset applicationId and studentId of user context
      userContext.setUserContext({
        ...userContext,
        ...{
          applicationId: 0,
          studentId: 0,
          studentName: null,
        },
      });
    } else {
      // this means, we are here for a student
      if (userContext.studentName) {
        setProgramTitle(
          `Find a program for ${userContext.studentName} and apply`
        );
      }
    }
  }, []);
  useEffect(() => {
    console.log('Role:', role);
    console.log('Is Agent:', isAgent);
    console.log('Is Agent Staff:', isAgentStaff);
  }, [role, isAgent, isAgentStaff]);
  const [programTitle, setProgramTitle] = useState(
    'Find your program and apply'
  );
  const [search, setSearch] = useState('');
  const [useAllInstitutions, setUseAllInstitutions] = useState(true);
  const [allInstitutions, setAllInstitutions] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [courses, setCourses] = useState([]);
  const [studyLevel, setStudyLevel] = useState(0);
  const [country, setCountry] = useState(0);
  const [universityName, setUniversityName] = useState('');
  const [intakes, setIntakes] = useState({
    January: false,
    February: false,
    March: false,
    April: false,
    May: false,
    June: false,
    July: false,
    August: false,
    September: false,
    October: false,
    November: false,
    December: false,
    allIntake: false,
  });
  const {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
  } = intakes;
  const [loaded, setLoaded] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const forCrm = isAgent || isAgentStaff ? 1 : 2

  const getOptionsDelayed = useCallback(
    debounce((countryId, text, callback) => {
      setAllInstitutions([]);
      Agent.Universities.search(countryId, text).then(callback);
    }, 300),
    []
  );

  useEffect(() => {
    setAllInstitutions([]);
    if (universityName && !useAllInstitutions) {
      getOptionsDelayed(country, universityName, (options) =>
        setAllInstitutions(options)
      );
    }
  }, [universityName, useAllInstitutions]);

  // useEffect(() => {
  //   if (!useAllInstitutions && allInstitutions.length == 0) {
  //     Agent.Universities.list()
  //       .then((universities) => {
  //         setAllInstitutions(universities);
  //         setInstitutionLoaded(true);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         debugger;
  //       });
  //   }
  // }, [useAllInstitutions]);

  const handleIntakeChange = (event) => {
    let temp = {};
    if (event.target.name == 'allIntake' && event.target.checked == true) {
      for (const prop in intakes) {
        temp[prop] = event.target.checked;
      }
    } else if (event.target.checked == true) {
      temp[event.target.name] = true;
    } else {
      temp[event.target.name] = false;
      temp.allIntake = false;
    }
    setIntakes({ ...intakes, ...temp });
    console.log({ ...intakes, ...temp });
  };

  const getParams = (initial) => {
    const params = new URLSearchParams();
    params.append('studentId', state ? state : '0');
    params.append('pageNumber', initial ? '1' : currentPage.toString());
    params.append('pageSize', '5');
    params.append('search', search);
    params.append('studyLevelId', studyLevel.toString());
    params.append('country', country.toString());
    params.append('forCrm', Number(forCrm));


    // New logic for setting forCrm value
    // console.log('For CRM:', forCrm);
    // console.log('Role:', role);
    // console.log('Is Agent:', role.isAgent);
    // console.log('Is Agent Staff:', role.isAgentStaff);
    // console.log('forCrm value:', forCrm);
    // console.log('forCrm value (as string):', forCrm.toString());
    // console.log('Params:', params.toString());

    params.append(
      'university',
      selectedInstitution && !useAllInstitutions ? selectedInstitution.id : '0'
    );


    let tempIntakes = '';
    for (const intake in intakes) {
      if (intake == 'allIntake') continue;
      if (intakes[intake] == true) {
        tempIntakes =
          tempIntakes + ',' + INTAKES.find(({ type }) => type == intake).id;
      }
    }
    params.append('intakes', tempIntakes);
    if (basedOnPreferences) {
      params.append('basedOnPreferences', basedOnPreferences.toString());
    }
    return params;
  };

  // console.log('getParams', getParams);

  const searchPrograms = (initial) => {
    setLoaded(false);
    if (initial) {
      setCurrentPage(1);
      setLoadingInitial(true);
    } else {
      setLoadingNext(true);
    }
    Agent.Programs.list(getParams(initial))
      .then((programs) => {
        console.log('API Response:', programs);
        setCurrentPage((prev) => prev + 1);
        const { pagination, data } = programs;
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);
        if (initial) {
          setCourses(data);
        } else {
          setCourses(courses.concat(data));
        }

        setLoadingInitial(false);
        setLoadingNext(false);
        setLoaded(true);
      })
      .catch((error) => {
        console.error('API Error:', error);
        debugger;
        setLoadingInitial(false);
        setLoadingNext(false);
        setLoaded(false);
        console.log(error);
      });
  };

  function handleGetNext() {
    searchPrograms();
  }
  return !role.isStudent ? (
    <Grid container justifyContent="center">
      <Grid item container xs={10}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Typography color="secondary" variant="h5">
                {programTitle}
              </Typography>
            </Grid>
            {(role.isStudent || state) && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={basedOnPreferences}
                      onChange={() => {
                        setBasedOnPreferences((prevState) => !prevState);
                      }}
                    />
                  }
                  label={
                    state
                      ? 'Based on student preferences'
                      : 'Based on my preferences'
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Find your program and click &apos;Apply Now!&apos; and you will be
              redirected to your applicaiton.
            </Typography>
            <Typography variant="body1">
              Please note you can only apply for students with completed
              profile.
            </Typography>
          </Grid>
          <Grid item>
            <RadioGroup
              value={useAllInstitutions}
              onChange={() => setUseAllInstitutions((prev) => !prev)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Search all institutions"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Choose specific institutions"
              />
            </RadioGroup>
          </Grid>
          {!useAllInstitutions && (
            <Grid item>
              <Autocomplete
                filterOptions={(x) => x}
                style={{ width: '100%' }}
                size="small"
                value={selectedInstitution}
                onChange={(_, newValue) => {
                  setSelectedInstitution(newValue);
                }}
                options={
                  country > 0
                    ? allInstitutions.filter((c) => c.countryId == country)
                    : allInstitutions
                }
                getOptionLabel={(option) =>
                  `${option.name}, ${
                    Countries.find(({ id }) => id == option.countryId).name
                  }`
                }
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    value={universityName}
                    onChange={(e) => setUniversityName(e.target.value)}
                    fullWidth
                    {...params}
                    label="University/Institution"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              hiddenLabel
              fullWidth
              id="standard-start-adornment"
              variant="outlined"
              placeholder="Search Programs"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="studyLevel"
                  value={studyLevel}
                  onChange={(e) => setStudyLevel(parseInt(e.target.value))}
                >
                  {STUDY_LEVELS.filter((level) => level.display == true).map(
                    (level) => (
                      <FormControlLabel
                        key={level.id}
                        value={level.id}
                        control={<Radio />}
                        label={level.type}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={4} container direction="column">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={January}
                        onChange={handleIntakeChange}
                        name="January"
                      />
                    }
                    label="Jan Intake"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={February}
                        onChange={handleIntakeChange}
                        name="February"
                      />
                    }
                    label="Feb Intake"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={March}
                        onChange={handleIntakeChange}
                        name="March"
                      />
                    }
                    label="Mar Intake"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={April}
                        onChange={handleIntakeChange}
                        name="April"
                      />
                    }
                    label="Apr Intake"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={May}
                        onChange={handleIntakeChange}
                        name="May"
                      />
                    }
                    label="May Intake"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={June}
                        onChange={handleIntakeChange}
                        name="June"
                      />
                    }
                    label="June Intake"
                  />
                </Grid>
              </Grid>
              <Grid item xs={7} container direction="column">
                <Grid item>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={July}
                          onChange={handleIntakeChange}
                          name="July"
                        />
                      }
                      label="July Intake"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={August}
                          onChange={handleIntakeChange}
                          name="August"
                        />
                      }
                      label="Aug Intake"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={September}
                          onChange={handleIntakeChange}
                          name="September"
                        />
                      }
                      label="Sep Intake"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={October}
                          onChange={handleIntakeChange}
                          name="October"
                        />
                      }
                      label="Oct Intake"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={November}
                          onChange={handleIntakeChange}
                          name="November"
                        />
                      }
                      label="Nov Intake"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={December}
                          onChange={handleIntakeChange}
                          name="December"
                        />
                      }
                      label="Dec Intake"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} container direction="column" spacing={3}>
              {!showMore && (
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="country"
                      value={country}
                      onChange={(e) => {
                        setCountry(parseInt(e.target.value));
                        setUniversityName('');
                      }}
                    >
                      <FormControlLabel
                        key={0}
                        value={0}
                        control={<Radio />}
                        label={'All Countries'}
                      />
                      {Countries.filter((c) => c.showAll)
                        .sort(function (a, b) {
                          if (a.order < b.order) {
                            return -1;
                          }
                          if (a.order > b.order) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((country) => (
                          <FormControlLabel
                            key={country.id}
                            value={country.id}
                            control={<Radio />}
                            label={country.short || country.name}
                          />
                        ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {isInternal && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showMore}
                        onChange={() => setShowMore(!showMore)}
                      />
                    }
                    label="Show all countries"
                  />
                </Grid>
              )}
              {showMore && isInternal && (
                <Grid item>
                  <Autocomplete
                    style={{ width: '100%' }}
                    size="small"
                    value={country.id}
                    onChange={(_, newValue) => {
                      setCountry(newValue ? newValue.id : 0);
                    }}
                    options={Countries}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} label="Country" />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="column" spacing={1}>
          <Grid item>
            <LoadingButton
              loading={loadingInitial}
              size="large"
              variant="contained"
              className="bg-sky-500 hover:bg-sky-600"
              onClick={() => searchPrograms(true)}
              startIcon={<SearchIcon />}
            >
              Search Programs
            </LoadingButton>
          </Grid>
          {loaded && (
            <Grid item>
              <Typography
                color="primary"
                variant="h5"
              >{`${totalItems} Programs found`}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" style={{ marginTop: 15 }}>
          {courses.length > 0 && (
            <Grid item>
              <InfiniteScroll
                dataLength={courses.length}
                next={handleGetNext}
                hasMore={!loadingNext && currentPage <= totalPages}
              >
                <CourseList courses={courses} />
              </InfiniteScroll>
            </Grid>
          )}
          {(loadingNext || loadingInitial) && (
            <Grid item container direction="column" spacing={5}>
              <Grid item>
                <LoadingView color="primary" />
              </Grid>
              <Grid item>
                <LoadingView />
              </Grid>
              <Grid item>
                <LoadingView />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      {role.isStudent && (
        <div className="w-full p-6 flex justify-center">
          <StudentProgramList />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    isInternal: state.auth.isInternal,
    isAgent: state.auth.role.isAgent,
    isAgentStaff: state.auth.role.isAgentStaff,
  };
};

export default connect(mapStateToProps, null)(Programs);

