import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { Typography, Grid } from '@mui/material';
import Agent from '../../../../api/agent';
import { USER_PERMISSIONS } from '../../../../Util/Constants';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';

const ReportPermissionAssignment = ({ user, role }) => {
  const [permissions, setPermissions] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await Agent.Users.getClaims(user.appUserId);
        
        const existingPermissions = response.map(claim => claim.type);
        
        const tempPermissions = USER_PERMISSIONS
          .filter(permission => 
            (permission.type.startsWith('CanAccess') && permission.type.toLowerCase().includes('report')) || 
            permission.type === 'CanManageAutomatedReports'
          )
          .map(permission => ({
            ...permission,
            status: existingPermissions.includes(permission.type)
          }));

        setPermissions(tempPermissions);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, [user.appUserId]);

  const handleChange = (event, index) => {
    setPermissions(prevPermissions => {
      const newPermissions = [...prevPermissions];
      newPermissions[index].status = event.target.checked;
      return newPermissions;
    });
    setSaved(false);
  };

  const handleSave = async () => {
    setUpdating(true);
    try {
      const existingPermissions = await Agent.Users.getClaims(user.appUserId);
      const existingPermissionTypes = existingPermissions.map(claim => claim.type);

      const updatedReportPermissions = permissions
        .filter(permission => permission.status)
        .map(permission => permission.type);

      const nonReportPermissions = existingPermissionTypes.filter(type => 
        !(type.startsWith('CanAccess') && type.toLowerCase().includes('report')) && 
        type !== 'CanManageAutomatedReports'
      );

      const permissionsToSend = [...new Set([...updatedReportPermissions, ...nonReportPermissions])];

      await Agent.Users.setClaims(user.appUserId, permissionsToSend);
      setUpdating(false);
      setSaved(true);
    } catch (error) {
      setUpdating(false);
      console.error('Error saving permissions:', error);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl component="fieldset">
          <FormGroup>
            {permissions.map((permission, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Switch
                    checked={permission.status}
                    onChange={(event) => handleChange(event, i)}
                    name={permission.type}
                    color="primary"
                  />
                }
                label={permission.value}
              />
            ))}
          </FormGroup>
          {role.isSuperAdmin && (
            <FormHelperText>
              Click to turn blue to give permission
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {role.isSuperAdmin && (
        <Grid item container spacing={2}>
          <Grid item>
            <LoadingButton
              loading={updating}
              onClick={handleSave}
              className='bg-red-800'
              variant="contained"
            >
              Save Report Permissions
            </LoadingButton>
          </Grid>
          {saved && (
            <Grid item>
              <Typography color="primary">Successfully Saved!</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.role,
    user: ownProps.user,
  };
};

export default connect(mapStateToProps, null)(ReportPermissionAssignment);

