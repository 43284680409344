import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

const ErrorPopup = ({ error }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-2xl transform transition-all duration-300 ease-in-out scale-100 opacity-100">
        <div className="flex flex-col items-center mb-4">
          <div className="bg-red-100 rounded-full p-3 mb-3">
            <ExclamationTriangleIcon className="w-12 h-12 text-red-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 text-center mb-1">Access Denied</h2>
          <div className="w-16 h-0.5 bg-red-500 rounded"></div>
        </div>
        <p className="text-gray-700 mb-6 text-base text-center leading-relaxed">{error}</p>
        <div className="flex justify-center">
          <Link
            to="/reportpanel"
            className="bg-sky-500 text-white py-2 px-6 rounded-md hover:bg-sky-600 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-opacity-50 shadow-md text-center font-semibold text-sm flex items-center"
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            Back to Report Panel
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;

