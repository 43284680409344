import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { connect } from 'react-redux';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import CountrySourceReport from './CountrySourceReport';
import ErrorPopup from '../RegionalReport/ErrorPopUp';

const RegionalSourceReport = ({ permissions }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  const [countries, setCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState('');
  const [branches, setBranches] = useState([]);

  const fetchBranches = async () => {
    try {
      const branchData = await Agent.Cache.getBranchOffices();
      setBranches(branchData);
    } catch (err) {
      console.error('Error fetching branch data:', err);
      setError('Failed to fetch branch information. Please try again.');
    }
  };

  const loadPermissions = useCallback(() => {
    if (!permissions || Object.keys(permissions).length === 0 || branches.length === 0) {
      return;
    }

    const uniqueCountries = new Set();
    branches.forEach(branch => {
      const country = Countries.find(c => c.id === branch.countryId);
      if (country && permissions[`CanAccessRegionalSourceReport${country.name.replace(/\s+/g, '')}`]) {
        uniqueCountries.add(country);
      }
    });

    const countryList = Array.from(uniqueCountries);

    if (countryList.length > 0) {
      setCountries(countryList);
      setActiveCountry(countryList[0].name);
      setError(null);
    } else {
      setError('You do not have permission to access any regional source reports.');
    }

    setPermissionsChecked(true);
    setLoading(false);
  }, [branches, permissions]);

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    if (branches.length > 0 && permissions && Object.keys(permissions).length > 0) {
      loadPermissions();
    }
  }, [branches, permissions, loadPermissions]);

  useEffect(() => {
    document.title = "Source Performance Regional Report";
  }, []);

  const filteredBranches = useMemo(() => {
    const countryBranches = {};
    countries.forEach(country => {
      countryBranches[country.name] = branches.filter(branch => branch.countryId === country.id);
    });
    return countryBranches;
  }, [branches, countries]);

  const handleCountryChange = (countryName) => {
    setActiveCountry(countryName);
  };

  const getFlagUrl = (countryCode) => {
    return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
  };

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    fetchBranches();
  };

  if (loading && !permissionsChecked) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress className="text-blue-500" />
      </div>
    );
  }

  if (error) {
    return <ErrorPopup error={error} />;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="w-full mx-auto px-2 sm:px-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Source Performance Regional Report
          </h1>
          <Link
            to="/reportpanel"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowBackIcon className="h-5 w-5 mr-1" />
            Back to Reporting Panel
          </Link>
        </div>
        {countries.length > 0 ? (
          <>
            <div className="mb-6">
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-2">
                {countries.map((country) => (
                  <button
                    key={country.id}
                    onClick={() => handleCountryChange(country.name)}
                    className={`px-2 py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 flex items-center justify-center gap-1 ${
                      activeCountry === country.name
                        ? 'bg-blue-500 text-white'
                        : 'bg-white text-gray-700 hover:bg-blue-100'
                    }`}
                  >
                    <img
                      src={getFlagUrl(country.code)}
                      alt={`${country.name} flag`}
                      className="w-4 h-3 object-cover rounded-sm shadow-sm"
                      loading="lazy"
                    />
                    <span className="truncate">
                      {activeCountry === country.name
                        ? country.name.toUpperCase()
                        : country.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
              {activeCountry && (
                <CountrySourceReport
                  country={activeCountry}
                  branches={filteredBranches[activeCountry]}
                  Countries={Countries}
                  getFlagUrl={getFlagUrl}
                />
              )}
            </div>
          </>
        ) : (
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">No Access to Regional Reports</h2>
            <p className="text-gray-600 text-center mb-6">
              You do not have permission to access any regional reports. Please contact your administrator if you believe this is an error.
            </p>
            <div className="flex justify-center">
              <button
                onClick={handleRetry}
                className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center justify-center mr-4"
              >
                <RefreshIcon className="mr-2" />
                Retry
              </button>
              <Link
                to="/reportpanel"
                className="py-2 px-4 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors duration-300 flex items-center justify-center"
              >
                <ArrowBackIcon className="mr-2" />
                Back to Reports
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(RegionalSourceReport);

