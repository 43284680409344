import React, { useEffect, useRef, useState } from "react"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import CircularProgress from "@mui/material/CircularProgress"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material"
import { IconButton } from "@mui/material"
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material"
import PhotoWidgetDropzone from "../../../../Common/ImageUpload/PhotoWidgetDropzone"
import { DownloadCloudFile } from "../../../../../Util/FileProcessing"
import { GENDER, MARITALSTATUS, STUDENTSTATUS } from "../../../../../Util/Constants"
import user from "../../../../../assets/images/user_placeholder.png"
import { ResizeFile } from "../../../../../Util/ResizeFile"
import Agent from "../../../../../api/agent"
import { Countries } from "../../../../../Util/Util"
import moment from "moment"
import useWindowSize from "../../../common/useWindowSize"
import * as yup from "yup"
import { isEmpty, isNull } from "lodash"
import PhoneInput from "react-phone-number-input"
import phoneInput from "./phoneInput"
import additionalPhoneInput from "./additionalPhoneInput"
import Agreements from "./Agreements"

const PREFIX = "StudentProfile"

const classes = {
  root: `${PREFIX}-root`,
  penelHeader: `${PREFIX}-penelHeader`,
  fieldset: `${PREFIX}-fieldset`,
  legend: `${PREFIX}-legend`,
  extendedIcon: `${PREFIX}-extendedIcon`,
}

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.penelHeader}`]: {
    backgroundColor: theme.palette.common.lightBlue,
  },
  [`& .${classes.fieldset}`]: {
    borderColor: theme.palette.common.lightBlue,
    borderRadius: 5,
    marginBottom: 20,
  },
  [`& .${classes.legend}`]: {
    margin: "auto",
    padding: 10,
  },
  [`& .${classes.extendedIcon}`]: {
    marginRight: theme.spacing(1),
  },
}))

const StudentProfile = ({
  profile,
  displayError,
  updateProfileParent,
  studentPhoto,
  setStudentPhoto,
  tempImageParent,
  setTempImageParent,
  inValidEmail,
  setInValidEmail,
  name,
  setTermsAgreed,
  termsAgreed,
  role,
  isInternal,
  agentName,
}) => {
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [emailChecking, setEmailChecking] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [successOpen, setSuccessOpen] = useState(false)
  const [isStatusUpdating, setIsStatusUpdating] = useState(false)
  console.log("profile.countryOfIssue", profile.status)
  const [firstName, setFirstName] = useState(profile.firstName ? profile.firstName : "")
  const [lastName, setLastName] = useState(profile.lastName ? profile.lastName : "")
  const [dateOfBirth, setDateOfBirth] = useState()
  const [placeOfBirth, setPlaceOfBirth] = useState(profile.placeOfBirth ? profile.placeOfBirth : "")
  const [gender, setGender] = useState()
  const [maritalStatus, setMaritalStatus] = useState()
  const [passportNumber, setPassportNumber] = useState(profile.passportNumber ? profile.passportNumber : "")
  const [issueDate, setIssueDate] = useState("")
  const [expiryDate, setExpiryDate] = useState("")
  const [countryOfIssue, setCountryOfIssue] = useState(
    profile.countryOfIssue ? Countries[profile.countryOfIssue] : null,
  )
  const [citizenship, setCitizenship] = useState(profile.citizenship ? Countries[profile.citizenship] : null)
  const [countryOfResidence, setCountryOfResidence] = useState(
    profile.countryOfResidence ? Countries[profile.countryOfResidence] : null,
  )
  const [email, setEmail] = useState(profile.email ? profile.email : "")
  const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber ? profile.phoneNumber : "")
  const [country, setCountry] = useState(profile.country ? Countries[profile.country] : null)
  const [additionalEmail1, setAdditionalEmail1] = useState(profile.additionalEmail1 ? profile.additionalEmail1 : "")
  const [additionalEmail2, setAdditionalEmail2] = useState(profile.additionalEmail2 ? profile.additionalEmail2 : "")
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState(
    profile.additionalPhoneNumber ? profile.additionalPhoneNumber : "",
  )
  const [streetAddress, setStreetAddress] = useState(profile.streetAddress ? profile.streetAddress : "")
  const [status, setStatus] = useState(profile.status ? profile.status : 1)
  //const [studentPhoto, setStudentPhoto] = useState();
  const [fieldErrors, setFieldErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState("")
  const [openResCountry, setOpenResCountry] = useState(false)
  const [openCountry, setOpenCountry] = useState(false)
  const [openIssueCountry, setOpenIssueCountry] = useState(false)
  const [openCitizenship, setOpenCitizenship] = useState(false)
  //const [countryOfResidance, setCountryOfResidance] = useState(null);
  const [tempCountry, setTempCountry] = useState([])
  const countryResRef = useRef()
  const countryIssueRef = useRef()
  const citizenshipRef = useRef()
  const countryRef = useRef()

  if (!isEmpty(placeOfBirth)) {
    profile.placeOfBirth = placeOfBirth
  }
  if (!isEmpty(firstName)) {
    profile.firstName = firstName
  }
  if (!isEmpty(lastName)) {
    profile.lastName = lastName
  }

  if (!isEmpty(dateOfBirth)) {
    profile.dateOfBirth = dateOfBirth
  }
  if (gender) {
    profile.gender = gender
  }
  if (maritalStatus) {
    profile.maritalStatus = maritalStatus
  }
  if (!isEmpty(passportNumber)) {
    profile.passportNumber = passportNumber
  }
  if (!isEmpty(issueDate)) {
    profile.issueDate = issueDate
  }
  if (!isEmpty(expiryDate)) {
    profile.expiryDate = expiryDate
  }
  if (countryOfIssue) {
    profile.countryOfIssue = countryOfIssue.id
  }
  if (citizenship) {
    profile.citizenship = citizenship.id
  }
  if (countryOfResidence) {
    profile.countryOfResidence = countryOfResidence.id
  }
  if (!isEmpty(email)) {
    profile.email = email
  }
  if (!isEmpty(phoneNumber)) {
    profile.phoneNumber = phoneNumber
  }
  if (country) {
    profile.country = country.id
  }
  if (!isEmpty(streetAddress)) {
    profile.streetAddress = streetAddress
  }
  if (!isEmpty(additionalPhoneNumber)) {
    profile.additionalPhoneNumber = additionalPhoneNumber
  }
  if (!isEmpty(additionalEmail1)) {
    profile.additionalEmail1 = additionalEmail1
  }
  if (!isEmpty(additionalEmail2)) {
    profile.additionalEmail2 = additionalEmail2
  }
  if (!isEmpty(status)) {
    profile.status = status
  }
  useEffect(() => {
    setTempCountry(Countries)
  }, [Countries])

  const handleFirstName = (event) => {
    setFirstName(event.target.value)
    fieldErrors.firstName = ""
    //onBlurHandler;
  }
  const handleLastName = (event) => {
    setLastName(event.target.value)
    fieldErrors.lastName = ""
    //onBlurHandler;
  }
  const handleDateOfBirth = (event) => {
    setDateOfBirth(event.target.value)
    fieldErrors.dateOfBirth = ""
    // onBlurHandler;
  }
  const handlePlaceOfBirth = (event) => {
    setPlaceOfBirth(event.target.value)
    fieldErrors.dateOfBirth = ""
  }
  const handleGender = (event) => {
    setGender(event.target.value)
    fieldErrors.gender = ""
    //onBlurHandler;
  }
  const handleMaritalStatus = (event) => {
    setMaritalStatus(event.target.value)
    fieldErrors.maritalStatus = ""
    //  onBlurHandler;
  }
  const handlePassportNumber = (event) => {
    setPassportNumber(event.target.value)
    fieldErrors.passportNumber = ""
    // onBlurHandler;
  }
  const handleIssueDate = (event) => {
    setIssueDate(event.target.value)
    fieldErrors.issueDate = ""
    //  onBlurHandler;
  }
  const handleExpiryDate = (event) => {
    setExpiryDate(event.target.value)
    fieldErrors.expiryDate = ""
    //onBlurHandler;
  }
  const handleCountryOfIssue = (event) => {
    setCountryOfIssue(event.target.value)
    fieldErrors.countryOfIssue = ""
    //onBlurHandler;
  }
  const handleCitizenship = (event) => {
    setCitizenship(event.target.value)
    fieldErrors.citizenship = ""
    //onBlurHandler;
  }
  const handleCountryOfResidence = (event) => {
    setCountryOfResidence(event.target.value)
    fieldErrors.countryOfResidence = ""
    //onBlurHandler;
  }
  const handleEmail = (event) => {
    setEmail(event.target.value)
    fieldErrors.email = ""
    // onBlurHandler;
  }
  const handleAdditionalEmail2 = (event) => {
    setAdditionalEmail2(event.target.value)
    fieldErrors.additionalEmail2 = ""
    // onBlurHandler;
  }
  const handleAdditionalEmail1 = (event) => {
    setAdditionalEmail1(event.target.value)
    fieldErrors.additionalEmail1 = ""
    // onBlurHandler;
  }
  const handleStatus = (event) => {
    const newStatus = event.target.value
    setStatus(newStatus)
    updateProfileParent({
      ...profile,
      status: newStatus,
    })
  }
  const [phoneError, setPhoneError] = useState("")
  const handlePhoneNumber = (value, event) => {
    console.log("phone number value", value)
    //setPhoneNumber(event.target.value);
    fieldErrors.phoneNumber = ""
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNumber(value)
        setPhoneError("")
      } else {
        setPhoneNumber(null)
        setPhoneError("Invalid phone number format. Include country code (+) and digits.")
      }
    } else {
      // Clear any previous error and reset the phone number
      setPhoneNumber(null)
      setPhoneError("")
    }
  }
  const [addPhoneError, setaddPhoneError] = useState("")
  const handleAdditionalPhoneNumber = (value, country) => {
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setAdditionalPhoneNumber(value)
        setaddPhoneError("")
      } else {
        setAdditionalPhoneNumber(null)
        setaddPhoneError("Invalid phone number format. Include country code (+) and digits.")
      }
    } else {
      // Clear any previous error and reset the phone number
      setAdditionalPhoneNumber(null)
      setaddPhoneError("")
    }
  }
  const handleCountry = (event) => {
    setCountry(event.target.value)
    fieldErrors.country = ""
    //onBlurHandler;
  }
  const handleStreetAddress = (event) => {
    setStreetAddress(event.target.value)
    fieldErrors.streetAddress = ""
    //  onBlurHandler;
  }
  const handleStudentPhoto = (event) => {
    setStudentPhoto(event.target.value)
    fieldErrors.studentPhoto = ""
    // onBlurHandler;
  }

  const successDialogStyles = {
    backgroundColor: "bg-white",
  }

  // const handleSuccessfulOpen = () => {
  //   setSuccessMessage(<Agreements />);
  //   setSuccessOpen(true);
  // };
  const onBlurHandler = (name, value) => {
    profile = {
      ...profile,
      ...{
        [name]: value,
      },
    }
    updateProfileParent(profile)
  }

  const onEmailBlurHandler = (name, value) => {
    // setEmailChecking(false);
    // setInValidEmail(false);
    // onBlurHandler(name, value);
    // if (value) {
    //   setEmailChecking(true);
    //   setInValidEmail(true);
    //   Agent.Students.emailExists(value)
    //     .then((exist) => {
    //       setEmailChecking(false);
    //       if (!exist) {
    //         onBlurHandler(name, value);
    //         setInValidEmail(false);
    //       }
    //     })
    //     .catch((error) => {
    //       setEmailChecking(false);
    //       console.log(error);
    //       debugger;
    //     });
    // } else {
    //   setEmailChecking(false);
    //   setInValidEmail(false);
    //   onBlurHandler(name, value);
    // }
  }
  console.log("profile", profile)
  const formattedDate = (date) => {
    ``
    return moment(date).format("YYYY-MM-DD")
  }

  const deleteTempPhoto = () => {
    setTempImageParent(null)
  }

  const deletePhoto = () => {
    Agent.CloudFiles.delete(studentPhoto.fileName, 0, profile.id, 0)
      .then(() => {
        setStudentPhoto(null)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const upload = (file) => {
    const fileSize = file.size / 1024 / 2024
    if (fileSize > 2) {
      return
    }
    ResizeFile(file).then(setTempImageState)
  }

  const setTempImageState = (file) => {
    setTempImageParent(file)
  }
  const size = useWindowSize()
  const sortedSTUDENTSTATUS = [...STUDENTSTATUS].sort((a, b) => a.id - b.id)
  return (
    <>
      {size.width > 844 ? (
        <>
          {" "}
          <div className="min-w-[800px] px-6 py-6 min-h-[900px] mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
            <h2 className="text-gray-800 pl-2 text-xl text-center font-semibold"> GENERAL INFORMATION</h2>
            <form>
              <div className="g-0 lg:flex lg:flex-wrap">
                <div className="px-4 md:px-0 lg:w-full">
                {isInternal && (
              <div className="w-full flex justify-center my-4">
                <TextField
                  select
                  label="Status"
                  size="small"
                  style={{ width: "50%" }}
                  value={profile.status || status}
                  error={!!fieldErrors.status}
                  helperText={fieldErrors.status}
                  onChange={handleStatus}
                  variant="outlined"
                >
                  {sortedSTUDENTSTATUS.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.type}
                    </MenuItem>
                  ))}
                </TextField>
                {isStatusUpdating && <CircularProgress size={24} />}
              </div>
            )}

                  <div className="md:mx-6 md:pt-6 pb-8 ">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          error={!!fieldErrors.firstName}
                          helperText={fieldErrors.firstName}
                          value={firstName}
                          onChange={handleFirstName}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          error={!!fieldErrors.lastName}
                          helperText={fieldErrors.lastName}
                          value={lastName}
                          onChange={handleLastName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <TextField
                            label="Date Of Birth"
                            size="small"
                            type="date"
                            fullWidth
                            required
                            error={!!fieldErrors.dateOfBirth}
                            helperText={fieldErrors.dateOfBirth}
                            value={profile.dateOfBirth ? formattedDate(profile.dateOfBirth) : dateOfBirth}
                            onChange={handleDateOfBirth}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Place Of Birth"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          value={placeOfBirth}
                          onChange={handlePlaceOfBirth}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          select
                          label="Gender"
                          size="small"
                          fullWidth
                          value={profile.gender ? profile.gender : gender}
                          error={!!fieldErrors.gender}
                          helperText={fieldErrors.gender}
                          onChange={handleGender}
                          variant="outlined"
                        >
                          <option value={0}>Gender</option>
                          {GENDER.map((charge) => (
                            <MenuItem key={charge.id} value={charge.id}>
                              {charge.type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                     

                      <Grid item xs={6}>
                        <TextField
                          required
                          select
                          label="Marital Status"
                          size="small"
                          fullWidth
                          value={profile.maritalStatus ? profile.maritalStatus : maritalStatus}
                          error={!!fieldErrors.maritalStatus}
                          helperText={fieldErrors.maritalStatus}
                          onChange={handleMaritalStatus}
                          variant="outlined"
                        >
                          <option value={0}>Marital Status</option>
                          {MARITALSTATUS.map((charge) => (
                            <MenuItem key={charge.id} value={charge.id}>
                              {charge.type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Passport Number"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          error={!!fieldErrors.passportNumber}
                          helperText={fieldErrors.passportNumber}
                          value={passportNumber}
                          onChange={handlePassportNumber}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Issue Date"
                          size="small"
                          type="date"
                          fullWidth
                          required
                          error={!!fieldErrors.issueDate}
                          helperText={fieldErrors.issueDate}
                          value={profile.issueDate ? formattedDate(profile.issueDate) : issueDate}
                          onChange={handleIssueDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Expiry Date"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          required
                          error={!!fieldErrors.expiryDate}
                          helperText={fieldErrors.expiryDate}
                          value={profile.expiryDate ? formattedDate(profile.expiryDate) : expiryDate}
                          onChange={handleExpiryDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          open={openIssueCountry}
                          onOpen={() => {
                            setOpenIssueCountry(true)
                          }}
                          onClose={() => {
                            setOpenIssueCountry(false)
                          }}
                          value={countryOfIssue}
                          onChange={(_, newVal) => {
                            setCountryOfIssue(newVal)
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={tempCountry.filter((item) => item.id != 0)}
                          renderInput={(params) => (
                            <TextField
                              inputRef={countryIssueRef}
                              {...params}
                              label="Country Of Issue"
                              placeholder="Country Of Issue"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          open={openCitizenship}
                          onOpen={() => {
                            setOpenCitizenship(true)
                          }}
                          onClose={() => {
                            setOpenCitizenship(false)
                          }}
                          value={citizenship}
                          onChange={(_, newVal) => {
                            setCitizenship(newVal)
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={tempCountry.filter((item) => item.id != 0)}
                          renderInput={(params) => (
                            <TextField
                              inputRef={citizenshipRef}
                              {...params}
                              label="Citizenship"
                              placeholder="Citizenship"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          size="small"
                          open={openResCountry}
                          onOpen={() => {
                            setOpenResCountry(true)
                          }}
                          onClose={() => {
                            setOpenResCountry(false)
                          }}
                          value={countryOfResidence}
                          onChange={(_, newVal) => {
                            setCountryOfResidence(newVal)
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={tempCountry.filter((item) => item.id != 0)}
                          renderInput={(params) => (
                            <TextField
                              inputRef={countryResRef}
                              {...params}
                              label="Country Of Residence"
                              placeholder="Country Of Residence"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    {(role.isStudent || role.isAgent || role.isAgentStaff) && (
                      <>
                        <div className="text-2xl font-semibold text-gray-700 py-5 text-center">Contact Info</div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              required
                              error={!!fieldErrors.email}
                              helperText={fieldErrors.email}
                              value={email}
                              onChange={handleEmail}
                            />
                          </Grid>
                          {emailChecking && (
                            <div>
                              <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                              </Box>
                            </div>
                          )}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <PhoneInput
                                placeholder="Phone Number"
                                error={!!fieldErrors.phoneNumber}
                                helperText={fieldErrors.phoneNumber}
                                value={phoneNumber}
                                required
                                onChange={handlePhoneNumber}
                                inputComponent={phoneInput}
                              />
                              {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Additional Email"
                              value={additionalEmail1}
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              onChange={handleAdditionalEmail1}
                            />
                            {emailChecking && (
                              <div>
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress />
                                </Box>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <PhoneInput
                                placeholder="Additional Phone Number"
                                value={additionalPhoneNumber}
                                onChange={handleAdditionalPhoneNumber}
                                inputComponent={additionalPhoneInput}
                              />
                              {addPhoneError && <FormHelperText error>{addPhoneError}</FormHelperText>}
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Additional Email"
                              value={additionalEmail2}
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              onChange={handleAdditionalEmail2}
                            />
                            {emailChecking && (
                              <div>
                                <Box sx={{ display: "flex" }}>
                                  <CircularProgress />
                                </Box>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              fullWidth
                              size="small"
                              open={openCountry}
                              onOpen={() => {
                                setOpenCountry(true)
                              }}
                              onClose={() => {
                                setOpenCountry(false)
                              }}
                              value={country}
                              onChange={(_, newVal) => {
                                setCountry(newVal)
                              }}
                              getOptionLabel={(option) => `${option.name}`}
                              options={tempCountry.filter((item) => item.id != 0)}
                              renderInput={(params) => (
                                <TextField inputRef={countryRef} {...params} label="Country" placeholder="Country" />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Street Address"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={streetAddress}
                              error={!!fieldErrors.streetAddress}
                              helperText={fieldErrors.streetAddress}
                              onChange={handleStreetAddress}
                              required
                            />
                          </Grid>
                        </Grid>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          
            <div className=" bg-white px-4 py-2 rounded-lg w-full">
              <div className="font-semibold pb-0 text-center">
                <div className="text-2xl font-semibold text-gray-800">Your Photograph</div>
                <div className="text-lg text-gray-600">Please upload a formal photograph of yourself</div>
              </div>
              <div>
                <div>
                  <PhotoWidgetDropzone
                    crop={false}
                    upload={upload}
                    message="Drop your photograph here"
                    disabled={studentPhoto || tempImageParent}
                    height={100}
                  />
                </div>
                <div className="flex justify-between gap-6 p-2">
                  <div className="max-w-sm">
                    <div className="text-xl font-small text-gray-900">Please note the following:</div>
                    <div className="text-base font-medium text-gray-600">
                      <ul style={{ marginTop: 2 }}>
                        <li>You can only upload a JPEG file</li>
                        <li>Your file cannot exceed 2MB</li>
                        <li>The image will be cut to the regular passport size of 35 x 45mm</li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <div>
                      <img
                        alt="student"
                        src={
                          studentPhoto
                            ? studentPhoto.fileUrl
                            : tempImageParent
                              ? URL.createObjectURL(tempImageParent)
                              : user
                        }
                      />
                    </div>
                    {studentPhoto && (
                      <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                        <LoadingButton
                          variant="text"
                          size="small"
                          loading={loading}
                          startIcon={<CloudDownloadIcon />}
                          onClick={() => {
                            setLoading(true)
                            DownloadCloudFile(
                              studentPhoto.fileNameLocal,
                              studentPhoto.fileName,
                              studentPhoto.containerName,
                            ).finally(() => {
                              setLoading(false)
                            })
                          }}
                        ></LoadingButton>
                        <IconButton aria-label="close" onClick={deletePhoto} color="secondary" size="large">
                          <DeleteForeverIcon />
                        </IconButton>
                      </ButtonGroup>
                    )}
                    {tempImageParent && (
                      <div>
                        <Button onClick={deleteTempPhoto}>CHANGE</Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-gray-100 w-full px-4 py-2 rounded-lg">
              <div className="text-2xl w-full font-semibold mb-4 text-gray-700 pb-0 text-center">
                Terms andConditions
              </div>
              <Grid item>
                <Agreements
                  termsAgreed={termsAgreed}
                  setTermsAgreed={setTermsAgreed}
                  isInternal={isInternal}
                  role={role}
                  agentName={name}
                />
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="w-full px-2 py-0 h-full mt-4 rounded overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
            <h2 className="text-gray-800 pl-0 pt-4 text-center uppercase font-semibold">GENERAL INFORMATION</h2>
            <form>
              <div className="px-0 md:px-0 pt-4">
                <div className="md:mx-6 md:pt-4 pb-8 ">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!fieldErrors.firstName}
                        helperText={fieldErrors.firstName}
                        value={firstName}
                        onChange={handleFirstName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!fieldErrors.lastName}
                        helperText={fieldErrors.lastName}
                        value={lastName}
                        onChange={handleLastName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Date Of Birth"
                          size="small"
                          type="date"
                          fullWidth
                          required
                          error={!!fieldErrors.dateOfBirth}
                          helperText={fieldErrors.dateOfBirth}
                          value={profile.dateOfBirth ? formattedDate(profile.dateOfBirth) : dateOfBirth}
                          onChange={handleDateOfBirth}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Place Of Birth"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        value={placeOfBirth}
                        error={!!fieldErrors.placeOfBirth}
                        helperText={fieldErrors.placeOfBirth}
                        onChange={handlePlaceOfBirth}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          select
                          label="Gender"
                          size="small"
                          variant="outlined"
                          fullWidth
                          value={!isNull(profile.gender) ? Number.parseInt(profile.gender) : gender}
                          error={!!fieldErrors.gender}
                          helperText={fieldErrors.gender}
                          onChange={handleGender}
                        >
                          <option value={0}>--select--</option>
                          {GENDER.map((charge) => (
                            <MenuItem key={charge.id} value={charge.id}>
                              {charge.type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          select
                          label="Marital Status"
                          size="small"
                          fullWidth
                          value={
                            !isNull(profile.maritalStatus) ? Number.parseInt(profile.maritalStatus) : maritalStatus
                          }
                          error={!!fieldErrors.maritalStatus}
                          helperText={fieldErrors.maritalStatus}
                          onChange={handleMaritalStatus}
                          variant="outlined"
                        >
                          <option value={0}>--select--</option>
                          {MARITALSTATUS.map((charge) => (
                            <MenuItem key={charge.id} value={charge.id}>
                              {charge.type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Passport Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        error={!!fieldErrors.passportNumber}
                        helperText={fieldErrors.passportNumber}
                        value={passportNumber}
                        onChange={handlePassportNumber}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Issue Date"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          required
                          error={!!fieldErrors.issueDate}
                          helperText={fieldErrors.issueDate}
                          value={profile.issueDate ? formattedDate(profile.issueDate) : issueDate}
                          onChange={handleIssueDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Expiry Date"
                          variant="outlined"
                          size="small"
                          type="date"
                          fullWidth
                          required
                          error={!!fieldErrors.expiryDate}
                          helperText={fieldErrors.expiryDate}
                          value={profile.expiryDate ? formattedDate(profile.expiryDate) : expiryDate}
                          onChange={handleExpiryDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        open={openIssueCountry}
                        onOpen={() => {
                          setOpenIssueCountry(true)
                        }}
                        onClose={() => {
                          setOpenIssueCountry(false)
                        }}
                        value={countryOfIssue}
                        onChange={(_, newVal) => {
                          setCountryOfIssue(newVal)
                        }}
                        getOptionLabel={(option) => `${option.name}`}
                        options={tempCountry.filter((item) => item.id != 0)}
                        renderInput={(params) => (
                          <TextField
                            inputRef={countryIssueRef}
                            {...params}
                            label="Country Of Issue"
                            placeholder="Country Of Issue"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        open={openCitizenship}
                        onOpen={() => {
                          setOpenCitizenship(true)
                        }}
                        onClose={() => {
                          setOpenCitizenship(false)
                        }}
                        value={citizenship}
                        onChange={(_, newVal) => {
                          setCitizenship(newVal)
                        }}
                        getOptionLabel={(option) => `${option.name}`}
                        options={tempCountry.filter((item) => item.id != 0)}
                        renderInput={(params) => (
                          <TextField
                            inputRef={citizenshipRef}
                            {...params}
                            label="Citizenship"
                            placeholder="Citizenship"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        open={openResCountry}
                        onOpen={() => {
                          setOpenResCountry(true)
                        }}
                        onClose={() => {
                          setOpenResCountry(false)
                        }}
                        value={countryOfResidence}
                        onChange={(_, newVal) => {
                          setCountryOfResidence(newVal)
                        }}
                        getOptionLabel={(option) => `${option.name}`}
                        options={tempCountry.filter((item) => item.id != 0)}
                        renderInput={(params) => (
                          <TextField
                            inputRef={countryResRef}
                            {...params}
                            label="Country Of Residence"
                            placeholder="Country Of Residence"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {(role.isStudent || role.isAgent || role.isAgentStaff) && (
                    <>
                      <div className=" uppercase font-semibold text-gray-700 py-5 text-center">Contact Info</div>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            type="email"
                            fullWidth
                            required
                            error={!!fieldErrors.email}
                            helperText={fieldErrors.email}
                            value={email}
                            onChange={handleEmail}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        {emailChecking && (
                          <div>
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress />
                            </Box>
                          </div>
                        )}
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <PhoneInput
                              placeholder="Phone Number"
                              error={!!fieldErrors.phoneNumber}
                              helperText={fieldErrors.phoneNumber}
                              value={phoneNumber}
                              required
                              onChange={handlePhoneNumber}
                              inputComponent={phoneInput}
                            />
                            {phoneError && <FormHelperText error>{phoneError}</FormHelperText>}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Additional Email"
                            value={additionalEmail1}
                            variant="outlined"
                            size="small"
                            type="email"
                            fullWidth
                            onChange={handleAdditionalEmail1}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {emailChecking && (
                            <div>
                              <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                              </Box>
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Additional Email"
                            value={additionalEmail2}
                            variant="outlined"
                            size="small"
                            type="email"
                            fullWidth
                            onChange={handleAdditionalEmail2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {emailChecking && (
                            <div>
                              <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                              </Box>
                            </div>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <PhoneInput
                              placeholder="Additional Phone Number"
                              value={additionalPhoneNumber}
                              onChange={handleAdditionalPhoneNumber}
                              inputComponent={additionalPhoneInput}
                            />
                            {addPhoneError && <FormHelperText error>{addPhoneError}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            fullWidth
                            size="small"
                            open={openCountry}
                            onOpen={() => {
                              setOpenCountry(true)
                            }}
                            onClose={() => {
                              setOpenCountry(false)
                            }}
                            value={country}
                            onChange={(_, newVal) => {
                              setCountry(newVal)
                            }}
                            getOptionLabel={(option) => `${option.name}`}
                            options={tempCountry.filter((item) => item.id != 0)}
                            renderInput={(params) => (
                              <TextField inputRef={countryRef} {...params} label="Country" placeholder="Country" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Street Address"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={streetAddress}
                            error={!!fieldErrors.streetAddress}
                            helperText={fieldErrors.streetAddress}
                            onChange={handleStreetAddress}
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </form>
        
            <div className=" bg-white px-4 py-2 rounded-lg w-full">
              <div className="font-semibold pb-0 text-center">
                <div className="text-2xl font-semibold text-gray-800">Your Photograph</div>
                <div className="text-lg text-gray-600">Please upload a formal photograph of yourself</div>
              </div>
              <div>
                <div className="text-xl font-small text-gray-900 pb-5">
                  <PhotoWidgetDropzone
                    crop={false}
                    upload={upload}
                    message="Drop your photograph here"
                    disabled={studentPhoto || tempImageParent}
                    height={100}
                  />
                </div>
                <div className="flex justify-between gap-6 p-3 ">
                  <div className="max-w-sm pt-3">
                    <div className="text-xl font-small text-gray-900">Please note the following:</div>
                    <div className="text-base font-small text-gray-900">
                      <ul style={{ marginTop: 2 }}>
                        <li>You can only upload a JPEG file</li>
                        <li>Your file cannot exceed 2MB</li>
                        <li>The image will be cut to the regular passport size of 35 x 45mm</li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <div>
                      <img
                        alt="student"
                        src={
                          studentPhoto
                            ? studentPhoto.fileUrl
                            : tempImageParent
                              ? URL.createObjectURL(tempImageParent)
                              : user
                        }
                      />
                    </div>
                    {studentPhoto && (
                      <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                        <LoadingButton
                          variant="text"
                          size="small"
                          loading={loading}
                          startIcon={<CloudDownloadIcon />}
                          onClick={() => {
                            setLoading(true)
                            DownloadCloudFile(
                              studentPhoto.fileNameLocal,
                              studentPhoto.fileName,
                              studentPhoto.containerName,
                            ).finally(() => {
                              setLoading(false)
                            })
                          }}
                        ></LoadingButton>
                        <IconButton aria-label="close" onClick={deletePhoto} color="secondary" size="large">
                          <DeleteForeverIcon />
                        </IconButton>
                      </ButtonGroup>
                    )}
                    {tempImageParent && (
                      <div>
                        <Button onClick={deleteTempPhoto}>CHANGE</Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-gray-100 w-full px-4 py-2 rounded-lg">
              <div className="text-2xl w-full font-semibold mb-4 text-gray-700 pb-0 text-center">
                Terms and Conditions
              </div>
              <Grid item>
                <Agreements
                  termsAgreed={termsAgreed}
                  setTermsAgreed={setTermsAgreed}
                  isInternal={isInternal}
                  role={role}
                  agentName={name}
                />
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default StudentProfile

